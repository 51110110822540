/* ********** COLOR CONTRASTS ********** */

/* /core/libraries/jquery.mmenu/assets/5.6.5/css/jquery.mmenu.all.css */
.mm-menu .mm-search input {
	background: #fff;
	color: #000;
	font-family: inherit;
}

.mm-menu.mm-theme-dark .mm-search input {
	background: #fff;
	color: #000;
	font-family: inherit;
}

.mm-menu.mm-theme-white .mm-search input {
	background: #fff;
	color: #000;
	font-family: inherit;
}

.mm-menu .mm-search input {
	background: #fff;
	color: #000;
	font-family: inherit;
}

.mm-menu.mm-theme-black .mm-search input {
	background: #fff;
	color: #000;
	font-family: inherit;
}

/* /core/public/shared/assets/css/responsive.css */
.fly-out-menu-list-container ul.fly-out-menu-list li a:active,
.fly-out-menu-list-container ul.fly-out-menu-list li.selected a {
	background: #000000 !important;
	color: #ffffff !important;
	font-family: inherit;
}

.pagination-wrapper .pagination-previous-page .disabled,
.pagination-wrapper .pagination-next-page .disabled {
	color: #6b6b6b;
	cursor: default;
	opacity: 0;
}

/* /core/public/shared/assets/css/screen.css */
.search-facets .search-facet .price-range-button a {
	background-color: #757575;
	color: #ffffff;
	font-family: inherit;
}

/* /core/libraries/tablesaw/assets/2.0.3/tablesaw.css */
.tablesaw-enhanced .tablesaw-bar .btn:hover,
.tablesaw-enhanced .tablesaw-bar .btn:focus {
	color: #000000;
	background-color: #ffffff;
	font-family: inherit;
}

/* /core/public/shared/assets/css/uitags.css */
.ui-form-legend h3 .ui-form-legend-step {
	background: #ffffff;
	color: #000000;
	font-family: inherit;
}


/* ********** FONT SIZE UNITS ********** */

/* /core/libraries/jquery.mmenu/assets/5.6.5/css/jquery.mmenu.all.css */
.mm-listview {
	font-size: 0.875rem;
	line-height: 1.25rem;
}

.mm-listview .mm-divider {
	font-size: 0.625rem;
	line-height: 1.5rem;
}

em.mm-counter {
	font-size: 0.875rem;
	line-height: 1.25rem;
}

.mm-search input {
	font-size: 0.875rem;
	line-height: 1.625rem;
}

.mm-noresultsmsg {
	font-size: 1.375rem;
}

.mm-indexer {
	font-size: 0.75rem;
}

/* /core/libraries/tablesaw/assets/2.0.3/tablesaw.css */
.tablesaw-enhanced .tablesaw-bar .btn {
	font-size: 1.25rem;
}

/* /core/libraries/select2/assets/select2.css */
.select2-container .select2-choice abbr {
	font-size: 0.0625rem;
}

/* ********** ALL INFORMATION CONVEYED WITH COLOR IS ALSO AVAILABLE WITHOUT COLOR ********** */
/* /core/libraries/dataTables/assets/1.10.20/css/jquery.dataTables.min.css */
.dataTables_wrapper .dataTables_length,
.dataTables_wrapper .dataTables_filter,
.dataTables_wrapper .dataTables_info,
.dataTables_wrapper .dataTables_processing,
.dataTables_wrapper .dataTables_paginate {
	color: #333;
	font-weight: 700;
}


/************ This CSS style makes it difficult or impossible to see the dotted link focus outline. **************/
/* /core/libraries/select2/assets/select2.css */
.select2-offscreen,
.select2-offscreen:focus {
	outline: 1px solid #18639d;
}

/************** Content inserted with CSS is not read by some screen readers ********************/
/* /core/public/shared/assets/css/slideshow.css*/
.slideshow-wrapper .cycle-paused:after {
	content: '||' !important;
}
