body {
	font-family: Montserrat, sans-serif;
	font-size: 1rem;
	line-height: 1.6rem;
	font-weight: 500;
}

.page-background {
	color: #2e2e2e;
	background-color: #fff;
	font-family: Montserrat, sans-serif;
}

#printHeader,
#printFooter {
	display: none;
}

/* Top navigation header */
@font-face {
	font-family: 'Permanentmarker';
	src: url('/assets/fonts/PermanentMarker-Regular.ttf') format('truetype');
	font-weight: 400;
	font-style: normal;
	font-display: swap;
}

.top-header-section {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	padding-right: 5rem;
	padding-left: 5rem;
	-webkit-box-pack: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
	justify-content: center;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
	background-color: #e9e9e9;
}

.nav-tab-container {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	width: 100%;
	max-width: none;
}

.stylized-text-wrapper {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	padding-right: 0.5rem;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
	-webkit-transform: rotate(-2deg);
	-ms-transform: rotate(-2deg);
	transform: rotate(-2deg);
}

.top-header-section.meat-grocery .stylized-text-wrapper {
	padding-left: 0.5rem;
	padding-right: 0px;
}

.stylized-text {
	max-width: 200px;
	font-family: Permanentmarker, sans-serif;
	color: #c72336;
	font-size: 1rem;
	line-height: 20px;
}

.stylized-text.stylized-text-fareway {
	padding-right: 20px;
}

.stylized-arrow {
	position: relative;
	left: -15px;
	width: 22px;
}

.top-header-section.meat-grocery .stylized-arrow {
	transform: rotate(182deg);
}

.logos-wrapper {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
}

.top-header-section.meat-grocery .logos-wrapper {
	margin-left: 230px;
}

.fareway-logo {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	width: auto;
	padding: 1.25rem 1rem;
	-webkit-box-pack: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
	justify-content: center;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
	background-color: transparent;
}

.image {
	width: 125px;
}

.fareway-logo.meat-market-logo {
	padding-top: 1.25rem;
	padding-bottom: 1.25rem;
}

.fareway-logo.fareway-logo-active {
	background-color: #fff;
}

@media screen and (max-width: 991px) {
	.top-header-section {
		padding-right: 3rem;
		padding-left: 3rem;
	}

	.nav-tab-container {
		-webkit-box-pack: justify;
		-webkit-justify-content: space-between;
		-ms-flex-pack: justify;
		justify-content: space-between;
	}

	.top-header-section.meat-grocery .stylized-text-wrapper {
		padding-left: 0;
		padding-right: 0;
		width: 234px;
	}

	.top-header-section.meat-grocery .logos-wrapper {
		margin-left: 0;
		order: 2;
	}

	.top-header-section.meat-grocery .stylized-arrow {
		transform: rotate(0);
		order: 2;
	}
}

@media screen and (max-width: 479px) {
	.top-header-section {
		display: block;
		padding-right: 1rem;
		padding-left: 1rem;
		-webkit-box-orient: horizontal;
		-webkit-box-direction: normal;
		-webkit-flex-direction: row;
		-ms-flex-direction: row;
		flex-direction: row;
		-webkit-flex-wrap: wrap;
		-ms-flex-wrap: wrap;
		flex-wrap: wrap;
	}

	.nav-tab-container {
		-webkit-box-pack: start;
		-webkit-justify-content: flex-start;
		-ms-flex-pack: start;
		justify-content: flex-start;
		-webkit-flex-wrap: wrap;
		-ms-flex-wrap: wrap;
		flex-wrap: wrap;
	}

	.top-header-section.meat-grocery .nav-tab-container {
		justify-content: flex-end;
	}

	.stylized-text-wrapper {
		margin-top: 0.5rem;
		margin-bottom: 0.5rem;
		margin-left: 1rem;
		padding-right: 0rem;
	}

	.top-header-section.meat-grocery .stylized-text-wrapper {
		width: auto;
	}

	.stylized-text.stylized-text-fareway {
		padding-right: 0;
	}

	.stylized-arrow {
		-webkit-box-ordinal-group: 0;
		-webkit-order: -1;
		-ms-flex-order: -1;
		order: -1;
		-webkit-transform: rotate(65deg);
		-ms-transform: rotate(65deg);
		transform: rotate(65deg);
	}

	.top-header-section.meat-grocery .stylized-arrow {
		transform: rotate(65deg);
		order: 0;
	}

	.logos-wrapper {
		width: 100%;
	}

	.fareway-logo {
		width: 50%;
	}
}

h1 {
	margin-top: 1rem;
	margin-bottom: 1rem;
	font-family: 'Roboto Slab', sans-serif;
	font-size: 2.5rem;
	line-height: 3.2rem;
	font-weight: 400;
}

h2 {
	margin-top: 1.5rem;
	margin-bottom: 0.7rem;
	font-family: 'Roboto Slab', sans-serif;
	font-size: 2rem;
	line-height: 2.7rem;
	font-weight: 400;
}

h3 {
	margin-top: 1.5rem;
	margin-bottom: 0.5rem;
	font-family: 'Roboto Slab', sans-serif;
	font-size: 1.75rem;
	line-height: 2.45rem;
	font-weight: 400;
}

h4 {
	margin-top: 1.25rem;
	margin-bottom: 0.5rem;
	font-family: 'Roboto Slab', sans-serif;
	font-size: 1.5rem;
	line-height: 2.2rem;
	font-weight: 400;
}

h5 {
	margin-top: 1.25rem;
	margin-bottom: 0.5rem;
	font-family: 'Roboto Slab', sans-serif;
	font-size: 1.25rem;
	line-height: 1.95rem;
	font-weight: 400;
}

h6 {
	margin-top: 1.25rem;
	margin-bottom: 0.5rem;
	font-family: 'Roboto Slab', sans-serif;
	font-size: 1.15rem;
	line-height: 1.75rem;
	font-weight: 400;
}

p {
	margin-top: 0.625rem;
	margin-bottom: 1rem;
	color: #2e2e2e;
	font-size: 1rem;
	line-height: 1.7rem;
}

a {
	color: #d8263c;
	background-color: #fff;
	-webkit-transition: all 200ms ease;
	transition: all 200ms ease;
	text-decoration: underline;
}

ul {
	margin-top: 0.5rem;
	margin-bottom: 1rem;
	padding-left: 1.25rem;
	font-size: 1.05rem;
	line-height: 1.7rem;
}

ol {
	margin-top: 0.5rem;
	margin-bottom: 1rem;
	padding-left: 1.25rem;
	font-size: 1.05rem;
	line-height: 1.7rem;
}

label {
	display: block;
	margin-bottom: 5px;
	font-size: 0.8571rem;
	line-height: 1.5rem;
	font-weight: 700;
}

blockquote {
	margin-top: 1.5rem;
	margin-bottom: 1.5rem;
	padding: 1.25rem;
	border-left: 6px solid #ec1c24;
	background-color: transparent;
	font-family: 'Roboto Slab', sans-serif;
	color: #2e2e2e;
	font-size: 1.15rem;
	line-height: 1.85rem;
}

.social-share-link.share-facebook,
.social-share-link.share-twitter,
.social-share-link.share-pinterest,
.social-share-link.share-email {
	background-color: #999;
	cursor: pointer;
}

.inside-page-wrapper .card.video .card-content h3 .card-title-a,
.ui-datepicker .ui-datepicker-prev,
.ui-datepicker .ui-datepicker-next {
	background-color: transparent;
	cursor: pointer;
}

.slide-caption-inner-wrap p:last-of-type {
	margin-bottom: 0;
}

.slide-caption-inner-wrap p:last-of-type a,
.button.module-button,
.button,
.primary,
.secondary,
.tertiary,
button[type="submit"] {
	position: static;
	display: inline-block;
	min-width: 100px;
	margin-right: 1rem;
	padding: 0.5625rem 1rem;
	border: 1px solid #d8263c;
	border-radius: 2px;
	background-color: #d8263c;
	-webkit-transition: all 200ms ease;
	transition: all 200ms ease;
	color: #fff;
	font-size: 1rem;
	line-height: 1rem;
	font-weight: 600;
	font-family: Montserrat, sans-serif;
	text-align: center;
	text-decoration: none;
	box-shadow: none !important;
	text-transform: none;
	cursor: pointer;
}

.button.module-button {
	margin-right: 0;
}

.button.module-button + .button.module-button,
.button.secondary.module-button-secondary {
	margin-left: 1rem;
}

.primary,
.secondary,
.tertiary,
.button.module-button,
button[type="submit"] {
	padding: 0.5625rem 1rem !important;
	border: 1px solid #d8263c !important;
}

.slide-caption-inner-wrap p:last-of-type a:hover,
.button:hover,
.primary:hover,
.secondary:hover,
.tertiary:hover,
.button.module-button:hover,
button[type="submit"]:hover {
	color: #fff !important;
	border-color: #ad1e30 !important;
	background-color: #ad1e30 !important;
}

.button.secondary,
.secondary,
button[type="submit"].secondary {
	background-color: #fff;
	color: #2e2e2e;
	text-align: center;
}

.button.secondary:hover,
.secondary:hover,
button[type="submit"].secondary:hover {
	background-color: #d3d3d3 !important;
	opacity: 0.75;
	color: #000 !important;
}

.button.search-button {
	width: 30%;
	height: 2.5rem;
	min-height: 2.5rem;
	margin-top: 0rem;
	margin-right: 0rem;
	margin-bottom: 0rem;
	padding: 0.38rem 1rem;
	float: left;
}

.button.sidebar-button {
	height: 36px;
	min-width: 0px;
	margin-top: 0.5rem;
	margin-right: 0rem;
	margin-bottom: 0.5rem;
	padding: 0.35rem 0.75rem;
	font-size: 0.9rem;
	line-height: 1.4rem;
}

.button.tertiary,
.tertiary,
button[type="submit"].tertiary {
	padding: 0.35rem 0.75rem !important;
	border-color: #797676 !important;
	background-color: #fff;
	color: #2e2e2e;
	font-size: 0.9rem;
	line-height: 1.3rem;
	font-weight: 600;
	text-transform: uppercase;
}

.button.tertiary:hover,
.tertiary:hover,
button[type="submit"].tertiary:hover {
	background-color: #d3d3d3 !important;
	border-color: #797676 !important;
	color: #2e2e2e !important;
}

body a.primary {
	background-color: #d8263c;
	color: #fff;
	font-weight: 600;
	box-shadow: none;
}

body a.primary:hover {
	background-color: #ad1e30 !important;
	color: #fff;
	font-weight: 600;
	box-shadow: none;
}

body a.secondary {
	background-color: #fff;
	color: #000;
	font-weight: 600;
	box-shadow: none;
}

body a.secondary:hover {
	background-color: #d3d3d3 !important;
	color: #000;
	font-weight: 600;
	box-shadow: none;
}

body a.tertiary {
	background-color: #fff;
	color: #2e2e2e;
	font-weight: 600;
	box-shadow: none;
}

body a.tertiary:hover {
	background-color: #d3d3d3 !important;
	color: #2e2e2e;
	font-weight: 600;
	box-shadow: none;
}

.button.card-button {
	margin-top: 10px;
	margin-right: 0rem;
	margin-bottom: 10px;
	padding: 10px 1.25rem;
}

.main-content-wrapper {
	width: 100%;
	margin-top: 2.5rem;
	padding-top: 0rem;
	padding-bottom: 1rem;
	float: left;
}

.container {
	max-width: 1424px;
	margin-right: auto;
	margin-left: auto;
}

.container.inside-page-content-container {
	display: block;
	max-width: 1300px;
	min-height: 500px;
	-webkit-box-pack: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
	justify-content: center;
}

.container.inside-page-content-container.page-title-container {
	position: relative;
	min-height: 0px;
}

.container.footer-container {
	display: block;
	max-width: none;
	padding: 0rem 0rem 2rem;
	-webkit-box-pack: justify;
	-webkit-justify-content: space-between;
	-ms-flex-pack: justify;
	justify-content: space-between;
}

.container.signoff-container {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	max-width: 1424px;
	-webkit-box-pack: justify;
	-webkit-justify-content: space-between;
	-ms-flex-pack: justify;
	justify-content: space-between;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
	background-color: #fff;
}

.container.cards-container {
	position: relative;
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	max-width: 1424px;
	margin-top: 3.5rem;
	padding-top: 0rem;
	padding-bottom: 0rem;
	-webkit-box-pack: justify;
	-webkit-justify-content: space-between;
	-ms-flex-pack: justify;
	justify-content: space-between;
	-webkit-flex-wrap: wrap;
	-ms-flex-wrap: wrap;
	flex-wrap: wrap;
}

.container.insta-container {
	position: relative;
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	max-width: 1424px;
	padding-top: 0rem;
	padding-bottom: 0rem;
	-webkit-box-pack: justify;
	-webkit-justify-content: space-between;
	-ms-flex-pack: justify;
	justify-content: space-between;
	-webkit-flex-wrap: wrap;
	-ms-flex-wrap: wrap;
	flex-wrap: wrap;
}

.left-nav-col {
	width: 20%;
	max-width: 270px;
	min-width: 190px;
	margin-top: -2px;
	margin-right: 3%;
	-webkit-box-flex: 0;
	-webkit-flex: 0 0 auto;
	-ms-flex: 0 0 auto;
	flex: 0 0 auto;
}

.inside-content-column {
	width: 80%;
	-webkit-box-flex: 0;
	-webkit-flex: 0 auto;
	-ms-flex: 0 auto;
	flex: 0 auto;
}

.container.inside-page-content-container .inside-content-wrap .outline-wrap .inside-content-column {
	width: 100%;
}

.container.inside-page-content-container .inside-content-wrap .outline-wrap .left-nav-col + .inside-content-column {
	width: 77%;
}

.inside-content-column.right-sidebar-only-content-column {
	width: 100%;
}

.inside-content-column.no-sidebars-content-column {
	width: 100%;
	margin-right: 10%;
	margin-left: 10%;
}

.module-bakeries .inside-content-column.no-sidebars-content-column,
.module-health .inside-content-column.no-sidebars-content-column,
.module-healthnews .inside-content-column.no-sidebars-content-column,
.module-healthblogs .inside-content-column.no-sidebars-content-column,
.module-careers .inside-content-column.no-sidebars-content-column,
.module-recipes .inside-content-column.no-sidebars-content-column,
.module-stores .inside-content-column.no-sidebars-content-column,
.module-meatcounter .inside-content-column.no-sidebars-content-column {
	margin-right: 0;
	margin-left: 0;
}

.breadcrumbs-wrapper {
	background-color: #fff;
	font-size: 0.75rem;
	line-height: 1.5rem;
}

.breadcrumbs-list {
	margin: 0;
	padding: 0;
}

.breadcrumbs-list-item {
	display: inline-block;
}

.breadcrumb-link {
	padding-right: 0.25rem;
	font-size: 0.9rem;
	line-height: 1.5rem;
}

.breadcrumb-current {
	font-size: 0.75rem;
	line-height: 1.5rem;
	display: inline-block;
	color: #d8263c;
}

.content-wrapper {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-pack: start;
	-webkit-justify-content: flex-start;
	-ms-flex-pack: start;
	justify-content: flex-start;
	-webkit-flex-wrap: nowrap;
	-ms-flex-wrap: nowrap;
	flex-wrap: nowrap;
	-webkit-box-align: start;
	-webkit-align-items: flex-start;
	-ms-flex-align: start;
	align-items: flex-start;
}

.right-side-col {
	position: relative;
	width: 20%;
	min-width: 150px;
	margin-top: 2.5rem;
	margin-left: 2rem;
	-webkit-box-flex: 0;
	-webkit-flex: 0 0 auto;
	-ms-flex: 0 0 auto;
	flex: 0 0 auto;
}

.left-sidebar-custom h2,
.right-side-col h2 {
	margin-top: 1.25rem;
	margin-bottom: 0.5rem;
	font-size: 1.143rem;
	line-height: 1.743rem;
	font-weight: 400;
}

.left-sidebar-custom td,
.right-side-col td,
.left-sidebar-custom p,
.right-side-col p {
	font-family: Montserrat, sans-serif;
	font-size: 0.95rem;
	line-height: 1.45rem;
}

.left-sidebar-custom h3,
.right-side-col h3 {
	margin-top: 1.25rem;
	margin-bottom: 0.5rem;
	font-size: 1.143rem;
	line-height: 1.743rem;
}

.left-sidebar-custom h4,
.right-side-col h4 {
	margin-bottom: 0.5rem;
	font-size: 1.143rem;
	line-height: 1.743rem;
}

.left-sidebar-custom ul,
.right-side-col ul {
	font-size: 0.95rem;
	line-height: 1.45rem;
}

.left-sidebar-custom ol,
.right-side-col ol {
	font-size: 0.95rem;
	line-height: 1.45rem;
}

.left-nav-wrapper {
	position: relative;
}

.left-nav-section-title {
	height: 51px;
	margin-top: 0rem;
	margin-bottom: 0rem;
	font-family: Montserrat, sans-serif;
	font-size: 0.98rem;
	line-height: 1.4rem;
	font-weight: 500;
	text-decoration: none;
	border-bottom: 0 solid transparent;
	display: flex;
	align-items: center;
}

.left-nav-section-title-link {
	display: inline;
	margin: 0 auto;
	padding-top: 8px;
	padding-bottom: 8px;
	padding-left: 0rem;
	color: #fff !important;
	background-color: #231f20 !important;
	font-size: 1.25rem;
	line-height: 1.25rem;
	text-align: center;
	text-decoration: none;
	text-transform: uppercase;
	border-bottom: 0 solid transparent;
}

.left-nav-list {
	margin-bottom: 1rem;
	padding-top: 54px;
	color: #424242;
}

.left-nav-list.subnav-list {
	margin-top: 0rem;
	margin-bottom: 0rem;
	padding-top: 0px;
}

.left-nav-list-item {
	margin-bottom: 0rem;
}

.left-nav-list-item.grandchild-li {
	margin-left: 1rem;
}

.left-nav-list-item.greatgrandchild-li {
	margin-left: 1rem;
	font-size: 0.9rem;
}

.left-nav-link {
	display: block;
	padding: 0.4rem 0.75rem 0.4rem 0.85rem;
	background-image: url('../images/arrow-medium.svg');
	background-position: -20px 50%;
	background-size: 8px 8px;
	background-repeat: no-repeat;
	-webkit-transition: all 200ms ease;
	transition: all 200ms ease;
	color: #424242;
	font-size: 1rem;
	line-height: 1.5rem;
	text-decoration: none;
	border-bottom: 0 solid transparent;
}

.left-nav-link:hover {
	background-image: url('../images/arrow-medium.svg');
	background-position: 0px 50%;
	background-size: 8px 8px;
	background-repeat: no-repeat;
	-webkit-transform: translate(6px, 0px);
	-ms-transform: translate(6px, 0px);
	transform: translate(6px, 0px);
	color: #d8263c;
}

.left-nav-link.selected {
	margin-left: 0rem;
	background-position: 0px 50%;
	-webkit-transition: none 0ms ease;
	transition: none 0ms ease;
	color: #d8263c;
}

.left-nav-link.selected:hover {
	-webkit-transform: none;
	-ms-transform: none;
	transform: none;
}

.left-nav-link.grandchild-link {
	font-size: 0.95rem;
	line-height: 1.45rem;
}

.left-nav-link.grandchild-link.selected {
	margin-left: 0rem;
	background-position: 0px 50%;
	-webkit-transition: none 0ms ease;
	transition: none 0ms ease;
	color: #d8263c;
}

.left-nav-link.grandchild-link.selected:hover {
	-webkit-transform: none;
	-ms-transform: none;
	transform: none;
}

.left-nav-link.greatgrandchild-link {
	font-size: 0.85rem;
	line-height: 1.4rem;
}

.header-section {
	position: fixed;
	z-index: 1000;
	width: 100%;
	background-color: #fff;
	box-shadow: 0 1px 15px 0 rgba(0, 0, 0, 0.5);
	-webkit-transition: all 450ms ease;
	transition: all 450ms ease;
}

.navbar {
	display: block;
	max-width: 1424px;
	margin-right: auto;
	margin-left: auto;
	background-color: transparent;
}

.navbar.footer-nav-bar {
	max-width: none;
	border-bottom: 1px solid hsla(0, 1%, 47%, 0.5);
	border-top: 1px solid hsla(0, 1%, 47%, 0.5);
}

.brand {
	position: relative;
	width: 314px;
	margin-top: 1rem;
	margin-right: 10px;
	margin-left: 10px;
	padding-right: 10px;
	padding-left: 10px;
	float: none;
	-webkit-box-flex: 0;
	-webkit-flex: 0 0 auto;
	-ms-flex: 0 0 auto;
	flex: 0 0 auto;
	-webkit-transition: all 300ms ease;
	transition: all 300ms ease;
	cursor: pointer;
}

.nav-menu {
	width: auto;
	margin-top: 10px;
	padding-right: 20px;
	padding-left: 20px;
	float: none;
	-webkit-box-flex: 1;
	-webkit-flex: 1;
	-ms-flex: 1;
	flex: 1;
	border-top: 1px solid hsla(0, 1%, 47%, 0.5);
	border-bottom: 1px none hsla(0, 1%, 47%, 0.5);
	-webkit-transition: all 200ms ease;
	transition: all 200ms ease;
	text-align: center;
}

.main-nav-ul {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-pack: justify;
	-webkit-justify-content: space-between;
	-ms-flex-pack: justify;
	justify-content: space-between;
	-webkit-box-align: stretch;
	-webkit-align-items: stretch;
	-ms-flex-align: stretch;
	align-items: stretch;
	padding: 0;
	margin: 0;
	list-style-type: none;
}

.nav-menu.footer-menu {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-pack: justify;
	-webkit-justify-content: space-between;
	-ms-flex-pack: justify;
	justify-content: space-between;
	-webkit-box-align: stretch;
	-webkit-align-items: stretch;
	-ms-flex-align: stretch;
	align-items: stretch;
	margin-top: 0px;
	-webkit-box-pack: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
	justify-content: center;
	border-top-style: none;
	border-bottom-style: none;
}

.main-nav-link {
	position: relative;
	z-index: 900;
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	margin-right: 0px;
	margin-left: 0px;
	padding: 0.5rem 0.75rem;
	-webkit-box-pack: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
	justify-content: center;
	-webkit-box-align: end;
	-webkit-align-items: flex-end;
	-ms-flex-align: end;
	align-items: flex-end;
	-webkit-transition: all 250ms cubic-bezier(0.455, 0.03, 0.515, 0.955);
	transition: all 250ms cubic-bezier(0.455, 0.03, 0.515, 0.955);
	color: #000;
	background-color: #fff;
	font-size: 0.98rem;
	line-height: 1.4rem;
	font-weight: 600;
	letter-spacing: 0.02rem;
	text-decoration: none;
	text-transform: uppercase;
	cursor: pointer;
	border-bottom: 0 solid transparent;
}

.main-nav-link.active-item {
	height: 100%;
	padding-bottom: 0.5rem;
	box-shadow: inset 0 -2px 0 0 #ec1c24, inset 0 2px 0 0 #ec1c24;
	color: #d8263c;
	background-color: #fff;
}

.dropdown-wrap:hover .main-nav-link,
.main-nav-link:hover {
	box-shadow: inset 0 2px 0 0 #ec1c24, inset 0 -2px 0 0 #ec1c24;
	-webkit-transform: none;
	-ms-transform: none;
	transform: none;
	color: #d8263c;
	background-color: #fff;
}

.main-nav-link.mobile-duplicate {
	display: none;
}

.main-nav-link.footer-main {
	margin-right: 1vw;
	margin-left: 1vw;
	padding-top: 0.6rem;
	padding-bottom: 0.4rem;
}

.main-nav-link.footer-main.w--current {
	color: #d8263c;
	background-color: #fff;
	box-shadow: inset 0 -2px 0 0 #ec1c24, inset 0 2px 0 0 #ec1c24;
}

.rotator-section {
	position: relative;
	margin-top: 172px;
	padding-right: 5%;
	padding-left: 5%;
	background-image: none;
	width: calc(100% - 11px);
}

.rotator {
	display: block;
	width: 100%;
	height: auto;
	padding-top: 0rem;
	padding-bottom: 0px;
	background-color: transparent;
	box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.26);
}

.slideshow-arrow {
	opacity: 0.25;
	-webkit-transition: all 200ms ease;
	transition: all 200ms ease;
	color: #000;
}

.slideshow-arrow:hover {
	opacity: 0.75;
	-webkit-transform: translate(0px, 0px);
	-ms-transform: translate(0px, 0px);
	transform: translate(0px, 0px);
	color: #000;
}

.slideshow-arrow.left-arrow {
	left: -60px;
	width: 60px;
}

.slideshow-arrow.left-arrow:hover {
	opacity: 1;
	-webkit-transform: translate(-10px, 0px);
	-ms-transform: translate(-10px, 0px);
	transform: translate(-10px, 0px);
}

.slideshow-arrow.right-arrow {
	right: -60px;
	width: 60px;
}

.slideshow-arrow.right-arrow:hover {
	opacity: 1;
	-webkit-transform: translate(10px, 0px);
	-ms-transform: translate(10px, 0px);
	transform: translate(10px, 0px);
}

.testimonial-navdot {
	background-color: #4d647c !important;
	cursor: pointer;
}

.testimonial-navdot.cycle-pager-active {
	background-color: #c9353c !important;
	border-color: #c9353c;
	cursor: pointer;
}

.slidenav {
	left: 0px;
	right: 0px;
	bottom: -45px;
	display: block;
	height: 40px;
	padding-top: 0.75rem;
	font-size: 0.75rem;
}

.slide-caption-outer-wrap {
	position: absolute;
	left: 0px;
	right: 0px;
	bottom: 60px;
	display: block;
	overflow: hidden;
	width: 810px;
	height: auto;
	margin-top: 40px;
	margin-right: auto;
	margin-left: auto;
	padding: 1rem;
	background-color: hsla(0, 0%, 100%, 0.8);
	text-align: center;
}

.slide-caption-inner-wrap h1,
.slide-caption-inner-wrap h2,
.slide-caption-inner-wrap h3,
.slide-caption-header {
	margin-top: 0.75rem;
	margin-bottom: 1rem;
	font-family: 'Roboto Slab', sans-serif;
	color: #fff;
	font-size: 2.5rem;
	line-height: 3rem;
	font-weight: 400;
	text-shadow: 1px 1px 0 #000;
}

.slide-caption-inner-wrap h1,
.slide-caption-inner-wrap h2,
.slide-caption-inner-wrap h3,
.slide-caption-header.white-test-text {
	color: #2e2e2e;
	text-shadow: none;
}

.slide-caption-inner-wrap p,
.slide-caption-paragraph {
	font-family: 'Roboto Slab', sans-serif;
	color: #fff;
	font-size: 1.5rem;
	line-height: 2rem;
	font-weight: 400;
}

.slide-caption-inner-wrap p,
.slide-caption-paragraph.white-subtext-test {
	color: #2e2e2e;
	cursor: default;
}

.arrow-icon {
	display: block;
	margin-right: 1rem;
	margin-left: 1rem;
	font-size: 1.5rem;
}

.arrow-icon.left-arrow-icon {
	margin-right: 1rem;
	margin-left: 1rem;
}

.slide {
	padding: 1.25rem;
	background-color: #fff;
}

.home-content-section {
	padding-top: 0rem;
	padding-right: 5%;
	padding-left: 5%;
	background-image: none;
	background-position: 0px 0px;
	background-size: auto;
	-webkit-transition: all 400ms ease;
	transition: all 400ms ease;
}

.home-content-section.hcs-insta {
	padding-top: 2rem;
	padding-bottom: 2rem;
	background-image: none;
	box-shadow: none;
}

.home-content-section .cards-parent .card-container {
	width: 23%;
	display: flex;
	margin-left: 1%;
	margin-right: 1%;
}

.home-content-section .cards-parent .card-container.show-on-mobile {
	width: 98%;
	display: none;
}

.home-content-section .cards-parent .card-container .card {
	-webkit-flex-basis: 100%;
	-ms-flex-preferred-size: 100%;
	flex-basis: 100%;
	margin-left: 0px;
	margin-right: 0px;
}

.cb-static {
	padding: 1rem;
}

.footer-social-container-wrapper {
	padding: 0 5%;
}

.footer-social-container {
	display: flex;
	max-width: 1424px;
	margin: auto;
	padding: 0;
}

.cb-static.footer-cb-3 {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	margin-right: auto;
	margin-left: auto;
	padding: 2rem 2rem 1rem;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-webkit-flex-direction: column;
	-ms-flex-direction: column;
	flex-direction: column;
	background-color: transparent;
	text-align: center;
}

.best-workplace {
	padding-top: 1rem;
}

.best-workplace img {
	width: 92px;
	min-width: 92px;
}

.footer-section {
	background-color: #fdfdff;
	opacity: 1;
}

.signoff-wrapper {
	padding: 0.5rem 5% 1rem;
	border-top: 1px solid hsla(0, 1%, 47%, 0.5);
}

.footer-grip-link {
	width: 130px;
	height: 28px;
	min-width: 0px;
	margin-top: 1rem;
	margin-bottom: 1rem;
	padding-left: 0;
	-webkit-box-flex: 0;
	-webkit-flex: 0 0 auto;
	-ms-flex: 0 0 auto;
	flex: 0 0 auto;
	text-decoration: none;
	border-bottom: 0 solid transparent;
	cursor: pointer;
}

.grip-logo-text-box {
	font-family: Arial, 'Helvetica Neue', Helvetica, sans-serif;
	color: #797676;
	font-size: 0.5rem;
	line-height: 0.5rem;
	text-decoration: none;
	text-transform: uppercase;
	border-bottom: 0 solid transparent;
}

#GRIPFooterLogo {
	padding-top: 0 !important;
}

#GRIPFooterLogoText {
	line-height: 0.5rem;
	background-color: #fff;
}

#GRIPFooterLogoImg {
	margin-top: -15px !important;
}

.copyright-paragraph {
	display: inline-block;
	margin-bottom: 0rem;
	font-size: 0.8rem;
	font-family: Montserrat, sans-serif;
	line-height: 1.6rem;
	color: #2e2e2e;
	margin-top: 0px;
}

.signoff-link {
	margin-right: 0.75rem;
	margin-left: 0rem;
	border-bottom: 1px solid #ccc;
	-webkit-transition: all 200ms ease;
	transition: all 200ms ease;
	color: #2e2e2e;
	font-size: 0.8rem;
	text-decoration: none;
	cursor: pointer;
}

.signoff-link:hover {
	border-bottom-color: transparent;
	color: #d8263c;
	text-decoration: none;
	border-bottom: 0 solid transparent;
}

.social-media-wrapper {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
}

.social-media-wrapper.footer-socmed-wrapper {
	float: right;
	-webkit-box-pack: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
	justify-content: center;
}

.social-media-link-block {
	width: 2.25rem;
	height: 2.25rem;
	margin-right: 0.5rem;
	margin-left: 0.5rem;
	background-image: url('../images/facebook_wht_1facebook_wht.png');
	background-position: 50% 50%;
	background-size: 25px;
	background-repeat: no-repeat;
}

.social-media-link-block.footer-socmed-link-block {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	width: 40px;
	height: 40px;
	margin-right: 0.75rem;
	margin-left: 0.75rem;
	-webkit-box-pack: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
	justify-content: center;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
	border-radius: 50%;
	background-color: #d8263c;
	background-image: none;
	-webkit-transition: all 200ms ease;
	transition: all 200ms ease;
	cursor: pointer;
}

.social-media-link-block.footer-socmed-link-block:hover {
	background-color: #ad1e30;
	background-image: none;
	-webkit-transform: scale(1.08);
	-ms-transform: scale(1.08);
	transform: scale(1.08);
}

.copyright-privacy-sitemap-wrapper {
	-webkit-box-flex: 1;
	-webkit-flex: 1;
	-ms-flex: 1;
	flex: 1;
}

.two-part-mobile-nav-wrapper {
	display: none;
}

.horiz-divider {
	height: 2px;
	margin-top: 0.5rem;
	margin-bottom: 1rem;
	background-color: #000;
}

.header-search-field {
	display: inline-block;
	width: 70%;
	height: 2.5rem;
	margin-bottom: 0px;
	float: left;
	border: 0px none transparent;
}

.search-dd-button {
	display: none;
	width: 3rem;
	margin-right: 2px;
	margin-left: 2px;
	padding: 0.75rem 1rem;
	background-image: url('../images/search_wht.svg');
	background-position: 50% 50%;
	background-size: 17px 17px;
	background-repeat: no-repeat;
	-webkit-transition: all 200ms ease;
	transition: all 200ms ease;
}

.header-search-form {
	display: block;
	height: 100px;
	border-style: none;
}

.header-search-form-wrapper {
	display: block;
	width: 50%;
	height: 100px;
	margin-bottom: 0px;
	padding: 1rem;
	float: right;
	background-color: rgba(0, 0, 0, 0.5);
}

.header-search-form-wrapper.search-top-wrapper {
	display: block;
	width: 100%;
	max-width: 1200px;
	margin-right: auto;
	margin-left: auto;
	padding-right: 2rem;
	padding-bottom: 2rem;
	padding-left: 2rem;
	float: none;
}

.search-session-message {
	height: 0px;
	margin-top: 0px;
	padding: 0px;
	background-color: transparent;
	opacity: 0;
	color: transparent;
}

.breadcrumb-divider {
	display: inline-block;
	margin-right: 0.5rem;
	margin-left: 0.5rem;
}

.success-message-box {
	border: 1px solid #1a5b0b;
	background-color: #f1fced;
	color: #1a5b0b;
}

.error-message-box {
	border: 1px solid #d50a2f;
	background-color: #fef3ee;
	color: #d50a2f;
}

.footer-heading {
	margin-top: 0rem;
	font-family: 'Roboto Slab', sans-serif;
	font-weight: 400;
}

.left-sidebar-custom h1 a,
.left-sidebar-custom h2 a,
.left-sidebar-custom h3 a,
.left-sidebar-custom h4 a,
.left-sidebar-custom h5 a,
.left-sidebar-custom h6 a,
.content-wrapper h1 a,
.content-wrapper h2 a,
.content-wrapper h3 a,
.content-wrapper h4 a,
.content-wrapper h5 a,
.content-wrapper h6 a,
.heading-link {
	border-bottom: 1px solid #ccc;
	-webkit-transition: all 200ms ease;
	transition: all 200ms ease;
	color: #231f20;
	background-color: #fff;
	text-decoration: none;
	box-shadow: none;
	font-weight: 400;
}

.left-sidebar-custom h1 a:hover,
.left-sidebar-custom h2 a:hover,
.left-sidebar-custom h3 a:hover,
.left-sidebar-custom h4 a:hover,
.left-sidebar-custom h5 a:hover,
.left-sidebar-custom h6 a:hover,
.content-wrapper h1 a:hover,
.content-wrapper h2 a:hover,
.content-wrapper h3 a:hover,
.content-wrapper h4 a:hover,
.content-wrapper h5 a:hover,
.content-wrapper h6 a:hover,
.heading-link:hover {
	border-bottom-color: transparent;
	color: #d8263c;
	box-shadow: none;
	background-color: #fff;
}

.intro-text,
.intro_paragraph,
.intro-paragraph {
	margin-bottom: 1.5rem;
	color: #2e2e2e;
	font-size: 1.25rem;
	line-height: 1.95rem;
}

.header-flex-container {
	position: relative;
	display: block;
	max-width: 1424px;
	margin-right: auto;
	margin-bottom: 0.75rem;
	margin-left: auto;
	padding: 0.15rem 0% 0.15rem 0px;
	-webkit-box-pack: end;
	-webkit-justify-content: flex-end;
	-ms-flex-pack: end;
	justify-content: flex-end;
	-webkit-box-align: start;
	-webkit-align-items: flex-start;
	-ms-flex-align: start;
	align-items: flex-start;
	background-image: none;
	-webkit-transition: all 450ms ease;
	transition: all 450ms ease;
}

.header-search-container {
	position: absolute;
	left: 2px;
	top: 100%;
	right: 2px;
	display: block;
	overflow: hidden;
	width: 100%;
	height: 100px;
	max-width: 1200px;
	min-height: 0px;
	margin-top: -1px;
	margin-right: auto;
	margin-left: auto;
	-webkit-box-pack: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
	justify-content: center;
}

.close-link {
	margin-bottom: 0.75rem;
	padding: 0.25rem 0.25rem 0.25rem 0.5rem;
	float: right;
	text-decoration: none;
	border-bottom: 0 solid transparent;
}

.close-x-wrapper {
	width: 1rem;
	height: 1rem;
	margin-left: 0.25rem;
	float: left;
}

.close-text {
	float: left;
	color: #000;
	font-size: 0.95rem;
	line-height: 1rem;
	font-weight: 400;
	text-decoration: none;
	text-transform: uppercase;
	border-bottom: 0 solid transparent;
}

.close-image {
	display: block;
}

.search-container-push-down-rotator {
	overflow: hidden;
	height: 0vh;
}

.search-wrapper {
	margin-top: 0px;
	margin-right: 0px;
	margin-bottom: 0px;
	float: right;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
	border: 2px none #fff;
	border-radius: 24px;
}

.slideshow-mask {
	display: block;
}

.intro-header {
	margin-top: 0rem;
	margin-bottom: 1rem;
	font-family: 'Roboto Slab', sans-serif;
	font-size: 2.5rem;
	line-height: 3rem;
	font-weight: 400;
}

.slide-caption-inner-wrap {
	padding: 0.5rem 1rem 1.75rem;
	border: 1px none #5f5f5f;
	background-color: #fff;
}

.slide-caption-inner-wrap hr,
.divider-line {
	display: block;
	width: 100px;
	margin: 0.75rem auto !important;
	background-color: #7a7a7a;
	border-color: #7a7a7a;
	border: 0px;
	color: #7a7a7a;
	border-top: 1px solid;
}

.slide-image-wrap {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	height: 100%;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
	background-image: url('../images/fresh-kitchen-tiny_1384x674.jpg');
	background-position: 50% 50%;
	background-size: cover;
	background-repeat: no-repeat;
	box-shadow: inset 0 0 30px 5px rgba(0, 0, 0, 0.2);
}

.slide-image-wrap.slide-2 {
	background-image: url('../images/apples-tiny_1384x674.jpg');
	background-position: 50% 50%;
	background-size: cover;
	background-repeat: no-repeat;
	box-shadow: inset 0 0 30px 5px rgba(0, 0, 0, 0.4);
}

.slide-image-wrap.slide-3 {
	background-image: url('../images/cutting-veggies-tiny_1384x674.jpg');
}

.slide-link {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	text-indent: -9999px;
	background: transparent;
}

.nav-menu-wrap {
	position: relative;
	display: block;
	width: 100%;
	max-width: none;
	margin-right: auto;
	margin-bottom: 0rem;
	margin-left: auto;
	-webkit-box-pack: justify;
	-webkit-justify-content: space-between;
	-ms-flex-pack: justify;
	justify-content: space-between;
	-webkit-flex-wrap: wrap;
	-ms-flex-wrap: wrap;
	flex-wrap: wrap;
	-webkit-box-align: end;
	-webkit-align-items: flex-end;
	-ms-flex-align: end;
	align-items: flex-end;
	border-bottom: 1px none hsla(0, 1%, 47%, 0.5);
}

.nav-menu-wrap.footer-menu-wrap {
	max-width: 1424px;
}

.cart-count {
	height: 22px;
	min-width: 22px;
	padding-top: 2px;
	padding-right: 5px;
	padding-left: 5px;
	border-radius: 14px;
	background-color: hsla(0, 1%, 47%, 0.3);
	-webkit-transition: all 200ms ease;
	transition: all 200ms ease;
	color: #000;
	font-size: 0.85rem;
	line-height: 1.25rem;
	font-weight: 500;
	text-align: center;
}

.cart-count:hover {
	background-color: #d8263c;
	color: #fff;
	cursor: pointer;
}

.desktop-search-button {
	width: 20px;
	height: 36px;
	padding: 1rem 1.3rem;
	float: right;
	border: 0 none;
	background-color: transparent;
	text-indent: -9999px;
	border-bottom: 1px none hsla(0, 0%, 100%, 0.55);
	background-image: url('../images/search_blk.svg');
	background-position: 50% 62%;
	background-size: 18px 18px;
	background-repeat: no-repeat;
	opacity: 0.5;
	cursor: pointer;
	-webkit-transition: all 200ms ease;
	transition: all 200ms ease;
}

.desktop-search-button:hover {
	background-image: url('../images/search_red-D8263C.svg');
	background-size: 18px 18px;
	opacity: 1;
}

.search-text {
	width: 120px;
	padding-top: 6px;
	padding-bottom: 6px;
	padding-left: 4px;
	font-family: 'Roboto Condensed';
	color: hsla(0, 0%, 100%, 0.55);
	font-size: 0.95rem;
	font-weight: 300;
}

.search-text:focus {
	position: relative;
	width: 130px;
}

.ecomm-functions {
	position: relative;
	margin-right: 20px;
	-webkit-box-pack: end;
	-webkit-justify-content: flex-end;
	-ms-flex-pack: end;
	justify-content: flex-end;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
}

.content-wrapper a.cart-link {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	margin-right: 0px;
	padding-right: 10px;
	padding-left: 10px;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
	-webkit-transition: all 200ms ease;
	transition: all 200ms ease;
	text-decoration: underline;
	box-shadow: none;
	color: #2e2e2e;
}

.content-wrapper a.cart-link:hover {
	color: #2e2e2e;
	background-color: transparent;
	cursor: pointer;
}

.content-wrapper a.cart-link:hover .cart-count {
	color: #fff;
	cursor: pointer;
}

.cart-icon {
	width: 19px;
	height: 19px;
	margin-right: 10px;
	background-image: url('../images/cart-blk.svg');
	background-position: 0px 0px;
	background-size: contain;
	background-repeat: no-repeat;
	opacity: 0.5;
}

.cart-links {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	align-items: center;
}

.separate {
	display: inline-block;
	padding: 0 7px;
}

.content-wrapper a.ecomm-link {
	display: block;
	margin-top: 10px;
	margin-bottom: 10px;
	padding-right: 10px;
	padding-left: 10px;
	-webkit-transition: all 200ms ease;
	transition: all 200ms ease;
	color: #000;
	background-color: #fff;
	font-size: 0.8rem;
	line-height: 1.35rem;
	font-weight: 500;
	text-decoration: none;
	border-bottom: 0 solid transparent;
	text-transform: uppercase;
	cursor: pointer;
}

.content-wrapper a.ecomm-link:hover {
	color: #d8263c;
	background-color: #fff;
	cursor: pointer;
}

.dropdown-wrap {
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
}

.dd-list a,
.dropdown-link {
	position: relative;
	display: block;
	margin-top: 0rem;
	padding: 0.5rem 0.5rem 0.5rem 1rem;
	-webkit-transition: all 200ms ease;
	transition: all 200ms ease;
	color: #000;
	background-color: #fff;
	font-size: 0.98rem;
	line-height: 1.4rem;
	letter-spacing: 0rem;
	text-decoration: none;
	border-bottom: 0 solid transparent;
}

.dd-list a:hover,
.dropdown-link:hover {
	-webkit-transform: translate(-5px, 0px);
	-ms-transform: translate(-5px, 0px);
	transform: translate(-5px, 0px);
	color: #d8263c;
	background-color: #fff;
}

.dd-list a.w--current,
.dropdown-link.w--current {
	background-color: transparent;
	background-image: url('../images/arrow-medium.svg');
	background-position: 0px 50%;
	background-size: 8px 8px;
	background-repeat: no-repeat;
	background-color: #fff;
	color: #d8263c;
}

.dd-list a.w--current:hover,
.dropdown-link.w--current:hover {
	-webkit-transform: none;
	-ms-transform: none;
	transform: none;
}

.dd-list a,
.dropdown-link {
	padding-left: 0.75rem;
	cursor: pointer;
}

.search-text {
	width: 120px;
	height: 36px;
	margin-bottom: 0px;
	padding: 6px 5px 0px;
	float: left;
	border-style: none !important;
	border-bottom-width: 1px !important;
	border-bottom-color: #000 !important;
	background-color: transparent;
	-webkit-transition: all 300ms ease;
	transition: all 300ms ease;
	font-family: Montserrat, sans-serif;
	color: #2e2e2e;
	font-size: 0.8rem;
	line-height: 1.35rem;
	font-weight: 500;
}

.search-text:focus {
	width: 170px;
	padding-left: 10px;
	border-bottom-style: none;
	color: #797676;
	text-decoration: none;
	border-bottom: 0 solid transparent;
}

.search-text::-webkit-input-placeholder {
	font-family: Montserrat, sans-serif;
	color: #797676;
	font-size: 0.8rem;
	line-height: 1.35rem;
	font-weight: 500;
	text-transform: uppercase;
}

.search-text:-ms-input-placeholder {
	font-family: Montserrat, sans-serif;
	color: #797676;
	font-size: 0.8rem;
	line-height: 1.35rem;
	font-weight: 500;
	text-transform: uppercase;
}

.search-text::-ms-input-placeholder {
	font-family: Montserrat, sans-serif;
	color: #797676;
	font-size: 0.8rem;
	line-height: 1.35rem;
	font-weight: 500;
	text-transform: uppercase;
}

.search-text::placeholder {
	font-family: Montserrat, sans-serif;
	color: #797676;
	font-size: 0.8rem;
	line-height: 1.35rem;
	font-weight: 500;
	text-transform: uppercase;
}

.search-form {
	height: 36px;
	border-bottom: 1px solid hsla(0, 1%, 47%, 0.5);
}

.mobile-menu-search-wrap {
	display: none;
}

.inside-page-wrapper {
	display: block;
	padding-top: 120px;
	background-image: var(--main-bg-image);
	background-image: -webkit-image-set(var(--main-bg-image) 1x, var(--fallback-bg-image) 1x);
	background-image: image-set(var(--main-bg-image) 1x, var(--fallback-bg-image) 1x);
	background-position: 0px 0px;
	background-size: 512px;
	color: #2e2e2e;
	background-color: #fff;
}

.inside-page-banner-section {
	position: relative;
	display: block;
	width: 100%;
	height: 576px;
	margin-right: auto;
	margin-left: auto;
	background-position: 50% 50%;
	background-size: cover;
	background-repeat: no-repeat;
}

.inside-page-banner-section.banner-image-2 {
	background-image: url('../images/veg-cutting-board-tiny_1903x926.jpg');
	background-position: 50% 50%;
	background-size: cover;
	background-repeat: no-repeat;
}

.left-sidebar-custom a,
.content-wrapper a,
.text-link {
	padding-right: 2px;
	padding-left: 2px;
	box-shadow: inset 0 -1px 0 0 rgba(0, 0, 0, 0.5);
	-webkit-transition: all 200ms ease;
	transition: all 200ms ease;
	color: #d8263c;
	background-color: #fff;
	font-weight: 500;
	text-decoration: none;
	border-bottom: 0 solid transparent;
}

.left-sidebar-custom a:hover,
.content-wrapper a:hover,
.text-link:hover {
	color: #2e2e2e;
	background-color: #dedfe4;
	box-shadow: inset 0 -1px 0 0 transparent;
}

.text-link.breadcrumb-link {
	padding-right: 1px;
	padding-left: 1px;
	border-bottom: 1px solid rgba(0, 0, 0, 0.5);
	box-shadow: none;
	color: #424242;
	font-size: 0.75rem;
	font-weight: 400;
}

.text-link.breadcrumb-link:hover {
	border-bottom-color: transparent;
	background-color: transparent;
	box-shadow: none;
	color: #d8263c;
}

.inside-content-wrapper {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	margin-right: 3%;
	margin-left: 3%;
	padding: 1rem 1rem 0rem;
	background-color: #fff;
}

.inside-content-wrapper.page-title-container {
	position: absolute;
	left: 0px;
	right: 0px;
	bottom: 0px;
	display: block;
	width: 100%;
	max-width: 1300px;
	margin-right: auto;
	margin-left: auto;
	padding: 0rem 0%;
	-webkit-box-pack: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
	justify-content: center;
	-webkit-box-align: end;
	-webkit-align-items: flex-end;
	-ms-flex-align: end;
	align-items: flex-end;
	background-color: transparent;
}

.right-side-col .box,
.right-col-item-wrap {
	padding-bottom: 1rem;
	border-top: 1px solid hsla(0, 1%, 47%, 0.5);
}

.important-text {
	font-weight: 400;
}

.h1-page-title {
	margin-top: 1.25rem;
	margin-bottom: 1.25rem;
	font-size: 3.5rem;
	line-height: 4.5rem;
	font-weight: 400;
	text-align: center;
	text-transform: none;
}

.page-title-inner-wrap {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	margin-right: 3%;
	margin-left: 3%;
	padding-right: 10%;
	padding-left: 10%;
	-webkit-box-pack: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
	justify-content: center;
	-webkit-box-align: end;
	-webkit-align-items: flex-end;
	-ms-flex-align: end;
	align-items: flex-end;
	border-bottom: 4px solid #d8263c;
	background-color: hsla(0, 0%, 100%, 0.8);
}

.left-nav-section-title-wrap {
	position: absolute;
	top: 0px;
	right: -9px;
	overflow: hidden;
	width: 127%;
	background-image: url('../images/banner-inset-3.png');
	background-size: 290px 50px;
	background-repeat: no-repeat;
	box-shadow: -5px 0 5px -2px rgba(0, 0, 0, 0.35);
	background-position: 100% 50%;
}

.outline-wrap {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	padding: 1.5rem 1.5rem 2.5rem;
	border-style: solid solid none;
	border-width: 1px 1px 0px;
	border-color: hsla(0, 1%, 47%, 0.5) hsla(0, 1%, 47%, 0.5) transparent;
	width: 100%;
}

.nav-link-wrap {
	border-bottom: 1px solid #ccc;
}

.form-success-message {
	margin-top: 20px;
	margin-bottom: 20px;
	padding: 20px;
	background-color: #b5eebe;
	font-size: 1.125rem;
}

.form-error-message {
	margin-top: 20px;
	margin-bottom: 20px;
	padding: 20px;
	background-color: #f5d1d1;
	font-size: 1.125rem;
}

.prep-time {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	width: 50%;
	height: 100%;
	padding-top: 2px;
	float: right;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-webkit-flex-direction: column;
	-ms-flex-direction: column;
	flex-direction: column;
	-webkit-box-pack: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
	justify-content: center;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
}

.prep-time-inside-wrap {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	margin-bottom: 4px;
	-webkit-box-pack: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
	justify-content: center;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
	text-align: center;
}

.card-info-text {
	display: inline-block;
	font-size: 0.96rem;
	line-height: 1.3rem;
	letter-spacing: -0.02rem;
	text-transform: uppercase;
}

.card-info-text.card-small-text {
	font-size: 0.75rem;
	line-height: 1rem;
	text-transform: uppercase;
}

.card-info-text.ulc {
	text-align: left;
	text-transform: none;
}

.card-prep-icon {
	margin-right: 3px;
	width: 20px;
	height: 20px;
	background-image: url('/20190103254455/assets/images/clock-black.svg');
	background-size: 20px 20px;
	background-position: 50% 50%;
}

.card-prep-icon.heart {
	background-image: url('/20190103254455/assets/images/heart-black.svg');
}

.save.js-save:hover .card-prep-icon.heart {
	background-image: url('/20190103254455/assets/images/heart-red-outline.svg');
}

.hearthover {
	position: absolute;
	left: 0px;
	top: 0px;
	right: 0px;
	bottom: 0px;
	z-index: 2;
	width: 20px;
	height: 20px;
	background-image: url('../images/heart-white.svg');
	background-position: 50% 50%;
	background-size: 20px 20px;
	background-repeat: no-repeat;
}

.hearthover:hover {
	background-image: url('../images/heart-red-outline.svg');
	background-size: 20px 20px;
}

.hearthover:active {
	background-image: none;
	background-size: auto;
}

.hearthover:focus {
	background-image: none;
}

.heart-save {
	position: absolute;
	left: 0px;
	top: 0px;
	right: 0px;
	bottom: 0px;
	z-index: 1;
	display: none;
	width: 20px;
	height: 20px;
	background-image: url('../images/heart-red.svg');
	background-position: 50% 50%;
	background-size: 20px 20px;
	background-repeat: no-repeat;
	opacity: 1;
}

.recipe-filter-dd-scroll-wrap {
	position: absolute;
	display: none;
	overflow: auto;
	width: 260px;
	height: 401px;
	margin-top: -1px;
	border: 1px solid #ccc;
	background-color: #fff;
}

.styles-page-container {
	max-width: 1024px;
	margin-top: 48px;
	margin-right: auto;
	margin-left: auto;
}

.field-label {
	font-weight: 500;
}

.highlight-note {
	background-color: #fff200;
	cursor: default;
}

.flexy-styles-container {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
}

.utility-nav {
	position: relative;
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	height: 55px;
	padding-top: 10px;
	padding-left: 0.75rem;
	-webkit-box-pack: end;
	-webkit-justify-content: flex-end;
	-ms-flex-pack: end;
	justify-content: flex-end;
	-webkit-box-align: start;
	-webkit-align-items: flex-start;
	-ms-flex-align: start;
	align-items: flex-start;
}

.dd-list {
	position: absolute;
	display: block;
	z-index: 899;
	height: 0px;
	max-width: 400px;
	min-width: 200px;
	border-style: solid;
	border-width: 1px;
	border-color: hsla(0, 1%, 47%, 0.5);
	background-color: #fff;
	box-shadow: 0 6px 5px 0 rgba(0, 0, 0, 0.5);
	opacity: 0;
	text-align: left;
	list-style-type: none;
	margin: 0;
	padding: 0 0.5rem;
	overflow: hidden;
	-webkit-transition: padding 200ms ease;
	transition: padding 200ms ease;
}

.open .dd-list {
	overflow: visible;
	height: auto;
	opacity: 1;
	padding: 0.5rem;
}

.dd-list li,
.dd-link-wrap {
	border-bottom: 1px solid hsla(0, 1%, 47%, 0.5);
}

.top-nav-divider {
	display: block;
	width: 2px;
	height: 21px;
	margin: 0px 10px;
	padding-right: 0px;
	padding-left: 0px;
	border-left: 1px solid hsla(0, 1%, 47%, 0.5);
	-webkit-transition: all 200ms ease;
	transition: all 200ms ease;
	color: #000;
	font-size: 0.8rem;
	line-height: 1.35rem;
	font-weight: 500;
	text-decoration: none;
	border-bottom: 0 solid transparent;
	text-transform: uppercase;
	cursor: pointer;
}

.top-nav-divider:hover {
	color: #d8263c;
	opacity: 1;
}

.slash {
	margin-top: 10px;
	margin-bottom: 10px;
	font-size: 0.8rem;
	line-height: 1.35rem;
}

.header-top {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	max-width: 1700px;
	-webkit-box-pack: justify;
	-webkit-justify-content: space-between;
	-ms-flex-pack: justify;
	justify-content: space-between;
}

.instagram-widget-wrapper {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	width: 100%;
	margin-top: 2.5rem;
	margin-bottom: 1rem;
}

.instagram-img {
	width: 100%;
	height: 100%;
}

.insta-tile {
	position: relative;
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	width: 25%;
	max-width: 25%;
	padding: 6px;
}

.author-name {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	margin-bottom: 0px;
	-webkit-box-pack: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
	justify-content: center;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
	font-size: 0.8125rem;
	line-height: 1.125em;
}

.card {
	position: static;
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	width: auto;
	min-height: 380px;
	margin-right: 1%;
	margin-bottom: 1.75rem;
	margin-left: 1%;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-webkit-flex-direction: column;
	-ms-flex-direction: column;
	flex-direction: column;
	-webkit-box-flex: 0;
	-webkit-flex-grow: 0;
	-ms-flex-positive: 0;
	flex-grow: 0;
	-webkit-flex-basis: 23%;
	-ms-flex-preferred-size: 23%;
	flex-basis: 23%;
	border-style: solid;
	border-width: 10px 1px 1px;
	border-color: #ec1c24 hsla(0, 1%, 47%, 0.5) hsla(0, 1%, 47%, 0.5);
	background-color: #fff;
	text-align: center;
}

.card.full-size-image {
	border-top-color: #ec1c24;
	position: relative;
}

.card.full-size-image a {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	text-indent: -9999px;
	background-position: 50% 50%;
	background-size: cover;
	background-repeat: no-repeat;
}

.card.locator {
	position: relative;
	background-color: #000;
	background-image: -webkit-gradient(linear, left top, left bottom, color-stop(39%, transparent), color-stop(85%, rgba(0, 0, 0, 0.95))), url('../images/Store_Locator_generic.webp');
	background-image: linear-gradient(180deg, transparent 39%, rgba(0, 0, 0, 0.95) 85%), url('../images/Store_Locator_generic.webp');
	background-position: 0px 0px, 50% 50%;
	background-size: auto, cover;
	background-repeat: repeat, no-repeat;
}

.card.interviews {
	position: relative;
}

.card-title {
	margin-top: 0px;
	margin-bottom: 15px;
	font-family: Montserrat, sans-serif;
	font-size: 1.125rem;
	line-height: 1.7rem;
	font-weight: 500;
	text-transform: uppercase;
}

.card-title.white {
	color: #fff;
	font-family: inherit;
}

.author-pic {
	margin-top: -30px;
	margin-right: 3px;
	border: 5px solid #fff;
	border-radius: 50px;
	height: 60px;
}

.heading-link-insta {
	border-bottom: 1px solid #ccc;
	-webkit-transition: all 200ms ease;
	transition: all 200ms ease;
	color: #231f20;
	text-decoration: none;
}

.heading-link-insta:hover {
	border-bottom-color: transparent;
	color: #d8263c;
}

.cards-parent {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	margin-right: -1%;
	margin-bottom: 2rem;
	margin-left: -1%;
	-webkit-box-pack: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
	justify-content: center;
	-webkit-flex-wrap: wrap;
	-ms-flex-wrap: wrap;
	flex-wrap: wrap;
	width: 100%;
}

.card-image {
	width: 100%;
	height: 190px;
	background-position: 50% 50%;
	background-size: cover;
	background-repeat: no-repeat;
}

.card-image.meat-mkt {
	background-image: url('../images/MS_Home_Tile_C8021FA8CFBFE.jpg');
	background-position: 50% 50%;
	background-size: cover;
	background-repeat: no-repeat;
}

.card-image.interviews {
	background-image: url('../images/partial-tile-test_420x210.jpg'), url('../images/open-interviews-red.jpg');
	background-position: 50% 50%, 50% 50%;
	background-size: cover, cover;
	background-repeat: no-repeat, no-repeat;
}

.card-image.centsable-image {
	background-image: url('../images/Touchdownheader.jpg');
	background-position: 50% 50%;
	background-size: cover;
	background-repeat: no-repeat;
}

.card-image.recipe-image {
	background-image: url('../images/pumpkin_bites_EAB6591421223.jpg');
	background-position: 50% 50%;
	background-size: cover;
	background-repeat: no-repeat;
}

.card-image.article-image {
	background-image: url('../images/family_table2_687AD29BEB362.jpg');
	background-position: 50% 50%;
	background-size: cover;
	background-repeat: no-repeat;
}

.card-image.recipe-image-2 {
	background-image: url('../images/chicken_sausage_and_kale_crop_7203CBA255A3C.jpg');
	background-position: 50% 50%;
	background-size: cover;
	background-repeat: no-repeat;
}

.recipe-clear-search-icon#clearSearchIcon {
	position: absolute;
	right: 5px;
	height: 100%;
	background-color: transparent;
	background-image: url('../images/icons/remove-grey.svg');
	background-repeat: no-repeat;
	background-size: 10px 10px;
	background-position: center center;
}

.bkgd-image-alt-span {
	text-indent: -9999px;
}

.card-content {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	padding: 20px 20px 10px;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-webkit-flex-direction: column;
	-ms-flex-direction: column;
	flex-direction: column;
	-webkit-box-flex: 1;
	-webkit-flex-grow: 1;
	-ms-flex-positive: 1;
	flex-grow: 1;
	-webkit-flex-shrink: 0;
	-ms-flex-negative: 0;
	flex-shrink: 0;
}

.card-content.locator-card {
	position: absolute;
	left: 0px;
	right: 0px;
	bottom: 0px;
}

.card-title-link {
	padding-right: 2px;
	padding-left: 2px;
	border-bottom: 1px solid #ccc;
	-webkit-transition: all 200ms ease;
	transition: all 200ms ease;
	color: #2e2e2e;
	text-decoration: none;
}

.card-title-link:hover {
	box-shadow: none;
	color: #d8263c;
	text-decoration: none;
	border-bottom: 0 solid transparent;
}

.page-background {
	background-image: var(--main-bg-image);
	background-image: -webkit-image-set(var(--main-bg-image) 1x, var(--fallback-bg-image) 1x);
	background-image: image-set(var(--main-bg-image) 1x, var(--fallback-bg-image) 1x);
	background-position: 50% 21px;
	background-size: 512px;
}

.card-bottom {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	height: 62px;
	-webkit-box-pack: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
	justify-content: center;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
	border-top: 1px solid hsla(0, 1%, 47%, 0.5);
	font-weight: 500;
	text-transform: uppercase;
	padding-left: 10px;
	padding-right: 10px;
}

.card-bottom .js-save {
	padding-right: 10px;
}

.card-bottom .prep-time {
	padding-left: 10px;
}

.card-bottom.card-centsable {
	padding-left: 10px;
	-webkit-box-pack: start;
	-webkit-justify-content: flex-start;
	-ms-flex-pack: start;
	justify-content: flex-start;
}

.date {
	margin-top: 0rem;
	margin-bottom: 0rem;
	font-size: 0.8125rem;
	line-height: 1.125rem;
}

.card-action-link {
	color: #d8263c;
	text-decoration: none;
	border-bottom: 0 solid transparent;
}

.card-action-link:hover {
	color: #2e2e2e;
	text-decoration: underline;
}

.card-paragraph {
	line-height: 1.5rem;
}

.save {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	width: 50%;
	height: 100%;
	padding-top: 2px;
	padding-right: 0px;
	padding-left: 0px;
	float: left;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-webkit-flex-direction: column;
	-ms-flex-direction: column;
	flex-direction: column;
	-webkit-box-pack: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
	justify-content: center;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
	border-right: 1px solid hsla(0, 1%, 47%, 0.5);
	-webkit-transition: all 200ms ease;
	transition: all 200ms ease;
	color: #2e2e2e;
	font-weight: 500;
	text-decoration: none;
	border-bottom: 0 solid transparent;
	cursor: pointer;
}

.save:hover {
	color: #d8263c;
	cursor: pointer;
}

.insta-parent {
	margin-right: -6px;
	margin-left: -6px;
	padding: 1rem 0rem;
	text-align: center;
	width: 100%;
}

.copyright-wrapper {
	display: inline-block;
	margin-right: 0.5rem;
}

.inside-content-wrap {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	margin-right: 3%;
	margin-left: 3%;
	padding: 1rem 1rem 0rem;
	background-color: #fff;
	box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.35);
}

.fake-wysiwyg-image {
	width: 50%;
}

html.w-mod-js *[data-ix="shop-arrow"] {
	opacity: 0;
	-webkit-transform: translate(-30px, 0px);
	-ms-transform: translate(-30px, 0px);
	transform: translate(-30px, 0px);
}

html.w-mod-js *[data-ix="slide-caption-load"] {
	opacity: 0;
	-webkit-transform: scale(0.6600000000000025, 0.6600000000000025);
	-ms-transform: scale(0.6600000000000025, 0.6600000000000025);
	transform: scale(0.6600000000000025, 0.6600000000000025);
}

.inside-page-banner-section-map {
	display: none;
}

@media (max-width: 1630px) {
	.main-nav-ul li:last-of-type .dd-list {
		right: 0;
	}
}

@media (max-width: 1200px) {
	.author-name {
		font-size: 0.7rem;
	}
}

@media (max-width: 991px) {
	.slideshow-arrow {
		display: none;
	}

	.rotator-section {
		width: 100%;
	}

	h1 {
		margin-right: 0rem;
		font-size: 2.25rem;
		line-height: 3rem;
	}

	h3 {
		font-size: 1.65rem;
		line-height: 2.35rem;
	}

	h4 {
		font-size: 1.4rem;
		line-height: 2.1rem;
	}

	.button.search-button.search-top-button {
		width: 25%;
		border-radius: 0px 3px 3px 0px;
	}

	.main-content-wrapper {
		width: 100%;
		margin-top: 0rem;
		padding-top: 0.5rem;
		padding-bottom: 0.75rem;
	}

	.container {
		max-width: 991px;
	}

	.container.inside-page-content-container {
		position: relative;
		max-width: 991px;
		min-height: 0px;
		padding-right: 1.25rem;
		padding-left: 1.25rem;
	}

	.container.inside-page-content-container .inside-content-wrap .outline-wrap .left-nav-col + .inside-content-column {
		width: 100%;
	}

	.container.inside-page-content-container.page-title-container {
		padding-right: 0rem;
		padding-left: 0rem;
	}

	.container.footer-container {
		max-width: 991px;
	}

	.container.signoff-container {
		max-width: 991px;
		-webkit-box-orient: vertical;
		-webkit-box-direction: normal;
		-webkit-flex-direction: column;
		-ms-flex-direction: column;
		flex-direction: column;
		text-align: center;
	}

	.container.cards-container {
		max-width: 991px;
		-webkit-flex-wrap: wrap;
		-ms-flex-wrap: wrap;
		flex-wrap: wrap;
	}

	.container.insta-container {
		max-width: 991px;
		-webkit-flex-wrap: wrap;
		-ms-flex-wrap: wrap;
		flex-wrap: wrap;
	}

	.left-nav-col {
		display: none;
	}

	.inside-content-column {
		width: 100%;
		margin-top: 0rem;
		margin-left: 0rem;
	}

	.inside-content-column.no-sidebars-content-column {
		margin-right: 0%;
		margin-left: 0%;
	}

	.breadcrumbs-wrapper {
		line-height: 1.5rem;
	}

	.breadcrumb-link {
		margin-top: 0.25rem;
	}

	.breadcrumb-current {
		margin-top: 0.25rem;
	}

	.content-wrapper {
		-webkit-box-orient: vertical;
		-webkit-box-direction: normal;
		-webkit-flex-direction: column;
		-ms-flex-direction: column;
		flex-direction: column;
	}

	.right-side-col {
		width: 100%;
		margin-top: 0rem;
		margin-left: 0rem;
		padding-bottom: 0rem;
	}

	.header-section {
		position: relative;
		padding-right: 0%;
		padding-left: 0%;
	}

	.navbar.footer-nav-bar {
		z-index: 999;
	}

	.brand.w--current {
		margin-top: 1.25rem;
		margin-left: 0px;
	}

	.nav-menu {
		display: block;
		width: 90%;
		margin-top: 0px;
		background-color: #fff;
	}

	.nav-menu.footer-menu {
		display: -webkit-box;
		display: -webkit-flex;
		display: -ms-flexbox;
		display: flex;
		width: 100%;
		-webkit-box-pack: center;
		-webkit-justify-content: center;
		-ms-flex-pack: center;
		justify-content: center;
		-webkit-flex-wrap: wrap;
		-ms-flex-wrap: wrap;
		flex-wrap: wrap;
	}

	.main-nav-link {
		width: 100%;
		padding-top: 0.75rem;
		padding-bottom: 0.75rem;
		-webkit-box-pack: start;
		-webkit-justify-content: flex-start;
		-ms-flex-pack: start;
		justify-content: flex-start;
		border-bottom: 1px solid hsla(0, 1%, 47%, 0.5);
		text-align: left;
	}

	.main-nav-link:hover {
		box-shadow: none;
	}

	.main-nav-link.active-item {
		box-shadow: none;
	}

	.main-nav-link.mobile-duplicate {
		display: block;
		width: 100%;
		margin-right: 0px;
		-webkit-box-pack: justify;
		-webkit-justify-content: space-between;
		-ms-flex-pack: justify;
		justify-content: space-between;
		border-bottom-style: solid;
		-webkit-transition-timing-function: cubic-bezier(0.455, 0.03, 0.515, 0.955);
		transition-timing-function: cubic-bezier(0.455, 0.03, 0.515, 0.955);
	}

	.main-nav-link.mobile-duplicate:hover {
		-webkit-transform: none;
		-ms-transform: none;
		transform: none;
	}

	.main-nav-link.mobile-duplicate.w--current {
		height: auto;
		box-shadow: inset 2px 0 0 0 #ec1c24;
		color: #d8263c;
	}

	.main-nav-link.mobile-duplicate.w--current:hover {
		-webkit-transform: none;
		-ms-transform: none;
		transform: none;
	}

	.main-nav-link.two-part-mobile-nav-link-duplicate {
		width: 88%;
		-webkit-box-pack: start;
		-webkit-justify-content: flex-start;
		-ms-flex-pack: start;
		justify-content: flex-start;
		border-bottom-style: none;
	}

	.main-nav-link.footer-main {
		display: block;
		width: auto;
		margin-right: 2vw;
		margin-left: 2vw;
		-webkit-box-pack: justify;
		-webkit-justify-content: space-between;
		-ms-flex-pack: justify;
		justify-content: space-between;
		border-bottom-style: none;
		-webkit-transition-timing-function: cubic-bezier(0.455, 0.03, 0.515, 0.955);
		transition-timing-function: cubic-bezier(0.455, 0.03, 0.515, 0.955);
	}

	.main-nav-link.footer-main:hover {
		box-shadow: none;
		text-decoration: underline;
	}

	.main-nav-link.footer-main.w--current {
		box-shadow: inset 0 0 0 0 transparent;
		font-weight: 700;
	}

	.main-nav-link.footer-main.w--current:hover {
		box-shadow: none;
		-webkit-transform: none;
		-ms-transform: none;
		transform: none;
	}

	.main-nav-link.two-part {
		border-bottom-style: none;
	}

	.rotator-section {
		position: static;
		top: 139px;
		margin-top: 0px;
		padding-right: 0%;
		padding-left: 0%;
	}

	.slideshow-arrow.left-arrow {
		width: 50px;
		height: 80%;
		color: #fff;
		font-size: 2.5rem;
		display: none !important;
	}

	.slideshow-arrow.left-arrow:hover {
		-webkit-transform: none;
		-ms-transform: none;
		transform: none;
	}

	.slideshow-arrow.right-arrow {
		width: 50px;
		height: 80%;
		color: #fff;
		font-size: 2.5rem;
		display: none !important;
	}

	.slideshow-arrow.right-arrow:hover {
		-webkit-transform: none;
		-ms-transform: none;
		transform: none;
	}

	.slide-caption-inner-wrap h1,
	.slide-caption-inner-wrap h2,
	.slide-caption-inner-wrap h3,
	.slide-caption-header {
		font-size: 2.25rem;
		line-height: 2.75rem;
	}

	.arrow-icon {
		display: -webkit-box;
		display: -webkit-flex;
		display: -ms-flexbox;
		display: flex;
		width: 100%;
		height: 100%;
		margin-right: 0rem;
		margin-left: 0rem;
		padding-right: 1rem;
		padding-left: 1rem;
		-webkit-box-pack: center;
		-webkit-justify-content: center;
		-ms-flex-pack: center;
		justify-content: center;
		-webkit-box-align: center;
		-webkit-align-items: center;
		-ms-flex-align: center;
		align-items: center;
		-webkit-transition: all 200ms ease;
		transition: all 200ms ease;
		font-size: 1.5rem;
	}

	.arrow-icon:hover {
		color: #d8263c;
		cursor: pointer;
	}

	.arrow-icon.left-arrow-icon {
		margin-right: 0rem;
		margin-left: 0rem;
	}

	.slide {
		padding: 0rem;
		border-style: none;
	}

	.home-content-section {
		padding-right: 3%;
		padding-left: 3%;
	}

	.home-content-section.hcs-insta {
		padding-bottom: 1rem;
	}

	.home-content-section .cards-parent .card-container {
		width: 31%;
	}

	.cb-static {
		width: 100%;
	}

	.cb-static.footer-cb-3 {
		width: 100%;
	}

	.signoff-wrapper {
		padding-top: 0.25rem;
	}

	.footer-grip-link {
		-webkit-box-flex: 0;
		-webkit-flex: 0 0 auto;
		-ms-flex: 0 0 auto;
		flex: 0 0 auto;
		margin-top: 2rem;
	}

	.copyright-paragraph {
		margin-right: 0.25rem;
	}

	.signoff-link {
		margin-left: 0rem;
	}

	.menu-button {
		display: block;
		width: 50px;
		height: 50px;
		margin-right: 0px;
		padding: 0.5rem 0.1rem;
		float: right;
		clear: left;
		-webkit-box-ordinal-group: 2;
		-webkit-order: 1;
		-ms-flex-order: 1;
		order: 1;
		-webkit-transition: all 300ms ease;
		transition: all 300ms ease;
		color: #000;
		text-align: center;
	}

	.menu-button:hover {
		color: #d8263c;
		cursor: pointer;
	}

	.menu-button.w--open {
		background-color: transparent;
		color: #000;
		cursor: pointer;
	}

	.menu-icon {
		font-size: 2rem;
		line-height: 2rem;
	}

	.menu-text {
		font-size: 0.75rem;
		line-height: 0.75rem;
	}

	.mobile-link-label {
		display: inline-block;
		margin-bottom: 0rem;
	}

	.mobile-menu-arrow {
		display: -webkit-box;
		display: -webkit-flex;
		display: -ms-flexbox;
		display: flex;
		width: 12%;
		-webkit-box-pack: center;
		-webkit-justify-content: center;
		-ms-flex-pack: center;
		justify-content: center;
		-webkit-box-align: center;
		-webkit-align-items: center;
		-ms-flex-align: center;
		align-items: center;
		-webkit-box-flex: 0;
		-webkit-flex: 0 0 auto;
		-ms-flex: 0 0 auto;
		flex: 0 0 auto;
		border-left: 1px solid hsla(0, 1%, 47%, 0.5);
		-webkit-transition: all 200ms cubic-bezier(0.455, 0.03, 0.515, 0.955);
		transition: all 200ms cubic-bezier(0.455, 0.03, 0.515, 0.955);
	}

	.social-media-wrapper.footer-socmed-wrapper {
		-webkit-flex-wrap: wrap;
		-ms-flex-wrap: wrap;
		flex-wrap: wrap;
	}

	.copyright-privacy-sitemap-wrapper {
		width: 60%;
		-webkit-box-flex: 0;
		-webkit-flex: 0 auto;
		-ms-flex: 0 auto;
		flex: 0 auto;
	}

	.two-part-mobile-nav-wrapper {
		display: -webkit-box;
		display: -webkit-flex;
		display: -ms-flexbox;
		display: flex;
		-webkit-box-pack: justify;
		-webkit-justify-content: space-between;
		-ms-flex-pack: justify;
		justify-content: space-between;
		border-bottom: 1px solid hsla(0, 1%, 47%, 0.5);
	}

	.header-search-field {
		width: 75%;
		border-style: solid none solid solid;
		border-width: 1px;
		border-color: hsla(0, 1%, 47%, 0.5);
		border-radius: 3px 0px 0px 3px;
	}

	.search-dd-button {
		display: none;
	}

	.search-dd-button.mobile-search-dd-button {
		display: block;
		width: 50px;
		height: 50px;
		margin-right: 10px;
		padding: 0.5rem;
		float: right;
		clear: right;
		background-image: url('../images/search_blk.svg');
		background-position: 50% 50%;
		background-size: 20px 20px;
		background-repeat: no-repeat;
		cursor: pointer;
		-webkit-transition: all 0 ease !important;
		transition: all 0 ease !important;
		-webkit-transition: background-image 200ms ease !important;
		transition: background-image 200ms ease !important;
	}

	.search-dd-button.mobile-search-dd-button:hover {
		background-image: url('../images/search_red-D8263C.svg');
	}

	.header-search-form.search-top-form {
		display: -webkit-box;
		display: -webkit-flex;
		display: -ms-flexbox;
		display: flex;
		width: 80%;
		height: auto;
		margin-right: 2rem;
		-webkit-box-align: center;
		-webkit-align-items: center;
		-ms-flex-align: center;
		align-items: center;
		-webkit-box-flex: 1;
		-webkit-flex: 1;
		-ms-flex: 1;
		flex: 1;
	}

	.header-search-form-wrapper.search-top-wrapper {
		display: -webkit-box;
		display: -webkit-flex;
		display: -ms-flexbox;
		display: flex;
		height: auto;
		padding-top: 1.5rem;
		padding-bottom: 1.5rem;
		-webkit-box-pack: justify;
		-webkit-justify-content: space-between;
		-ms-flex-pack: justify;
		justify-content: space-between;
		-webkit-box-align: center;
		-webkit-align-items: center;
		-ms-flex-align: center;
		align-items: center;
		border-bottom: 1px solid #5f5f5f;
		background-color: transparent;
	}

	.intro-text,
	.intro_paragraph,
	.intro-paragraph {
		margin-top: 0rem;
		font-size: 1.3rem;
		line-height: 2rem;
	}

	.header-flex-container {
		display: block;
		margin-bottom: 1.25rem;
		padding: 0rem 1.5rem;
		-webkit-box-orient: horizontal;
		-webkit-box-direction: normal;
		-webkit-flex-direction: row;
		-ms-flex-direction: row;
		flex-direction: row;
		-webkit-box-pack: justify;
		-webkit-justify-content: space-between;
		-ms-flex-pack: justify;
		justify-content: space-between;
		-webkit-flex-wrap: wrap;
		-ms-flex-wrap: wrap;
		flex-wrap: wrap;
	}

	.close-link {
		width: 32px;
		height: 32px;
		margin-bottom: 0rem;
		border-radius: 14px;
		background-color: #fff;
		background-image: url('../images/close_x_black.svg');
		background-position: 50% 50%;
		background-size: 20px 20px;
		background-repeat: no-repeat;
		-webkit-transition: all 200ms ease;
		transition: all 200ms ease;
		color: #fff;
		cursor: pointer;
	}

	.close-link:hover {
		background-color: #dedfe4;
		opacity: 1;
	}

	.ecomm-functions {
		display: none;
		float: left;
		-webkit-box-pack: start;
		-webkit-justify-content: flex-start;
		-ms-flex-pack: start;
		justify-content: flex-start;
		-webkit-box-flex: 0;
		-webkit-flex: 0 auto;
		-ms-flex: 0 auto;
		flex: 0 auto;
	}

	.mobile-menu-search-wrap {
		display: -webkit-box;
		display: -webkit-flex;
		display: -ms-flexbox;
		display: flex;
		float: right;
		-webkit-box-align: center;
		-webkit-align-items: center;
		-ms-flex-align: center;
		align-items: center;
	}

	.search-container-top {
		display: none;
		overflow: hidden;
		height: auto;
		max-height: 0;
		background-color: #fff;
		-webkit-transition: max-height 500ms ease 0s;
		transition: max-height 500ms ease 0s;
	}

	.search-close-wrapper {
		display: -webkit-box;
		display: -webkit-flex;
		display: -ms-flexbox;
		display: flex;
		height: auto;
		-webkit-box-align: center;
		-webkit-align-items: center;
		-ms-flex-align: center;
		align-items: center;
	}

	.inside-page-wrapper {
		padding-top: 0px;
	}

	.inside-page-banner-section {
		height: 347px;
	}

	.inside-content-wrapper {
		margin-right: 2%;
		margin-left: 2%;
	}

	.inside-content-wrapper.page-title-container {
		max-width: 991px;
		padding-right: 1.25rem;
		padding-left: 1.25rem;
	}

	.right-col-item-wrap {
		padding-bottom: 0.75rem;
	}

	.h1-page-title {
		font-size: 2.5rem;
		line-height: 3.5rem;
	}

	.page-title-inner-wrap {
		margin-right: 2%;
		margin-left: 2%;
		padding-right: 3%;
		padding-left: 3%;
	}

	.outline-wrap {
		padding-top: 0.75rem;
		padding-bottom: 2rem;
	}

	.card-info-text {
		font-size: 0.9rem;
	}

	.card-info-text.card-small-text {
		font-size: 0.68rem;
	}

	.styles-page-container {
		max-width: 991px;
	}

	.utility-nav {
		height: auto;
		padding-top: 5px;
		-webkit-box-orient: vertical;
		-webkit-box-direction: normal;
		-webkit-flex-direction: column;
		-ms-flex-direction: column;
		flex-direction: column;
		-webkit-box-pack: center;
		-webkit-justify-content: center;
		-ms-flex-pack: center;
		justify-content: center;
		-webkit-box-align: end;
		-webkit-align-items: flex-end;
		-ms-flex-align: end;
		align-items: flex-end;
	}

	.instagram-widget-wrapper {
		-webkit-flex-wrap: wrap;
		-ms-flex-wrap: wrap;
		flex-wrap: wrap;
	}

	.insta-tile {
		width: 50%;
		max-width: 50%;
	}

	.card {
		-webkit-flex-basis: 31%;
		-ms-flex-preferred-size: 31%;
		flex-basis: 31%;
	}

	.card.full-size-image {
		width: auto;
		float: left;
	}

	.card.bakery {
		float: none;
	}

	.card.meat-counter {
		float: none;
	}

	.card.locator {
		width: auto;
		float: none;
	}

	.card.recipe-2 {
		float: none;
	}

	.card.interviews {
		float: none;
	}

	.heading-link-insta {
		font-size: 2.25rem;
		line-height: 2.75rem;
	}

	.cards-parent {
		display: -webkit-box;
		display: -webkit-flex;
		display: -ms-flexbox;
		display: flex;
	}

	.card-image.interviews {
		background-image: url('../images/partial-tile-test_420x210.jpg'), url('../images/open-interviews-red.jpg');
		background-position: 50% 50%, 50% 50%;
		background-size: cover, cover;
		background-repeat: no-repeat, no-repeat;
	}

	.insta-parent {
		width: 100%;
	}

	.copyright-wrapper {
		display: block;
		margin-right: 0rem;
	}

	.inside-content-wrap {
		margin-right: 2%;
		margin-left: 2%;
	}
}

@media (max-width: 767px) {
	h1 {
		font-size: 2rem;
		line-height: 2.75rem;
	}

	h2 {
		font-size: 1.75rem;
		line-height: 2.45rem;
	}

	h3 {
		font-size: 1.5rem;
		line-height: 2.2rem;
	}

	h4 {
		font-size: 1.35rem;
		line-height: 1.95rem;
	}

	ul {
		margin-top: 0.5rem;
		margin-bottom: 0.75rem;
		padding-left: 1.25rem;
	}

	ol {
		padding-left: 20px;
	}

	blockquote {
		font-size: 1.05rem;
		line-height: 1.75rem;
	}

	.main-content-wrapper {
		padding-top: 0.5rem;
		padding-bottom: 0.75rem;
	}

	.container {
		max-width: 767px;
	}

	.container.inside-page-content-container {
		max-width: 767px;
	}

	.container.footer-container {
		max-width: 767px;
		-webkit-flex-wrap: wrap;
		-ms-flex-wrap: wrap;
		flex-wrap: wrap;
	}

	.container.signoff-container {
		-webkit-box-orient: vertical;
		-webkit-box-direction: normal;
		-webkit-flex-direction: column;
		-ms-flex-direction: column;
		flex-direction: column;
		text-align: center;
	}

	.container.cards-container {
		max-width: 767px;
		margin-top: 2.5rem;
	}

	.container.insta-container {
		max-width: 767px;
	}

	.header-section {
		position: relative;
	}

	.brand {
		display: block;
		width: 275px;
		margin-top: 0px;
		margin-right: auto;
		margin-left: auto;
	}

	.brand.w--current {
		margin-top: 0rem;
		margin-left: auto;
	}

	.rotator-section {
		top: 99px;
	}

	.rotator.no-captions {
		min-height: 0;
	}

	.slideshow-arrow.left-arrow {
		left: 0px;
		display: none;
		height: 100%;
	}

	.slideshow-arrow.right-arrow {
		right: 0px;
		display: none;
		height: 100%;
	}

	.slidenav {
		bottom: 0px;
		border-bottom: 1px solid hsla(0, 1%, 47%, 0.5);
	}

	.slide-caption-outer-wrap {
		position: static;
		bottom: 0px;
		overflow: visible;
		width: 100%;
		margin-top: 0px;
		padding-top: 0rem;
		padding-right: 0rem;
		padding-left: 0rem;
	}

	.slide-caption-inner-wrap h1,
	.slide-caption-inner-wrap h2,
	.slide-caption-inner-wrap h3,
	.slide-caption-header {
		font-size: 1.75rem;
		line-height: 2.25rem;
	}

	.slide-caption-inner-wrap p,
	.slide-caption-paragraph {
		font-size: 1.25rem;
		line-height: 1.75rem;
	}

	.cb-static {
		width: 100%;
	}

	.footer-social-container {
		flex-direction: column;
	}

	.cb-static.footer-cb-3 {
		display: -webkit-box;
		display: -webkit-flex;
		display: -ms-flexbox;
		display: flex;
		width: 100%;
		padding: 2rem 1rem 1rem;
		-webkit-box-pack: center;
		-webkit-justify-content: center;
		-ms-flex-pack: center;
		justify-content: center;
		-webkit-box-align: center;
		-webkit-align-items: center;
		-ms-flex-align: center;
		align-items: center;
	}

	.best-workplace {
		text-align: center;
	}

	.best-workplace img {
		width: 120px;
		min-width: 120px;
	}

	.signoff-wrapper {
		padding-top: 0.5rem;
		padding-bottom: 0.75rem;
	}

	.footer-grip-link {
		margin-top: 2rem;
	}

	.copyright-paragraph {
		display: inline-block;
	}

	.menu-button {
		display: -webkit-box;
		display: -webkit-flex;
		display: -ms-flexbox;
		display: flex;
		width: 40px;
		height: 40px;
		margin-top: 0px;
		margin-right: 0px;
		clear: none;
		-webkit-box-align: center;
		-webkit-align-items: center;
		-ms-flex-align: center;
		align-items: center;
	}

	.menu-icon {
		position: relative;
		bottom: 3px;
	}

	.menu-text {
		display: none;
	}

	.copyright-privacy-sitemap-wrapper {
		display: block;
		width: 100%;
		-webkit-flex-wrap: wrap;
		-ms-flex-wrap: wrap;
		flex-wrap: wrap;
	}

	.search-dd-button.mobile-search-dd-button {
		width: 40px;
		height: 40px;
	}

	.header-search-form.search-top-form {
		margin-right: 1rem;
	}

	.header-search-form-wrapper.search-top-wrapper {
		height: auto;
	}

	.intro-text,
	.intro_paragraph,
	.intro-paragraph {
		font-size: 1.25rem;
		line-height: 1.95rem;
	}

	.header-flex-container {
		margin-bottom: 0rem;
		padding-top: 1rem;
		padding-right: 0rem;
		padding-left: 0rem;
	}

	.slideshow-mask {
		background-color: #fff;
		font-family: inherit;
	}

	.intro-header {
		font-size: 2.25rem;
		line-height: 2.5rem;
	}

	.slide-caption-inner-wrap {
		padding-bottom: 0.65rem;
	}

	.slide-image-wrap {
		width: 100%;
		height: 300px;
	}

	.ecomm-functions {
		left: 0px;
		top: 0%;
		right: 0px;
		bottom: auto;
		height: 40px;
		-webkit-flex-wrap: nowrap;
		-ms-flex-wrap: nowrap;
		flex-wrap: nowrap;
		-webkit-transform: none;
		-ms-transform: none;
		transform: none;
	}

	.cart-link {
		margin-left: 10px;
	}

	.cart-icon {
		opacity: 1;
	}

	.cart-links {
		-webkit-box-ordinal-group: 0;
		-webkit-order: -1;
		-ms-flex-order: -1;
		order: -1;
	}

	.ecomm-link.login-link {
		margin-bottom: 10px;
	}

	.mobile-menu-search-wrap {
		display: -webkit-box;
		display: -webkit-flex;
		display: -ms-flexbox;
		display: flex;
		margin-top: 0px;
		float: none;
		-webkit-box-pack: end;
		-webkit-justify-content: flex-end;
		-ms-flex-pack: end;
		justify-content: flex-end;
	}

	.inside-page-banner-section {
		display: block;
		height: 200px;
	}

	.inside-content-wrapper {
		margin-right: 1%;
		margin-left: 1%;
	}

	.inside-content-wrapper.page-title-container {
		max-width: 767px;
	}

	.h1-page-title {
		font-size: 2.25rem;
		line-height: 3rem;
	}

	.page-title-inner-wrap {
		margin-right: 1%;
		margin-left: 1%;
		padding-right: 1.5rem;
		padding-left: 1.5rem;
	}

	.styles-page-container {
		max-width: 767px;
	}

	.utility-nav {
		display: block;
		margin-top: 1rem;
		padding-top: 0px;
		padding-right: 1rem;
		padding-left: 1rem;
		-webkit-box-orient: horizontal;
		-webkit-box-direction: normal;
		-webkit-flex-direction: row;
		-ms-flex-direction: row;
		flex-direction: row;
		border-top: 1px solid hsla(0, 1%, 47%, 0.5);
	}

	.top-nav-divider {
		display: none;
		margin-top: 0px;
	}

	.header-top {
		-webkit-box-orient: vertical;
		-webkit-box-direction: normal;
		-webkit-flex-direction: column;
		-ms-flex-direction: column;
		flex-direction: column;
	}

	.home-content-section .cards-parent .card-container {
		width: 48%;
	}

	.card {
		-webkit-flex-basis: 48%;
		-ms-flex-preferred-size: 48%;
		flex-basis: 48%;
	}

	.card.full-size-image {
		width: 95%;
		float: none;
	}

	.card.meat-counter {
		display: block;
		width: 95%;
	}

	.card.bakery {
		display: block;
		width: 95%;
	}

	.card.locator {
		display: block;
		width: 95%;
		float: none;
	}

	.card.recipe-2 {
		width: 95%;
	}

	.card.interviews {
		display: block;
		width: 95%;
	}

	.heading-link-insta {
		font-size: 2rem;
		line-height: 2.5rem;
	}

	.card-image.interviews {
		background-image: url('../images/partial-tile-test_420x210.jpg'), url('../images/open-interviews-red.jpg');
		background-position: 50% 50%, 50% 50%;
		background-size: cover, cover;
		background-repeat: no-repeat, no-repeat;
	}

	.insta-parent {
		width: 100%;
	}

	.copyright-wrapper {
		margin-top: 0.5rem;
		margin-bottom: 1rem;
	}

	.inside-content-wrap {
		margin-right: 1%;
		margin-left: 1%;
	}
}

@media (max-width: 479px) {
	h1 {
		font-size: 1.75rem;
		line-height: 2.4rem;
	}

	h2 {
		font-size: 1.55rem;
		line-height: 2.15rem;
	}

	h3 {
		font-size: 1.4rem;
		line-height: 2.05rem;
	}

	h4 {
		font-size: 1.3rem;
		line-height: 1.9rem;
	}

	h5 {
		font-size: 1.2rem;
		line-height: 1.8rem;
	}

	blockquote {
		line-height: 1.75rem;
	}

	.button.search-button {
		width: 30%;
		min-width: 0px;
		padding-right: 0.5rem;
		padding-left: 0.5rem;
		font-size: 0.9rem;
	}

	.button.search-button.search-top-button {
		width: 30%;
		padding-right: 0.25rem;
		padding-left: 0.25rem;
		float: none;
	}

	.main-content-wrapper {
		padding-bottom: 0.75rem;
	}

	.container {
		max-width: 479px;
	}

	.container.inside-page-content-container {
		max-width: none;
		padding-right: 0rem;
		padding-left: 0rem;
	}

	.container.footer-container {
		max-width: 479px;
		padding-bottom: 1rem;
	}

	.container.cards-container {
		max-width: 479px;
		padding-top: 1rem;
	}

	.container.insta-container {
		max-width: 479px;
	}

	.brand {
		width: 225px;
	}

	.brand.w--current {
		width: 230px;
	}

	.nav-menu {
		padding-right: 0.5rem;
		padding-left: 0.5rem;
	}

	.nav-menu.footer-menu {
		padding: 0.5rem 1rem;
	}

	.main-nav-link {
		padding-left: 0.5rem;
	}

	.main-nav-link.mobile-duplicate.w--current {
		width: 100%;
	}

	.main-nav-link.footer-main {
		margin-right: 1vw;
		margin-left: 1vw;
		font-size: 0.9375rem;
	}

	.slideshow-arrow.left-arrow {
		display: none;
	}

	.slideshow-arrow.right-arrow {
		display: none;
	}

	.slide-caption-outer-wrap {
		position: static;
		overflow: visible;
		width: 100%;
		height: auto;
		margin-top: 0px;
	}

	.slide {
		-webkit-box-orient: vertical;
		-webkit-box-direction: normal;
		-webkit-flex-direction: column;
		-ms-flex-direction: column;
		flex-direction: column;
	}

	.home-content-section {
		padding-right: 5%;
		padding-left: 5%;
	}

	.home-content-section .cards-parent .card-container {
		width: 98%;
	}

	.home-content-section .cards-parent .card-container.show-on-desktop {
		display: none;
	}

	.home-content-section .cards-parent .card-container.show-on-mobile {
		display: flex;
	}

	.cb-static {
		width: 100%;
	}

	.cb-static.footer-cb-3 {
		padding-right: 1rem;
		padding-bottom: 0rem;
		padding-left: 1rem;
	}

	.signoff-wrapper {
		padding-top: 1rem;
	}

	.copyright-paragraph {
		line-height: 1rem;
	}

	.menu-button {
		-webkit-box-flex: 0;
		-webkit-flex: 0 auto;
		-ms-flex: 0 auto;
		flex: 0 auto;
		line-height: 1.6rem;
	}

	.menu-text {
		display: none;
	}

	.mobile-menu-arrow {
		width: 17%;
	}

	.social-media-link-block.footer-socmed-link-block {
		margin-bottom: 1rem;
	}

	.copyright-privacy-sitemap-wrapper {
		-webkit-box-pack: center;
		-webkit-justify-content: center;
		-ms-flex-pack: center;
		justify-content: center;
	}

	.header-search-field {
		width: 70%;
		float: none;
	}

	.search-dd-button.mobile-search-dd-button {
		margin-right: 5px;
	}

	.header-search-form.search-top-form {
		display: -webkit-box;
		display: -webkit-flex;
		display: -ms-flexbox;
		display: flex;
		height: auto;
		margin-right: 0rem;
		-webkit-box-pack: justify;
		-webkit-justify-content: space-between;
		-ms-flex-pack: justify;
		justify-content: space-between;
		-webkit-box-flex: 1;
		-webkit-flex: 1;
		-ms-flex: 1;
		flex: 1;
	}

	.header-search-form-wrapper {
		min-width: 100%;
	}

	.header-search-form-wrapper.search-top-wrapper {
		height: auto;
		padding: 0.5rem 0.5rem 0.5rem 1rem;
	}

	.intro-text,
	.intro_paragraph,
	.intro-paragraph {
		font-size: 1.25rem;
		line-height: 1.9rem;
	}

	.header-flex-container {
		padding-top: 0.75rem;
	}

	.slide-image-wrap {
		display: block;
		width: 100%;
		height: 200px;
	}

	.cart-count {
		height: 20px;
		min-width: 20px;
		line-height: 1rem;
	}

	.ecomm-functions {
		width: auto;
		height: 40px;
		margin-right: 0px;
		-webkit-flex-wrap: nowrap;
		-ms-flex-wrap: nowrap;
		flex-wrap: nowrap;
		-webkit-box-align: end;
		-webkit-align-items: flex-end;
		-ms-flex-align: end;
		align-items: flex-end;
		-webkit-box-flex: 1;
		-webkit-flex: 1;
		-ms-flex: 1;
		flex: 1;
	}

	.cart-link {
		margin-bottom: 10px;
		padding-right: 0px;
		padding-left: 0px;
	}

	.cart-icon {
		width: 20px;
		height: 20px;
	}

	.ecomm-link {
		padding-right: 2px;
		padding-left: 2px;
		font-size: 0.8rem;
	}

	.mobile-menu-search-wrap {
		display: -webkit-box;
		display: -webkit-flex;
		display: -ms-flexbox;
		display: flex;
		height: 40px;
		-webkit-box-pack: end;
		-webkit-justify-content: flex-end;
		-ms-flex-pack: end;
		justify-content: flex-end;
		-webkit-box-align: center;
		-webkit-align-items: center;
		-ms-flex-align: center;
		align-items: center;
		-webkit-box-flex: 1;
		-webkit-flex: 1;
		-ms-flex: 1;
		flex: 1;
	}

	.search-container-top {
		overflow: hidden;
	}

	.search-close-wrapper {
		display: -webkit-box;
		display: -webkit-flex;
		display: -ms-flexbox;
		display: flex;
		height: 60px;
		margin-left: 1rem;
		-webkit-box-flex: 0;
		-webkit-flex: 0 0 auto;
		-ms-flex: 0 0 auto;
		flex: 0 0 auto;
	}

	.inside-content-wrapper {
		margin-right: 0%;
		margin-left: 0%;
	}

	.inside-content-wrapper.page-title-container {
		padding-right: 0%;
		padding-left: 0%;
	}

	.h1-page-title {
		font-size: 1.95rem;
		line-height: 2rem;
		letter-spacing: 0rem;
		text-transform: none;
		margin-top: 0.9rem;
		margin-bottom: 0.9rem;
	}

	.page-title-inner-wrap {
		margin-right: 0%;
		margin-left: 0%;
		padding-right: 1.25rem;
		padding-left: 1.25rem;
	}

	.outline-wrap {
		padding-right: 1rem;
		padding-left: 1rem;
	}

	.styles-page-container {
		max-width: 479px;
	}

	.utility-nav {
		margin-top: 0.75rem;
		padding-right: 0.5rem;
		padding-left: 0.5rem;
	}

	.top-nav-divider {
		font-size: 0.85rem;
	}

	.slash {
		margin-right: 2px;
		margin-left: 2px;
	}

	.instagram-img {
		width: 100%;
	}

	.insta-tile {
		width: 100%;
		max-width: 100%;
	}

	.card {
		-webkit-flex-basis: 98%;
		-ms-flex-preferred-size: 98%;
		flex-basis: 98%;
	}

	.card.meat-counter {
		background-image: url('../images/MS_Home_Tile_C8021FA8CFBFE_1.jpg'), -webkit-gradient(linear, left top, left bottom, from(null), to(null));
		background-image: url('../images/MS_Home_Tile_C8021FA8CFBFE_1.jpg'), linear-gradient(180deg, null, null);
		background-size: contain, auto;
	}

	.card.recipe-2 {
		background-image: url('../images/MS_Home_Tile_C8021FA8CFBFE_1.jpg'), -webkit-gradient(linear, left top, left bottom, from(null), to(null));
		background-image: url('../images/MS_Home_Tile_C8021FA8CFBFE_1.jpg'), linear-gradient(180deg, null, null);
		background-size: contain, auto;
	}

	.card.interviews {
		background-image: url('../images/MS_Home_Tile_C8021FA8CFBFE_1.jpg'), -webkit-gradient(linear, left top, left bottom, from(null), to(null));
		background-image: url('../images/MS_Home_Tile_C8021FA8CFBFE_1.jpg'), linear-gradient(180deg, null, null);
		background-size: contain, auto;
	}

	.card-image.interviews {
		background-image: url('../images/partial-tile-test_420x210.jpg'), url('../images/open-interviews-red.jpg');
		background-position: 50% 50%, 50% 50%;
		background-size: cover, cover;
		background-repeat: no-repeat, no-repeat;
	}

	.insta-parent {
		width: 100%;
	}

	.inside-content-wrap {
		margin-right: 0%;
		margin-left: 0%;
	}
}

body {
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	text-rendering: optimizeLegibility;
}

a[href^=tel] {
	color: #2e2e2e;
	background-color: transparent;
	cursor: pointer;
}

::-moz-selection {
	background-color: #797676;
	color: #ffffff;
	opacity: 1;
}

::selection {
	background-color: #797676;
	color: #ffffff;
	opacity: 1;
}

.hovered {
	box-shadow: inset 0 2px 0 0 #ec1c24, inset 0 -2px 0 0 #ec1c24;
	-webkit-transform: none;
	-ms-transform: none;
	transform: none;
	color: #d8263c;
}

.main-content-wrapper > *:first-child {
	margin-top: 0px;
}

@media screen and (min-width: 992px) {
	.sticky.header-section {
		background-color: white;
		font-family: inherit;
		top: 0;
	}

	.sticky .header-flex-container {
		margin-bottom: 0.5rem;
	}

	.sticky .nav-menu {
		margin-top: 5px;
	}

	.sticky .brand {
		width: 225px;
		margin-top: 10px;
	}
}

@media screen and (min-width: 992px) and (max-height: 828px) {
	.inside-page-banner-section {
		height: 400px;
	}
}

/*mmenu*/
.mobile-navigation-menu {
	display: none !important;
}

@media (min-width: 992px) {
	.mm-wrapper_opening .mm-menu_offcanvas.mm-menu_opened ~ .mm-slideout {
		-webkit-transform: translate(0, 0);
		-ms-transform: translate(0, 0);
		transform: translate(0, 0);
		-webkit-transform: translate3d(0, 0, 0);
		transform: translate3d(0, 0, 0);
	}
}

@media (max-width: 991px) {
	.mobile-navigation-menu.mm-menu_opened {
		display: block !important;
		background-color: #fff;
	}

	.mm-wrapper_opening .mm-menu_position-right.mm-menu_opened ~ .mm-slideout {
		-webkit-transform: translate(-80%, 0);
		-ms-transform: translate(-80%, 0);
		transform: translate(-80%, 0);
		-webkit-transform: translate3d(-80%, 0, 0);
		transform: translate3d(-80%, 0, 0);
	}

	.mm-menu_offcanvas {
		max-width: none;
	}

	.mm-panel {
		padding: 0 20px;
	}

	.mm-panels > .mm-panel > .mm-listview {
		margin: -20px 0 20px;
	}

	.mm-panels > .mm-panel + .mm-panel > .mm-listview {
		margin-top: 27px;
	}

	.mm-menu li {
		display: block !important;
	}

	.mm-menu a,
	.mm-menu a:active,
	.mm-menu a:link,
	.mm-menu a:visited {
		color: #000;
		background-color: #fff;
		padding: 0.75rem;
		text-decoration: none;
		border-bottom: 1px solid hsla(0, 1%, 47%, 0.5);
		text-transform: uppercase;
		float: none;
		box-shadow: none;
		font-weight: 600;
		line-height: 1.4rem;
		font-size: 0.98rem;
		letter-spacing: 0.02rem;
	}

	.mm-menu .mm-listitem_selected > a:not(.mm-btn_next),
	.mm-menu .mm-listitem_selected > span {
		box-shadow: inset 2px 0 0 0 #ec1c24;
		color: #d8263c;
		background-color: #fff;
		cursor: pointer;
	}

	.mm-menu a:hover {
		color: #d8263c;
		background-color: #fff;
		cursor: pointer;
	}

	.mm-listitem:after {
		border-bottom-width: 0px;
	}

	.mm-listitem .mm-btn_next:not(.mm-btn_fullwidth) {
		width: 56px;
		background-color: transparent;
		border-bottom-color: hsla(0, 1%, 47%, 0.5);
	}

	.mm-listitem .mm-btn_next:before {
		border-left-width: 1px;
		border-left-color: hsla(0, 1%, 47%, 0.5);
	}

	.mm-menu .mm-btn:after,
	.mm-menu .mm-btn:before,
	.mm-menu .mm-listview .mm-btn_next:after {
		border-color: #2e2e2e;
	}

	.mm-btn_next:after,
	.mm-btn_prev:before {
		width: 11px;
		height: 11px;
	}

	.mm-btn_next:after {
		right: 40%;
	}

	.mm-btn_prev:before {
		margin-left: 0;
		left: 17px;
	}

	.mm-btn_prev:hover:before,
	.mm-menu .mm-listview .mm-btn_next:hover:after {
		border-color: #d8263c;
	}

	.mm-menu .mm-listview .mm-btn_next + a {
		width: calc(100% - 56px);
		margin-right: 56px;
	}

	.mm-navbar {
		border-bottom: 1px solid hsla(0, 1%, 47%, 0.5);
		padding: 0 10px 0 40px;
		height: 47px;
		margin: 0px 20px;
	}

	.mm-menu .mm-navbar a,
	.mm-menu .mm-navbar > * {
		height: 47px;
		line-height: 1.6rem;
		border-bottom: 0 none !important;
	}

	.mm-navbar__title {
		font-size: 0.98rem;
		line-height: 1.4rem;
		font-weight: 600;
		text-align: left;
		padding-left: 0 !important;
		text-transform: capitalize;
		border-bottom: 0 none;
	}

	.mm-navbar:hover .mm-btn_prev:before {
		border-color: #d8263c;
	}

	.mm-navbar:hover .mm-navbar__title {
		color: #d8263c;
		cursor: pointer;
	}
}

/* breakpoints */
[data-breakpoints] {
	display: none;
}

[data-breakpoints="xs,sm,md,lg,xl"] {
	display: block;
}

@media screen and ( max-width: 991px ) {
	[data-breakpoints*="xl"] {
		display: block;
	}
}

@media screen and ( max-width: 767px ) {
	[data-breakpoints*="md,lg"] {
		display: block;
	}
}

@media screen and ( max-width: 479px ) {
	[data-breakpoints*="xs,sm"] {
		display: block;
	}
}

/*slideshow transitions*/
.slide-caption-outer-wrap {
	transform: scaleX(0.66) scaleY(0.66) scaleZ(1);
}

.cycle-slide-active .slide-caption-outer-wrap {
	opacity: 1;
	transform: scaleX(1) scaleY(1) scaleZ(1);
	transition: opacity 300ms ease 0s, transform 600ms cubic-bezier(0.175, 0.885, 0.32, 1.275) 0s;
}

/*inside pages*/
/*left column*/
.left-sidebar-custom .box + .box {
	margin-top: 1rem;
	padding-top: 1.25rem;
	border-top: 1px solid hsla(0, 1%, 47%, 0.5);
}

/*right column*/
.box.documents .content > table {
	margin-bottom: 0.75rem;
}

.box.documents .content td:first-of-type {
	padding-right: 10px;
	width: 35px;
}

.box .contentbox_item {
	margin-bottom: 0.75rem;
}

/*blockquotes*/
blockquote p {
	color: inherit;
	font-size: inherit;
	line-height: inherit;
	font-family: inherit;
	font-weight: inherit;
}

blockquote p:last-of-type {
	margin-bottom: 0;
}

/*horizontal ruler*/
.divider,
.cms_divider,
.cms_item_divider,
.item_divider {
	height: 1px !important;
	margin-top: 1.5em !important;
	margin-bottom: 1.5em !important;
	background-color: hsla(0, 1%, 47%, 0.5) !important;
	border-top: 0 none !important;
	border-bottom: 0 none !important;
}

hr:not(.fc-divider):not(.formBreak) {
	border-color: hsla(0, 1%, 47%, 0.5);
	border: 0px;
	color: hsla(0, 1%, 47%, 0.5);
	margin-bottom: 1.5em;
	margin-top: 1.5em;
	border-top: 1px solid;
}

/*messages*/
#session-message-wrapper .success {
	background-color: #b5eebe;
	text-align: left;
	margin-top: 20px;
	margin-bottom: 20px;
	padding: 20px;
	color: #2e2e2e;
	font-size: 1.125rem;
	line-height: 1.6rem;
	font-weight: 500;
}

#session-message-wrapper .error {
	background-color: #f5d1d1;
	text-align: left;
	margin-top: 20px;
	margin-bottom: 20px;
	padding: 20px;
	color: #2e2e2e;
	font-size: 1.125rem;
	line-height: 1.6rem;
	font-weight: 500;
}

#session-message-wrapper .success p,
#session-message-wrapper .success ul li,
#session-message-wrapper .error p,
#session-message-wrapper .error ul li {
	margin-top: 0;
	color: #2e2e2e;
	font-size: 1.125rem;
	line-height: 1.6rem;
	font-weight: 500;
}

#session-message-wrapper .error a {
	background-color: unset;
	color: #981626;
}

.success *:last-child,
.error *:last-child {
	margin-bottom: 0;
}

/*images*/
img {
	height: auto;
}

td img {
	max-width: none;
}

@media (max-width: 767px) {
	.mobile-friendly-image {
		display: block;
		float: none !important;
		margin-left: 0 !important;
		margin-right: 0 !important;
	}
}

/*tables*/
table.styled {
	width: 100%;
	margin: 20px 0 10px 0;
	background-color: white;
	border-spacing: 0px;
}

table.styled th,
table.styled td {
	border-right: 1px solid white;
}

table.styled tr th:last-child,
table.styled tr td:last-child {
	border-right: none;
}

table.styled thead tr th {
	font-size: 1rem;
	padding: 10px 10px;
	text-align: left;
	background-color: black;
	color: white;
}

table.styled th {
	font-weight: normal;
	font-size: 1rem;
	padding: 10px 10px;
	text-align: left;
	background-color: darkgray;
	color: black;
}

table.styled th a {
	color: #000;
	background-color: darkgray;
	text-decoration: underline;
	box-shadow: none;
}

table.styled thead th a {
	color: #fff;
	background-color: black;
	cursor: pointer;
}

table.styled th a:hover {
	text-decoration: none;
	border-bottom: 0 solid transparent;
	color: #000;
	background-color: darkgray;
	box-shadow: none;
}

table.styled thead th a:hover {
	color: #fff;
	cursor: pointer;
	background-color: black;
}

table.styled td {
	padding: 8px 10px;
}

table.styled tr:nth-child(even) {
	background: lightgray;
}

table.styled tr:nth-child(odd) {
	background: transparent;
}

@media (max-width: 39.9375em) {
	table.styled.tablesaw {
		border: 1px solid grey;
	}

	table.styled.tablesaw th,
	table.styled.tablesaw td {
		border-right: 0 none;
		border-bottom: 0 none;
	}

	.tablesaw-stack tbody {
		border-top: 1px solid grey;
	}

	.tablesaw-stack tbody tr {
		border-bottom: 0 none;
	}
}

/*forms*/
.cms_label,
.label,
label {
	font-weight: 400;
}

label {
	display: inline;
	margin-bottom: 0;
}

input[type="text"],
input[type="tel"],
input[type="password"],
input[type="email"],
input[type="search"],
textarea,
select {
	font-size: 0.875rem;
	line-height: 1.42857143;
	padding: 8px 12px;
	border: 1px solid #666666;
	color: #333333;
	max-width: 100%;
}

#submissionforms_module .select2-container .select2-selection--single {
	border: 1px solid #666 !important;
}

textarea {
	resize: vertical;
}

/*skip to main content*/
.skip-to-main {
	position: absolute;
	height: 0;
	padding: 0 !important;
	text-indent: -999999px;
	width: 0;
	border: 0 !important;
	margin: 0;
	min-width: 0;
}

.skip-to-main:focus {
	text-indent: 0;
	height: auto;
	width: auto;
	z-index: 9999999;
	top: 0;
	padding: 0.5625rem 1rem !important;
	border: 1px solid #d8263c !important;
	margin-top: 10px;
	margin-left: 10px;
}

/*social share*/
.social-share.container {
	clear: both;
	text-align: center;
}

/*module styling*/
/****************** General ************************/
/***************************************************/
/*horizontal ruler*/
.divider,
.cms_divider,
.cms_item_divider,
.item_divider,
.separator,
#services_module.list .list_item .footer,
#submissionforms_module.cms_form .formBreak,
.module-forms .formBreak {
	height: 1px !important;
	margin-top: 1.5rem !important;
	margin-bottom: 1.5rem !important;
	background-color: hsla(0, 1%, 47%, 0.5) !important;
	border-top: 0 none !important;
	border-bottom: 0 none !important;
}

.collection > .node {
	border-top: 1px solid hsla(0, 1%, 47%, 0.5);
	margin: 1.5rem 0 0 0;
	padding: 1.5rem 0 0 0;
}

.section_separator {
	border-bottom: 1px solid hsla(0, 1%, 47%, 0.5);
}

/* dates */
#events_module.cms_list .cms_date,
#photoalbums_module.cms_list .cms_list_item .cms_number_of_images {
	font-size: 1rem;
	line-height: 1.7rem;
	font-weight: 600;
	color: #2e2e2e;
}

/* ui form tags */
.ui-form-error {
	display: none;
}

.ui-form-container {
	border: 0px;
}

.ui-form-container .ui-form-legend {
	background: none;
	margin-top: 1rem !important;
	margin-bottom: 0.5rem !important;
	color: #363636 !important;
	font-size: 1.8rem !important;
	line-height: 2.3rem;
	font-weight: 600 !important;
	text-transform: none;
	padding: 0px;
	border-bottom: 0px;
}

.ui-form-container .ui-form-fields {
	border-bottom: 0px;
}

.ui-form-container .ui-form-fields .ui-form-field.required .ui-form-label em,
.ui-form-container .required_information em {
	color: #eb0000;
	font-style: italic;
}

.ui-form-container .ui-form-fields .select2-container-multi .select2-choices {
	border: 1px solid #ccc;
	border-radius: 0px;
	padding: 8px 12px;
	font-size: 0.875em;
	line-height: 1.42857143;
	color: #333333;
	box-shadow: none;
}

.ui-form-container .ui-form-fields .select2-container-multi .select2-choices .select2-search-field .select2-input {
	padding: 0px;
}

.ui-form-container .ui-form-fields .select2-container .select2-choice {
	box-shadow: none;
	border-radius: 0px;
	background: none;
	border: 1px solid #ccc;
}

.ui-form-buttons {
	padding: 0px;
	width: 100%;
	text-align: left;
}

.ui-form-note {
	line-height: 1rem;
	margin-top: 5px;
}

@media (max-width: 991px) {
	.ui-form-fields {
		padding: 0px;
	}

	.ui-form-label {
		position: static;
		padding: 0px;
		height: auto;
		display: block;
		width: 100%;
		left: 0px;
		text-align: left;
		top: auto;
		line-height: inherit;
	}

	.ui-form-input {
		width: 100%;
	}

	.ui-form-input input[type="text"],
	.ui-form-input input[type="tel"],
	.ui-form-input input[type="password"],
	.ui-form-input input[type="email"],
	.ui-form-input input[type="file"],
	.ui-form-input select,
	.ui-form-input textarea,
	.ui-form-fields .select2-container,
	.ui-form-fields .select2-container-multi,
	.ui-form-fields .select2-container-multi .select2-choices .select2-search-field .select2-input {
		width: 100% !important;
		max-width: 100%;
	}

	.ui-form-panel {
		padding: 0 !important;
	}

	.ui-form-buttons {
		text-align: left;
	}
}

@media (max-width: 479px) {
	.ui-form-buttons button {
		margin-bottom: 10px;
		width: auto;
	}
}

.pagination-wrapper > * {
	line-height: 1.6rem;
}

.slick-dots {
	margin: 0;
	padding: 0;
}

.slick-dots li {
	background-color: rgba(34, 34, 34, 0.4);
	border-radius: 100%;
	display: inline-block;
	height: 0.6875em;
	margin: 0 6px 0.5em;
	transition: background-color 0.1s color 0.1s;
	width: 0.6875em;
}

.slick-dots li.slick-active {
	background-color: #222222;
}

.slick-dots li button {
	background: transparent;
	text-indent: -999999px;
}

.slick-slide img {
	height: auto;
	width: 100%;
	box-shadow: inset 0 0 30px 5px rgba(0, 0, 0, 0.2);
}

.slick-slide picture {
	height: auto;
}

.slide-link {
	width: calc(100% - 2.5rem);
	height: calc(100% - 2.5rem);
	margin: 1.25rem;
	box-shadow: inset 0 0 30px 5px rgba(0, 0, 0, 0.2);
}

@media (max-width: 991px) {
	.slide-link {
		width: 100%;
		height: 100%;
		margin: 0;
	}
}

@media (max-width: 767px) and (min-width: 479px) {
	.slide-caption-outer-wrap {
		position: absolute;
		padding: 1rem;
		width: 100%;
	}

	.slide-caption-inner-wrap {
		padding-bottom: 1rem;
	}

	.slidenav {
		bottom: -13px;
	}
}


/* staff */
#staffdirectory_module.cms_entity .image {
	width: auto;
}

/* Partners module */
#partners_module.cms_list .cms_list_item .image {
	width: 320px;
	vertical-align: middle;
}

#partners_module.cms_entity .image {
	width: 300px;
	margin: 0px 20px 10px 0px;
}


@media screen and (max-width: 767px) {
	#partners_module.cms_list .cms_list_item .image {
		width: 100%;
	}
}

/* stores */
.module-stores.controller-pages-stores-stores .module-filter-wrap .module-subnav {
	margin-bottom: 20px !important;
	margin-top: 10px !important;
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
}

.module-stores.controller-pages-stores-stores .module-filter-wrap .module-subnav li {
	margin-left: 20px;
	margin-right: 20px;
	padding: 0px;
	min-width: 150px;
	margin-bottom: 10px;
}

.module-stores.controller-pages-stores-stores .module-filter-wrap .module-subnav li a.nav-link {
	padding: 1.2rem 0.7rem;
	color: #fff;
	font-size: 1.05rem;
	font-weight: 400;
	font-family: inherit;
	border-radius: 5px;
	background-color: #333;
	display: inline-block;
	box-shadow: 3px 3px 8px 2px rgb(77 77 77 / 20%);
	border-bottom: 0;
	text-align: center;
	transition: transform 0.2s;
	width: 100%;
}

.module-stores.controller-pages-stores-stores .module-filter-wrap .module-subnav li a.nav-link:hover {
	background-color: #000;
}

.module-stores.controller-pages-stores-stores .module-filter-wrap .module-subnav li a.nav-link.current {
	background-color: #d8263c;
}

.module-stores.controller-pages-stores-stores .module-filter-wrap .module-subnav li a.nav-link.current:hover {
	background-color: #ad1e30;
}

/* Worksite Wellness Homepage */
.wellness-homepage .header-image {
	flex-direction: column;
	justify-content: center;
	align-items: center;
	display: flex;
}

.wellness-homepage .categories-section {
	border-bottom: 0 solid #ccc;
	margin-bottom: 0;
}

.wellness-homepage .offers-div {
	justify-content: flex-start;
	align-items: center;
	margin-top: 0;
	margin-bottom: 40px;
	display: flex;
}

.wellness-homepage .image-div {
	width: auto;
}

.wellness-homepage .promo-cat {
	width: auto;
	padding-right: 20px;
}

.wellness-homepage .div-block-2 {
	width: 75%;
	flex-direction: column;
	display: flex;
}

.wellness-homepage .heading-3,
.wellness-homepage .heading-4 {
	margin-bottom: 0;
}

.wellness-homepage .paragraph-2 {
	margin-top: 0;
	margin-bottom: 0;
	font-family: Montserrat, sans-serif;
	font-size: 1rem;
	font-weight: 500;
}

.wellness-homepage .paragraph-2 p {
	font-family: Montserrat, sans-serif;
	font-size: 1rem;
	font-weight: 500;
	margin-top: 0;
	line-height: 1.7rem;
	margin-bottom: 0.5rem;
}

.wellness-homepage .paragraph-3 p:last-of-type,
.wellness-homepage .paragraph-2 p:last-of-type {
	margin-bottom: 0px;
}

.wellness-homepage h5 {
	margin-top: 0;
	margin-bottom: 0;
	font-family: Montserrat, sans-serif;
	font-size: 1rem;
	font-weight: 500;
	line-height: 1.8rem;
}

.wellness-homepage ul {
	color: #424242;
	margin-bottom: 1rem;
	padding-top: 0;
	list-style-type: none;
	padding-left: 0;
	font-size: 1.05rem;
	line-height: 1.7rem;
	margin-top: 0.5rem;
}

.wellness-homepage ul li {
	margin-bottom: 0;
	border-bottom: 1px solid #ccc;
}

.wellness-homepage ul li a {
	color: #424242;
	background-image: url("/assets/images/arrow-medium.svg");
	background-position: -20px;
	background-repeat: no-repeat;
	background-size: 8px 8px;
	padding: 0.4rem 0.75rem 0.4rem 0.85rem;
	font-size: 1rem;
	line-height: 1.5rem;
	text-decoration: none;
	transition: all 0.2s;
	display: block;
	box-shadow: none;
}

.wellness-homepage ul li a:hover {
	color: #d8263c;
	background-image: url("/assets/images/arrow-medium.svg");
	background-position: 0;
	background-repeat: no-repeat;
	background-size: 8px 8px;
	transform: translate(6px);
	background-color: transparent;
	box-shadow: none;
}

.wellness-homepage .cb-button {
	border-bottom: 1px solid #d8263c;
	max-width: 150px;
}

.wellness-homepage .intro-text {
	margin: 0px  0px 10px 0px;
	padding: 0px;
}

@media screen and (max-width: 479px) {
	.wellness-homepage .paragraph-3,
	.wellness-homepage .paragraph-3 p {
		text-align: left;
		font-size: 14px;
		line-height: 1.3rem;
	}

	.wellness-homepage .categories-section {
		margin-bottom: 10px;
		font-size: 1px;
		line-height: 1.6px;
	}

	.wellness-homepage .offers-div {
		flex-direction: column;
	}

	.wellness-homepage .promo-cat {
		height: auto;
		max-height: none;
		max-width: none;
		padding-right: 0;
	}

	.wellness-homepage .div-block-2 {
		width: 100%;
	}

	.wellness-homepage .paragraph-2,
	.wellness-homepage .paragraph-2 p {
		font-size: 14px;
	}

	.wellness-homepage h5 {
		font-size: 14px;
	}

	.wellness-homepage ul li a {
		font-size: 14px;
	}
}
