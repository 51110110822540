.wcag-text {
	left: -9999px;
	position: absolute;
}

/* ------------------------ */
/* CARDS
/* ------------------------ */

.inside-page-wrapper .cards {
	margin-top: 30px;
	margin-bottom: 15px;
	margin-right: -15px;
	margin-left: -15px;
	display: flex;
	flex-wrap: wrap;
	justify-content: center;
}

.inside-page-wrapper .card {
	display: flex;
	flex-direction: column;
	flex: 0 1 23%;
	min-height: 380px;
	margin: 0 1% 20px 1%;
	background-color: #ffffff;
	text-align: center;
	border-top: 10px solid #ec1c24;
}

.inside-page-wrapper .card.recipe {
	max-width: 23%;
}

.inside-page-wrapper .card.video {
	position: relative;
}

.inside-page-wrapper .card.video .card-image {
	height: 100%;
}

.inside-page-wrapper .card.video {
	background-color: #000;
}

.inside-page-wrapper .card.video .card-content {
	position: absolute;
	width: 100%;
	height: 140px;
	background-image: linear-gradient( transparent, rgba(0, 0, 0, 0.85) 40%);
	bottom: 0;
}

.inside-page-wrapper .card.video .card-content h3 {
	margin-top: 60px;
}

.inside-page-wrapper .card.video .card-content h3 a {
	color: #fff;
	height: 70px;
	display: block;
	display: -webkit-box;
	-webkit-line-clamp: 3;
	-webkit-box-orient: vertical;
	text-overflow: ellipsis;
	overflow: hidden;
}

.inside-page-wrapper .card.featured {
	flex: 1 1 31%;
	min-height: 0;
	height: auto;
	max-width: 700px;
}

.inside-page-wrapper .card.featured .testimonial-navdots {
	margin: 0 auto 10px auto;
}

.inside-page-wrapper .card.career.featured.featured-half:only-child {
	flex: 1 1 100%;
}

.inside-page-wrapper .card.featured .card-title {
	font-size: 1.65em;
	font-weight: 500;
}

.inside-page-wrapper .card.featured .card-subtitle {
	font-size: 1.125em;
	text-transform: uppercase;
}

.inside-page-wrapper .card-image {
	display: block;
	height: 190px;
	width: 100%;
	background-color: #cccccc;
	background-size: cover;
	background-position: 50% 50%;
	background-repeat: no-repeat;
	box-shadow: none !important;
}

.inside-page-wrapper .card-image .placeholder {
	font-size: 0;
	display: none;
	height: 0;
}

.inside-page-wrapper .meat-counter.testimonial .card-image {
	background-image: url('/assets/images/VMC_Testimonial_Photo.jpg');
}

.inside-page-wrapper .card-content {
	flex-grow: 1;
	padding: 10px 20px;
}

.inside-page-wrapper .card-title {
	margin-bottom: 15px;
	font-size: 1.125em;
	line-height: 1.5em;
	color: #333333;
	font-weight: 400;
	text-transform: uppercase;
}

.inside-page-wrapper .card-title a {
	color: #333333;
	cursor: pointer;
}

.inside-page-wrapper .card-title a:hover {
	color: #c9353c;
	text-decoration: none;
	border-bottom: 0 solid transparent;
}

.inside-page-wrapper .card-bottom {
	height: 62px;
	display: flex;
	justify-content: center;
	align-items: center;
	color: #333;
	border-top: 1px solid #cccccc;
	text-transform: uppercase;
}

.inside-page-wrapper .card-bottom.button-only {
	padding: 10px 0;
}

.inside-page-wrapper .card-bottom p {
	margin-bottom: 0;
}

/* ------------------------ */
/* NEWS CARD */

.inside-page-wrapper .card.news {
	border-top: 10px solid #dd8c30;
}

.inside-page-wrapper .date {
	font-size: 0.875em;
}

/* ------------------------ */
/* CENTSABLE CARD */
.inside-page-wrapper .card.centsable {
	border-top: 10px solid #ec1c24;
}

.inside-page-wrapper .card.centsable .card-image,
.inside-page-wrapper .card.centsable .card-image-full + .card-content .card-title a {
	box-shadow: none !important;
	border-bottom: 0 none !important;
}


.inside-page-wrapper .card.centsable .card-image-full + .card-content .card-title a:hover {
	color: #cf4a50;
	cursor: pointer;
}

.inside-page-wrapper .card.centsable .card-bottom {
	justify-content: flex-start;
	padding-left: 10px;
}

.inside-page-wrapper .author-name {
	flex: 0 1 auto;
	font-size: 0.875em;
	line-height: 1.25em;
}

.inside-page-wrapper .author-pic {
	margin-top: -30px;
	margin-right: 10px;
	border-radius: 50%;
	border: 5px solid #ffffff;
	height: 60px;
}

.inside-page-wrapper .ckehealth.card.centsable .card-bottom {
	justify-content: center;
	padding: 0;
}

.inside-page-wrapper .ckehealth.card.centsable .author-pic {
	display: none;
}

/* ------------------------ */
/* RECIPE CARD */

.inside-page-wrapper .card.recipe {
	border-top: 10px solid #ec1c24;
}

.inside-page-wrapper .save,
.inside-page-wrapper .prep-time,
.inside-page-wrapper .delete {
	display: flex;
	flex-wrap: wrap;
	align-items: center;
	justify-content: center;
	flex-grow: 1;
	flex-basis: 50%;
	height: 100%;
	margin: 0;
	color: #333333;
	text-transform: uppercase;
	border-right: 1px solid #cccccc;
	cursor: pointer;
}

.inside-page-wrapper .save span,
.inside-page-wrapper .prep-time span,
.inside-page-wrapper .delete span {
	display: block;
}

.inside-page-wrapper .prep-time {
	max-width: 50%;
}

.embedded-prep-time-width {
	max-width: 100%;
}

.inside-page-wrapper .prep-time span:first-child {
	display: block;
	max-width: 70%;
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
}

.inside-page-wrapper .save-label,
.inside-page-wrapper .prep-label,
.inside-page-wrapper .delete-label {
	width: 100%;
	margin-top: -20px;
	text-transform: uppercase;
	font-size: 0.75em;
}

.inside-page-wrapper .save:hover,
.inside-page-wrapper .save:hover span,
.inside-page-wrapper .prep-time:hover,
.inside-page-wrapper .delete:hover {
	color: #c9353c;
	text-decoration: none;
	border-bottom: 0 solid transparent;
}

.inside-page-wrapper .save:before,
.inside-page-wrapper .prep-time:before,
.inside-page-wrapper .delete:before {
	content: '';
	display: inline-block;
	margin-right: 6px;
	height: 20px;
	width: 20px;
	background-repeat: no-repeat;
	background-size: 20px 40px;
	background-position: 50% 0%;
}

.inside-page-wrapper .save:before {
	background-size: 20px 60px;
	background-image: url('/20160710165515/assets/images/icons/heart-sprite.svg');
}

.inside-page-wrapper .prep-time:before {
	background-image: url('/20160710165515/assets/images/icons/clock-sprite.svg');
}

.inside-page-wrapper .delete:before {
	background-image: url('/20160710165515/assets/images/icons/remove-sprite.svg');
}

.inside-page-wrapper .save:hover:before {
	background-position: 50% 50%;
}

.inside-page-wrapper .delete:hover:before {
	background-position: 50% 100%;
}

.inside-page-wrapper .prep-time {
	border-right: none;
	text-transform: none;
	cursor: default;
}

.inside-page-wrapper .prep-time:hover {
	color: #333333;
	cursor: default;
}

.inside-page-wrapper .save.saved,
.inside-page-wrapper .prep-time.saved {
	color: #c9353c;
	cursor: auto;
}

.inside-page-wrapper .save.saved:before,
.inside-page-wrapper .prep-time.saved:before {
	background-position: 50% 100%;
}

/* ------------------------ */
/* STORE CARD */

.inside-page-wrapper .card.store .card-content {
	position: relative;
}

.inside-page-wrapper .card.store .store-card-facebook-link {
	position: absolute;
	top: 7px;
	right: 2px;
	background-image: url('/20160710165515/assets/images/icons/facebook-icon.svg');
	display: block;
	margin: 0 8px;
	height: 28px;
	width: 28px;
	background-size: 22px 22px;
	background-position: 50% 50%;
	background-repeat: no-repeat;
	background-color: #4d647c;
	border-radius: 50%;
	-webkit-transition: background-color 200ms ease;
	transition: background-color 200ms ease;
}

.inside-page-wrapper .card.store .store-card-facebook-link:hover {
	background-color: #c9353c;
	cursor: pointer;
}

.inside-page-wrapper .card.store .store-card-facebook-link.with-instagram-link {
	right: 38px;
}


.inside-page-wrapper .card.store .store-card-instagram-link {
	position: absolute;
	top: 7px;
	right: 2px;
	background-image: url('/20160710165515/assets/images/icons/ic-instagram.svg');
	display: block;
	margin: 0 8px;
	height: 28px;
	width: 28px;
	background-size: 22px 22px;
	background-position: 50% 50%;
	background-repeat: no-repeat;
	background-color: #4d647c;
	border-radius: 50%;
	-webkit-transition: background-color 200ms ease;
	transition: background-color 200ms ease;
}

.inside-page-wrapper .card.store .store-card-instagram-link:hover {
	background-color: #c9353c;
	cursor: pointer;
}

.inside-page-wrapper .card.store .card-title {
	font-weight: 500;
	margin-bottom: 0;
}

.inside-page-wrapper .store-card-info {
	display: flex;
	flex-wrap: wrap;
	font-size: 0.875em;
	text-transform: uppercase;
}

.inside-page-wrapper .store-card-info p {
	margin-bottom: 0;
	display: flex;
	flex-wrap: wrap;
}

.inside-page-wrapper .store-card-info p span {
	display: block;
	font-weight: 500;
}

.inside-page-wrapper .store-phone {
	margin-bottom: 10px;
}

.inside-page-wrapper .store-phone,
.inside-page-wrapper .store-hours {
	width: 100%;
}

.inside-page-wrapper .store-phone a {
	font-weight: 700;
	color: #333333;
}

.inside-page-wrapper .store-phone a:hover {
	background-color: transparent;
	color: #c9353c;
}

.inside-page-wrapper .card.store .card-bottom.button-only {
	flex-wrap: wrap;
	height: auto;
	min-height: 62px;
	padding-top: 10px;
	box-sizing: border-box;
}

.inside-page-wrapper .card.store .module-button {
	flex: 1 1 auto;
	margin-bottom: 10px;
	transition: background-color 300ms ease;
}

.module-button.my-store:hover {
	background-color: #c9353c;
	cursor: pointer;
}

.my-store.saved {
	display: flex;
	justify-content: center;
	align-items: center;
	color: #c9353c;
	font-weight: 500;
	background-color: #efefef;
	border: 1px solid #cccccc;
}

.module-button.my-store.saved:hover {
	color: #c9353c;
	background-color: #cccccc;
	border: 1px solid #9a9a9a;
}

.my-store.saved:before {
	content: '';
	display: inline-block;
	margin-right: 5px;
	margin-bottom: 1px;
	height: 20px;
	width: 20px;
	background-image: url('/20160710165515/assets/images/icons/star.svg');
	background-size: contain;
	background-position: 50%;
}

/* ------------------------ */
/* MEAT MARKET CARD */

.inside-page-wrapper .card.meat-market {
	border-top: 10px solid #333333;
}

.meat-market .card-image {
	background-image: url('/20160710165515/assets/images/meat-market-sticker.png');
	background-position: 100%;
}

.inside-page-wrapper .card-price {
	margin-bottom: 10px;
}

.inside-page-wrapper .card-price-cost {
	margin-bottom: 0;
	font-size: 1.25em;
	color: #c9353c;
	font-weight: 500;
}

.inside-page-wrapper .card-price-unit {
	margin-bottom: 10px;
	font-size: 0.875em;
	font-weight: 500;
}

/* ------------------------ */
/* INSIDE PAGE
/* ------------------------ */

/* PAGINATION */
.pagination-wrapper {
	border-top: 1px solid #ccc;
	border-bottom: 1px solid #ccc;
	padding-top: 15px !important;
	padding-bottom: 15px;
	margin-top: 15px;
	margin-left: 10px;
	margin-right: 10px;
}

.pagination-wrapper .pagination-current-page label,
.pagination-wrapper .pagination-items-per-page label {
	text-transform: none;
	vertical-align: baseline;
}

.pagination-previous-page,
.pagination-next-page {
	text-transform: uppercase;
	font-size: 0.875rem;
	font-weight: 700;
}

.pagination-previous-page a,
.pagination-next-page a {
	font-weight: 700;
}

.pagination-previous-page a:hover,
.pagination-next-page a:hover,
.pagination-previous-page a:active,
.pagination-next-page a:active {
	text-decoration: none;
	border-bottom: 0 solid transparent;
}

.pagination-next-page a:after,
.pagination-next-page span:after {
	content: "\00bb";
	padding-left: 5px;
}

.pagination-previous-page a:before,
.pagination-previous-page span:before {
	content: "\00ab";
	padding-right: 5px;
}

.pagination-wrapper select {
	padding: 8px 5px;
}

.pagination-wrapper {
	padding-top: 25px;
	width: 100%;
}

/* ------------------------ */
/* SOCIAL SHARE */

.social-share {
	margin: 30px auto;
}

.social-share h2 {
	margin-bottom: 10px;
	font-size: 1.25em;
	text-transform: uppercase;
}

.social-share-list {
	list-style-type: none;
	margin: 0 auto ;
	padding-left: 0;
	display: flex;
	justify-content: center;
}

.social-share-link.share-facebook {
	margin-left: 0;
	padding-left: 0;
}

.top-share-list {
	margin-bottom: 0;
}

.social-share-link {
	display: block;
	margin: 0 8px;
	height: 34px;
	width: 34px;
	background-size: 25px 25px;
	background-position: 50% 50%;
	background-color: #999999;
	background-repeat: no-repeat;
	border-radius: 50%;
	-webkit-transition: background-color 200ms ease;
	transition: background-color 200ms ease;
	box-shadow: none !important;
}

.social-share-link:hover {
	background-color: #c9353c !important;
	cursor: pointer;
}

.share-facebook {
	background-image: url('/20160710165515/assets/images/icons/facebook-icon.svg');
}

.share-twitter {
	background-image: url('/20160710165515/assets/images/icons/twitter-icon.svg');
}

.share-pinterest {
	background-image: url('/20160710165515/assets/images/icons/pinterest-icon.svg');
}

.share-email {
	background-image: url('/20160710165515/assets/images/icons/email-icon.svg');
}

/* ------------------------ */
/* CUSTOM MODULES
/* ------------------------ */

/* Module Intro */

.module-intro-content {
	margin-bottom: 1rem;
}

.module-intro-content h1,
.module-intro-content h2,
.module-intro-content h3,
.module-intro-content h4,
.module-intro-content h5,
.module-intro-content h6,
.module-intro-content p {
	margin: 0;
}

.module-intro-content h1 + *,
.module-intro-content h2 + *,
.module-intro-content h3 + *,
.module-intro-content h4 + *,
.module-intro-content h5 + *,
.module-intro-content h6 + *,
.module-intro-content p + * {
	margin-top: 1rem;
}

/*.module-intro-content h2 {	font-size: 1.250em; }*/

.intro-subtext {
	font-size: 1.25em;
	text-align: center;
}


/* Module Outro */

.module-outro-wrap {
	display: flex;
	justify-content: center;
	box-sizing: border-box;
}

.module-outro-wrap:before,
.module-outro-wrap:after {
	content: '';
	display: block;
	width: 175px;
}

.module-outro-content {
	flex: 0 1 800px;
	margin: 10px auto 0;
	font-size: 1.25em;
	text-align: center;
}

.module-outro-content a {
	color: #4d647c;
	text-decoration: underline;
}

.module-outro-content a:hover {
	color: #c9353c;
	cursor: pointer;
}

.module-outro-content h1,
.module-outro-content h2,
.module-outro-content h3,
.module-outro-content h4,
.module-outro-content h5,
.module-outro-content h6,
.module-outro-content p {
	margin: 0;
}

.module-outro-content h1 + *,
.module-outro-content h2 + *,
.module-outro-content h3 + *,
.module-outro-content h4 + *,
.module-outro-content h5 + *,
.module-outro-content h6 + *,
.module-outro-content p + * {
	margin-top: 10px;
}

/* ------------------------ */
/* MODULE SUBNAV */

.module-subnav-wrap {
	display: flex;
	align-items: center;
	position: relative;
	text-align: center;
	background-color: #ffffff;
}

.module-subnav-wrap .container {
	flex-grow: 1;
	display: flex;
	flex-wrap: wrap;
	justify-content: center;
	align-items: center;
}

.module-subnav-wrap.below-page-title {
	margin-top: -35px;
	margin-bottom: 20px;
}

.module-subnav {
	list-style-type: none;
	margin: 0;
	padding: 0 0 1.5em;
	display: flex;
	justify-content: center;
}

.module-subnav li {
	display: flex;
	align-items: center;
	justify-content: center;
	padding: 0 5px;
}

.module-subnav li:last-child {
	border-right: 0;
}

.module-subnav-buttons {
	display: flex;
	flex: 0 1 auto;
	justify-content: center;
	flex-wrap: wrap;
	margin: 10px 0;
}

/* ------------------------ */
/* FILTER BAR */

.module-filter-wrap {
	position: relative;
	background-color: #ffffff;
}

.module-recipes .module-filter-wrap .container {
	display: flex;
	justify-content: center;
	align-items: flex-end;
}

.module-filter {
	display: flex;
	flex: 0 1 50%;
	flex-wrap: wrap;
	align-items: center;
	justify-content: space-between;
	margin-right: 15px;
}

.module-filter-group {
	display: flex;
	flex: 0 1 48.5%;
	flex-wrap: wrap;
	align-items: flex-start;
	justify-content: flex-start;
	flex-direction: column;
}

@media (max-width: 991px) {
	.module-filter {
		margin-right: 0;
	}
}

.module-filter-wrap .container.module-filter-stacked {
	align-items: flex-end;
}

.module-filter-stacked {
	align-items: flex-end;
}

/* Module Filter Bar Fields */

.module-filter label {
	margin-right: 10px;
	color: #4b4b4b;
}

.module-filter-select { /* Wrap <select> elements for a custom arrow */
	position: relative;
	width: 100%;
	overflow: hidden;
	border: 1px solid #cccccc;
	background-image: url('/20160710165515/assets/images/icons/arrow-down.svg');
	background-size: 14px 8px;
	background-position: calc(100% - 10px) 50%;
	background-repeat: no-repeat;
}

.module-filter-select select {
	width: 100%;
	padding-right: 0;
	font-size: 1rem;
	-webkit-appearance: none;
	-moz-appearance: none;
	appearance: none;
	border: none;
	background-color: transparent;
}

.module-filter-text {
	margin-right: 20px;
}

.module-filter-text input[type="text"] {
	padding: 9px 13px;
	box-sizing: border-box;
}

.module-filter-text .zip-code {
	width: 188px;
}

/* "Add Ingredient" Text Field */
.module-filter-text.add-ingredient-filter-text {
	position: relative;
}

.add-ingredient-button {
	display: block;
	position: absolute;
	top: 0;
	right: 0;
	width: 40px;
	height: 40px;
	background-image: url('/20160710165515/assets/images/icons/add.svg');
	background-position: 50%;
	background-size: 15px 15px;
	background-repeat: no-repeat;
	background-color: transparent;
	border: 0;
	padding: 0;
}

.add-ingredient-button:hover {
	cursor: pointer;
}

.module-filter-select.by-ingredient,
.module-filter-text .add-ingredient-field {
	width: 200px;
}

/* Buttons in the Module Filter Bar */
.module-filter-wrap.centsable-module-filter-wrap {
	margin-top: 1.5rem;
	margin-bottom: 1.5rem;
	border-top: 1px solid hsla(0, 1%, 47%, 0.5);
	border-bottom: 1px solid hsla(0, 1%, 47%, 0.5);
}

.module-filter-wrap.centsable-module-filter-wrap .module-filter > * {
	padding-top: 1.5rem;
	padding-bottom: 1.5rem;
}

.module-filter-buttons {
	overflow: hidden;
	margin-top: 0;
	display: flex;
	justify-content: space-between;
	align-items: baseline;
}

.module-filter-buttons.centsable-filter-buttons {
	flex-direction: column;
}

.module-filter-buttons > * {
	margin-bottom: 0 !important;
	min-height: 44px;
}

.module-filter-buttons > .no-min-height {
	min-height: 0;
}

.module-health .module-filter-buttons {
	margin-top: 0;
}

.module-filter-buttons .module-button,
.module-filter-buttons .module-button-secondary {
	display: block;
	margin: 0 20px 0 0;
	cursor: pointer;
	line-height: 1.5rem !important;
}

.nav-link-search.recipe-search-icon {
	height: 40px;
	width: 40px;
	margin-left: 10px;
	background-image: url('/20160710165515/assets/images/search_blk.svg');
	background-size: 20px 40px;
	background-position: 50% 0;
	background-repeat: no-repeat;
	transition: none;
	background-color: transparent;
	opacity: 0.5;
	cursor: pointer;
	position: absolute;
	right: 0;
}

.nav-link-search.recipe-search-icon:hover {
	background-image: url('/20160710165515/assets/images/search_red-D8263C.svg');
	background-size: 20px 40px;
	background-repeat: no-repeat;
	background-position: 50% 0;
	opacity: 1;
}

/* Just Links in the Module Filter Bar */

.content-wrapper a.filter-link {
	display: inline-block;
	margin: 17px 0 0;
	color: #333333;
	min-height: 0;
}

.content-wrapper .module-filter-buttons.centsable-filter-buttons a.filter-link {
	margin-top: 8px;
}

.content-wrapper a.filter-link.current,
.content-wrapper a.filter-link.w--current {
	color: #c9353c;
	cursor: pointer;
}

.content-wrapper a.filter-link:hover {
	color: #c9353c;
	background-color: transparent;
	cursor: pointer;
}

.content-wrapper a.first-filter-link {
	border: 0;
}

.content-wrapper a.filter-link.first {
	border-left: 0px;
}

.module-recipes .module-filter-select select optgroup {
	color: #c9353c;
	opacity: 1;
}

.module-filter-select select option:not(checked) {
	color: #333333;
	opacity: 1;
}

/* ------------------------ */
/* CAREERS */


.job-desc .required_information {
	text-align: right;
}

.job-desc .required_information em {
	color: #eb0000;
	font-style: italic;
}

.card.career {
	border-top: 10px solid #c9353c;
}

.ui-datepicker .ui-datepicker-title select {
	font-size: 0.8em;
	padding: 3px;
}

.form-group.date {
	position: relative;
}

.form-group.date img.ui-datepicker-trigger {
	cursor: pointer;
	position: absolute;
	top: 33px;
	right: 18px;
}

.form-group.third.left.date img.ui-datepicker-trigger,
.form-group.third.middle.date img.ui-datepicker-trigger {
	top: 8px;
}

.form-group.third.left.date img.ui-datepicker-trigger {
	right: 24px;
}

.form-group.third.middle.date img.ui-datepicker-trigger {
	right: 8px;
}

/* Careers Table */

.careers-list {
	width: 100%;
	font-size: 1em;
	text-align: left;
}


@media screen and (max-width: 815px) {
	.careers-list {
		font-size: 0.75rem;
	}
}

.careers-list thead {
	border-bottom: 2px solid #cccccc;
}

.careers-list th {
	padding: 10px 5px;
}

.careers-list td {
	padding: 10px;
}

.careers-list tr td:nth-child(1) {
	width: 40%;
	font-weight: 500;
}

/* Columns not sortable */
.careers-list thead tr th {
	cursor: default !important;
	background-image: none !important;
}

.careers-list thead tr th span {
	cursor: default !important;
}

table.careers-list.dataTable thead th {
	padding: 10px 18px 10px 10px;
}

/* Rows are links to the job */
.careers-list tbody tr {
	cursor: pointer;
	transition: all 300ms ease;
}

.careers-list tbody tr:hover {
	background-color: rgba(0, 0, 0, 0.05);
	cursor: pointer;
}

/* Job Page */
.job-intro {
	display: flex;
	justify-content: space-between;
}

.job-intro div:first-child {
	text-align: left;
	padding-right: 40px;
}

.job-intro div:last-child {
	text-align: right;
}

.job-intro p {
	font-size: 1.125em;
	margin-bottom: 0;
}

.job-intro .job-title-label {
	font-size: 0.875em;
	text-transform: uppercase;
}

.job-intro h2 {
	margin: 0;
	font-size: 1.875em;
	font-weight: 400;
}

.job-desc h3 {
	color: #c9353c;
	font-weight: 500;
	text-transform: uppercase;
	font-size: 1.25em;
}

/* ------------------------ */
/* MEAT COUNTER */

.inside-page-wrapper .card.meat-counter {
	border-top: 10px solid #ec1c24;
}

.card.meat-counter .card-image {
	height: 317px;
}

/* ------------------------ */
/* BAKERY */
.inside-page-wrapper .card.bakery {
	border-top: 10px solid #ec1c24;
}

.card.bakery .card-image {
	height: 317px;
}

/* Testimonial Slideshow */

.testimonial-navdots {
	display: flex;
	justify-content: center;
	margin-bottom: 10px;
}

.testimonial-navdot {
	height: 14px;
	width: 14px;
	margin: 0 5px;
	border-radius: 50%;
	border: 2px solid #4d647c;
	background-color: #ffffff;
	cursor: pointer;
	box-shadow: none !important;
}

.testimonial-navdot.current {
	background-color: #4d647c;
	cursor: pointer;
}

.testimonial-navdot:hover {
	background-color: #4d647c;
	cursor: pointer;
}

.testimonial-slide .testimonal-text {
	font-size: 0.875rem;
	line-height: 1.45rem;
}

/* Meat Market Product Page */

.meat-counter-wrap {
	display: flex;
	margin: 0 auto;
	flex-direction: row;
	justify-content: center;
}

.meat-counter-gallery {
	width: 50%;
}

.meat-counter-info {
	width: 50%;
	margin-left: 30px;
}

.meat-counter-info p {
	margin-top: 5px;
	margin-bottom: 0;
}

.meat-counter-info h2 {
	margin-bottom: 0;
	font-size: 1.25em;
	line-height: 2rem;
	font-weight: 500;
	color: #c9353c;
	text-transform: uppercase;
}

.meat-counter-info p {
	font-size: 1.125em;
}

.meat-counter-info ul {
	font-size: 1.125em;
	line-height: 1.5em;
}

.meat-counter-info .module-button {
	margin: 0 0 20px 0;
}

.meat-counter-main-img {
	padding-bottom: 20px;
	margin-bottom: 20px;
	border-bottom: 1px solid #cccccc;
}

.meat-counter-main-img img {
	max-width: 100%;
	border-radius: 3px;
	box-shadow: 0 0 2px rgba(0, 0, 0, 0.75);
}

.meat-counter-gallery-thumbs {
	display: flex;
	justify-content: center;
	width: 100%;
}

.meat-counter-gallery-thumbs a {
	width: 120px;
	height: 90px;
	margin: 0 10px;
	border-radius: 3px;
	overflow: hidden;
	background-size: cover;
	background-position: 50% 50%;
	background-repeat: no-repeat;
	box-shadow: 0 0 3px rgba(0, 0, 0, 0.25);
}

/* ------------------------ */
/* RECIPES */
/* Recipe Page */

.recipe-save-print {
	display: flex;
	justify-content: center;
	flex-wrap: wrap;
	margin-top: 10px;
}

.recipe-save-print .save,
.recipe-save-print .print {
	display: flex;
	align-items: center;
	height: 100%;
	color: #333333;
	flex: 0 0 auto;
	margin: 0 10px;
	text-transform: uppercase;
	border-right: 0;
}

.recipe-save-print .save:hover,
.recipe-save-print .print {
	cursor: pointer;
}

.recipe-save-print .print:hover {
	color: #c9353c;
	text-decoration: none;
	border-bottom: 0 solid transparent;
}

.recipe-save-print .save:before,
.recipe-save-print .print:before {
	content: '';
	display: inline-block;
	margin-right: 6px;
	color: #333333;
	height: 20px;
	width: 20px;
	background-repeat: no-repeat;
	background-size: 20px 40px;
	background-position: 0% 0%;
}

.recipe-save-print .save:before {
	background-image: url('/20160710165515/assets/images/icons/heart-sprite.svg');
	background-size: 20px 60px;
	background-position: 50% 0%;
}

.recipe-save-print .save:hover:before {
	background-position: 50% 50%;
}

.recipe-save-print .save.saved:before {
	background-position: 50% 100%;
}

.recipe-save-print .print:before {
	background-image: url('/20160710165515/assets/images/icons/print-sprite.svg');
}

.recipe-save-print .print:hover:before {
	background-position: 50% 100%;
}

.recipe-details-wrap {
	display: flex;
}

.recipe-details-wrap h2 {
	color: #c9353c;
	font-weight: 500;
	text-transform: uppercase;
	font-size: 1.25em;
}

.recipe-info {
	flex: 0 1 800px;
	padding: 20px;
	margin-left: 30px;
	background-color: #ffffff;
	box-sizing: border-box;
}

.recipe-directions {
	border-spacing: 5px;
	font-size: 1.125em;
	line-height: 1.5em;
}

.recipe-ingredients .side-bar-header {
	padding-left: 10px;
}

.recipe-info .fb-comments,
.recipe-info .fb-comments > span,
.recipe-info .fb-comments > span > iframe {
	width: 100% !important;
}

.recipe-info .fb-comments {
	padding-top: 20px !important;
}

.recipe-preparation-notes {
	font-size: 1.125em;
}

.recipe-servings {
	display: inline-block;
	font-weight: 500;
	font-size: 1.125em;
}

.recipe-servings span {
	text-transform: uppercase;
	font-weight: 300;
}

.recipe-amount-per-serving {
	display: inline-block;
	font-weight: 500;
	font-size: 1.125em;
}

.recipe-amount-per-serving span {
	text-transform: uppercase;
	font-weight: 300;
}

.recipe-total-time {
	display: inline-block;
	font-weight: 500;
	font-size: 1.125em;
}

.recipe-total-time span {
	text-transform: uppercase;
	font-weight: 300;
}

.recipe-disclaimer {
	font-weight: 100;
	font-size: 0.75em;
	font-style: italic;
	margin-top: 40px;
	color: #333333;
}

.recipe-link {
	display: block;
	margin-top: 10px;
	margin-bottom: 15px;
	color: #cd1f36;
	font-size: 1em;
}

.recipe-video {
	margin-top: 25px;
}

/* Nutritional Information Table */

.recipe-nutrition {
	width: 100%;
	max-width: 470px;
	font-size: 1.125em;
}

.recipe-nutrition td {
	padding: 6px;
}

.recipe-nutrition tr td:last-child {
	font-weight: 500;
	text-align: right;
}

.recipe-nutrition tr:nth-child(odd) {
	background-color: #efefef;
	font-family: inherit;
}

.recipe-nutrition p {
	font-size: 0.9em;
}

.recipe-specifics {
	text-align: center;
	margin-bottom: 35px;
}

.recipe-specifics.not-empty {
	margin-top: -20px;
}

.recipe-specifics p.margined {
	margin-left: 55px;
}

.recipe-search {
	float: left;
	margin-right: 0;
	width: 100%;
	padding: 9px 12px;
	border: 1px solid #cccccc;
	height: 43px;
}

.module-filter-input {
	width: 100%;
}

/* My Shopping List */

.my-shopping-list-wrap {
	display: flex;
	justify-content: space-between;
}

.my-shopping-list-functions {
	display: flex;
	justify-content: space-between;
	margin-bottom: 30px;
	padding: 0 10px 20px 10px;
	border-bottom: 1px solid #cccccc;
}

a.print-list,
a.clear-list {
	display: flex;
	align-items: center;
	text-transform: uppercase;
	color: #333333;
	background-color: transparent;
}

.print-list:hover,
.clear-list:hover {
	cursor: pointer;
	text-decoration: none;
	color: #c9353c;
	border-bottom: 0 solid transparent;
}

.print-list:before,
.clear-list:after {
	content: '';
	display: inline-block;
	background-repeat: no-repeat;
	background-position: 50% 0;
}

.print-list:before {
	height: 20px;
	width: 20px;
	margin-right: 6px;
	margin-top: -2px;
	background-image: url('/20160710165515/assets/images/icons/print-sprite.svg');
	background-size: 20px 40px;
}

.clear-list:after {
	height: 15px;
	width: 15px;
	margin-left: 6px;
	margin-top: -1px;
	background-image: url('/20160710165515/assets/images/icons/clear-list-sprite.svg');
	background-size: 15px 30px;
}

.clear-list:hover {
	cursor: pointer;
}

.print-list:hover:before,
.clear-list:hover:after {
	background-position: 50% 100%;
}

.recipe-ingredients-list {
	list-style-type: none;
	padding-left: 0;
	font-size: 1.125em;
}

.recipe-ingredients-website-link {
	padding-top: 20px;
	width: 350px;
}

.recipe-ingredients-website-link li {
	padding: 6px 10px;
	border-bottom: 1px solid #cccccc;
}

.recipe-ingredients-website-link .recipe-website-link {
	width: 100%;
	margin-top: 30px;
	display: flex;
	justify-content: center;
}

/* Shopping List Table */

.my-shopping-list {
	width: 100%;
	font-size: 1.125em;
	text-align: left;
}

.my-shopping-list thead tr {
	background-color: transparent !important;
	font-family: inherit;
}

.my-shopping-list th {
	padding: 10px 5px;
}

.my-shopping-list td {
	padding: 6px;
}

.my-shopping-list thead tr th {
	padding-top: 0;
}

.my-shopping-list tr td:first-child {
	padding-left: 15px;
}

.my-shopping-list tr td:last-child {
	width: 30px;
	background-image: url('/20160710165515/assets/images/icons/remove-grey.svg');
	background-size: 15px;
	background-position: 50% ;
	background-repeat: no-repeat;
	border-left: 5px solid #ffffff;
	cursor: pointer;
}

.my-shopping-list tr td:last-child:hover {
	background-image: url('/20160710165515/assets/images/icons/remove-red.svg');
}

.my-shopping-list tr:nth-child(odd) {
	background-color: #efefef;
	font-family: inherit;
}

/* ------------------------ */
/* STORES */

/* Stores Search Bar */

.stores-search {
	width: 100%;
	margin: 30px 0;
}

.stores-search form {
	display: block;
	justify-content: center;
}

.stores-search .stores-search-options label {
	text-transform: none;
	font-weight: 400;
}

.stores-search form input[type=text],
.stores-search form .select2-container {
	width: 100% !important;
	max-width: 250px;
}

@media (max-width: 660px) {
	.stores-search form input[type=text],
	.stores-search form .select2-container {
		max-width: 200px;
	}
}

.stores-search .select2-container .select2-choice abbr {
	margin-top: -1px;
}

.stores-search form input[type=submit] {
	cursor: pointer;
}

.stores-search form input[type=submit]:hover {
	background-color: #3c546d;
	cursor: pointer;
}

.stores-search-options {
	display: flex;
	justify-content: center;
}

.stores-search-options .select2-container .select2-choice {
	background: #ffffff;
	border-radius: 0 !important;
	border-color: #cccccc;
	box-shadow: none;
	color: #333333 !important;
}

.stores-search-options .select2-container-active .select2-choice {
	border-color: #3c546d;
	border-radius: 3px;
	box-shadow: none;
	height: auto;
	max-height: 38px;
	padding: 8px 12px;
	font-size: 0.875rem;
	line-height: 1.42857143;
	-webkit-transition: none;
	transition: none;
}

.stores-search-options .select2-container .select2-choice > .select2-chosen {
	margin-top: 8px !important;
	font-size: 0.875rem;
	line-height: 1.42857143;
}

.stores-search-options .select2-container.select2-container-active .select2-choice > .select2-chosen {
	margin-top: 0 !important;
	margin-left: -2px;
}

.stores-search-options span.form-label {
	margin-top: 3px;
	margin-bottom: 0;
}

.stores-search-options .select2-dropdown-open.select2-container-active .select2-choice {
	border-radius: 0 !important;
	font-size: 0.875rem;
	line-height: 1.42857143;
}

.select2-drop-active {
	border-color: #3c546d;
}

.select2-container .select2-choice > .select2-chosen,
.select2-default {
	background-color: #fff;
	color: #636363 !important;
}

.select2-default {
	background-color: #fff;
	color: #636363 !important;
}

.select2-chosen {
	margin-top: 4px !important;
}

.select2-container--default .select2-selection--single .select2-selection__placeholder {
	color: #737373 !important;
}

.select2-container--default .select2-selection--single .select2-selection__clear {
	line-height: 37px;
}

@media (max-width: 479px) {
	.select2-hidden-accessible {
		width: 100% !important;
	}

	.select2.select2-container {
		width: 100% !important;
	}
}

a.stores-search-options-link {
	margin: 0 20px;
}

a.stores-search-options-link.use-my-location:before {
	content: '';
	display: inline-block;
	height: 12px;
	width: 12px;
	margin-right: 5px;
	background-image: url('/20160710165515/assets/images/icons/location-sprite.svg');
	background-position: 50% 0%;
	background-size: 12px 24px;
}

a.stores-search-options-link.use-my-location:hover:before {
	background-position: 50% 100%;
}

.or {
	font-style: italic;
	margin-bottom: 0;
}

/* Store Ad Page */

.nav-link.view-map {
	display: flex;
	align-items: center;
}

.nav-link.view-map:after {
	content: '';
	display: block;
	width: 18px;
	height: 18px;
	margin-left: 3px;
	background-image: url('/20160710165515/assets/images/icons/map-marker-sprite.svg');
	background-size: 18px 36px;
	background-position: 50% 0;
	background-repeat: no-repeat;
}

.nav-link.view-map:hover:after {
	background-position: 50% 100%;
}

.nav-link.view-map.current:after {
	background-position: 50% 100%;
}

.store-ad-content {
	text-align: center;
}

.store-ad-functions {
	display: flex;
	justify-content: space-between;
	width: 100%;
	max-width: 600px;
	margin: 0 auto;
	margin-bottom: 30px;
}

.store-ad-functions a {
	display: flex;
	align-items: center;
	color: #333333;
	font-size: 0.9em;
	text-decoration: underline;
}

.store-ad-functions a:hover {
	color: #c9353c;
	cursor: pointer;
}

.alert-me:before,
.download-pdf:before {
	content: '';
	display: block;
	height: 15px;
	width: 15px;
	flex-shrink: 0;
	margin-right: 5px;
	background-size: contain;
	background-position: 50%;
	background-repeat: no-repeat;
}

.alert-me:before {
	background-image: url('/20160710165515/assets/images/icons/email.svg');
}

.download-pdf:before {
	background-image: url('/20160710165515/assets/images/icons/download.svg');
}

/* Meat Counter Testimonials Slideshow */

.testimonial-navdots {
	display: flex;
	justify-content: center;
	margin: 20px auto;
}

/* Store Ad Slideshow */

.store-ad-gallery-border {
	border: #000000 solid 1px;
}

.store-ad-window {
	position: relative;
	max-width: 758px;
	margin: 0 auto;
}

.store-ad-window .pdfImage {
	margin: 0 !important;
}

.store-ad-arrow-left,
.store-ad-arrow-right {
	position: absolute;
	height: 100%;
	width: 60px;
	top: 0;
	bottom: 0;
	display: flex;
	align-items: center;
}

.store-ad-arrow-left {
	left: -60px;
}

.store-ad-arrow-right {
	right: -60px;
}

.arrow-icon-left,
.arrow-icon-right {
	background-color: rgba(0, 0, 0, 0.6);
	background-position: 50%;
	background-repeat: no-repeat;
	background-size: 30px;
	cursor: pointer;
	display: block;
	height: 60px;
	width: 60px;
	margin-top: -30px;
	opacity: 0.75;
	top: 50%;
	z-index: 999;
}

.arrow-icon-left:hover,
.arrow-icon-right:hover {
	opacity: 1;
}

.arrow-icon-left {
	border-radius: 50% 0 0 50%;
	background-image: url('/20160710165515/assets/images/icons/circle-arrow-left.svg');
}

.arrow-icon-right {
	border-radius: 0 50% 50% 0;
	background-image: url('/20160710165515/assets/images/icons/circle-arrow-right.svg');
}

.store-ad-image {
	max-width: 100%;
	box-shadow: 0 0 2px rgba(0, 0, 0, 1);
}

.store-ad-navdots {
	display: flex;
	justify-content: center;
	margin: 25px auto 25px auto;
}

.store-ad-navdot {
	background-color: #ffffff;
	border-radius: 50%;
	border: 2px solid #4d647c;
	cursor: pointer;
	height: 15px;
	margin: 0 10px;
	width: 15px;
}

.store-ad-navdot.current {
	background-color: #4d647c;
	cursor: pointer;
}

.store-ad-navdot:hover {
	background-color: #4d647c;
	cursor: pointer;
}

.cycle-pager-active,
.cycle-pager-active:hover {
	background-color: #c9353c;
	border-color: #c9353c;
}

@media (max-width: 991px) {

	.arrow-icon-left {
		border-radius: 0 50% 50% 0;
		left: 0;
	}

	.arrow-icon-right {
		border-radius: 50% 0 0 50%;
		right: 0;
	}

	.store-ad-navdot {
		height: 10px;
		margin: 0 10px;
		width: 10px;
	}
}

.zoomContainer {
	overflow: hidden;
	z-index: 999;
}

@media (max-width: 991px) {

	.zoomContainer {
		display: none;
	}
}

/* Store Ad Info */

.stores-search-options input[type="checkbox"] {
	margin-top: 6px;
}

.store-ad-info {
	margin-right: -20px;
	margin-left: -20px;
	border-top: 1px solid #cccccc;
	margin-top: 30px;
}

.store-ad-info-wrap {
	display: flex;
	max-width: 600px;
	margin: 0 auto;
	padding: 20px 20px 0;
}

.store-ad-phone {
	flex: 1 0 50%;
	text-align: left;
}

.store-ad-hours {
	flex: 1 0 50%;
	text-align: right;
}

.store-ad-phone p,
.store-ad-hours p {
	margin-bottom: 0;
	text-transform: uppercase;
}

.store-ad-phone p span,
.store-ad-hours p span {
	font-weight: 700;
}

/* Store Map Page */

.store-map {
	margin-right: -20px;
	margin-left: -20px;
}

.store-map img {
	max-width: 100%;
}

/* ------------------------ */
/* CENTSABLE HEALTH */

.centsable-filter select {
	color: #2e2e2e;
	font-family: inherit;
}

.centsable-filter select option:checked {
	color: #c9353c;
	font-family: inherit;
}

.centsable-filter-buttons .filter-link:hover {
	color: #c9353c;
	text-decoration: none;
	border-bottom: 0 solid transparent;
}


/* Centsable Article */

.centsable-article-wrap {
	display: flex;
}

.centsable-article {
	flex: 0 1 800px;
	padding: 20px;
	margin-right: 2%;
	box-sizing: border-box;
}

.centsable-article .fb-comments,
.centsable-article .fb-comments > span,
.centsable-article .fb-comments > span > iframe {
	width: 100% !important;
}

.centsable-article .fb-comments {
	padding-top: 20px !important;
}

.centsable-author-wrap {
	width: 350px;
	margin-top: 40px;
}

.centsable-author {
	border: 1px solid hsla(0, 1%, 47%, 0.5);
}

.centsable-author-photo {
	width: 100%;
}

.centsable-author-content {
	flex: 0 1 auto;
	max-width: 100%;
	padding: 20px;
	box-sizing: border-box;
}

.centsable-author-name {
	margin-bottom: 5px;
	text-transform: uppercase;
	text-align: center;
	font-weight: 500;
	font-size: 1.35em;
}

.centsable-author-title {
	color: #d8263c;
	text-align: center;
	text-transform: uppercase;
	font-size: 1.125em;
}

/* ------------------------ */
/* MEAT MARKET */

.intro-banner {
	position: relative;
	display: inline-block;
	padding: 10px 50px;
	margin: 0 20px;
	color: #ffffff;
	background-color: #c9353c;
	background: repeating-linear-gradient(90deg, #9d2025, #9d2025 10px, #efefef 10px, #efefef 20px), repeating-linear-gradient(90deg, #9d2025, #9d2025 10px, #efefef 10px, #efefef 20px), #c9353c;
	background-size: 100% 1px;
	background-repeat: no-repeat;
	background-position: 0 0, 0 100%;
}

.intro-banner p {
	margin-bottom: 0;
}

.intro-banner-title {
	font-weight: 500;
	font-size: 1.5em;
}


.intro-banner:before,
.intro-banner:after {
	content: '';
	height: 0;
	width: 0;
	position: absolute;
	top: 50%;
	border-width: 28px;
	border-style: solid;
	transform: translateY(-50%);
	z-index: -1;
}

.intro-banner:before {
	border-color: #9d2025 #9d2025 #9d2025 transparent;
	left: -50px;
}

.intro-banner:after {
	border-color: #9d2025 transparent #9d2025 #9d2025;
	right: -50px;
}

/* Meat Market Functions */

.meat-market-functions {
	display: flex;
	justify-content: center;
	align-items: center;
}

.meat-market-functions-mobile {
	display: none;
}

.cart-link {
	display: flex;
	margin-right: 10px;
}

.cart-link:hover {
	text-decoration: none;
	border-bottom: 0 solid transparent;
}

.cart-icon {
	height: 25px;
	width: 28px;
	margin-right: 5px;
	background-image: url('/20160710165515/assets/images/icons/cart.svg');
	background-repeat: no-repeat;
	background-size: contain;
}

.cart-count {
	display: inline-block;
	height: 25px;
	min-width: 25px;
	padding: 2px 5px 0;
	color: #ffffff;
	text-align: center;
	font-weight: 500;
	border-radius: 14px;
	background-color: #4d647c;
	box-sizing: border-box;
}

.cart-link:hover .cart-count {
	text-decoration: none;
	background-color: #c9353c;
	border-bottom: 0 solid transparent;
}

.cart-links {
	display: flex;
}

/* Meat Market Product Page */

.meat-market-product-wrap {
	display: flex;
	align-items: flex-start;
	margin-bottom: 50px;
	padding-bottom: 50px;
	border-bottom: 1px solid #cccccc;
}

.product-info {
	width: 50%;
	margin-right: 30px;
}

.product-desc {
	font-weight: 300;
	font-size: 1.75em;
}

.product-functions {
	padding: 30px;
	border-radius: 3px;
	border: 1px solid #cccccc;
	background-color: white;
}

.product-name {
	margin-bottom: 0;
	font-size: 1.75em;
}

.product-unit {
	margin-bottom: 0;
	font-size: 1.125em;
}

.product-add {
	display: flex;
	align-items: flex-end;
	margin-top: 10px;
}

.product-quantity-field {
	display: flex;
	flex-direction: column;
	margin-right: 20px;
}

.product-quantity-field input {
	width: 60px;
	text-align: right;
}

.product-total {
	flex-grow: 1;
	margin-bottom: 0;
	color: #c9353c;
	font-size: 1.75em;
	font-weight: 500;
}

.button.module-button.add-to-cart-button {
	background-color: #c9353c;
	border: 1px solid #c2182d;
}

.button.module-button.add-to-cart-button:hover {
	background-color: #c2182d;
	cursor: pointer;
}

.product-image {
	width: 50%;
	border-radius: 3px;
	box-shadow: 0 0 2px rgba(0, 0, 0, 0.75);
}

.product-image img {
	display: block;
	width: 100%;
}

.product-extras {
	max-width: 600px;
	margin: 0 auto 30px auto;
}

.product-extras h2 {
	color: #c9353c;
	font-weight: 500;
	text-transform: uppercase;
	font-size: 1.25em;
}

.product-prep p {
	font-size: 1.125em;
}

.product-review {
	margin-top: 50px;
}

.product-review p {
	font-size: 1.5em;
	font-weight: 300;
}

p.product-reviewer {
	font-size: 1.125em;
	text-align: right;
	font-weight: 400;
}

p.product-reviewer span {
	text-transform: uppercase;
}

/* Meat Market Cart */

.meat-market-cart-wrap {
	display: flex;
	align-items: flex-start;
}

.cart-list {
	width: 70%;
	margin-right: 30px;
	margin-bottom: 30px;
}

.cart-item {
	display: flex;
	margin-bottom: 20px;
	padding: 10px;
	border-radius: 2px;
	border: 1px solid #cccccc;
	background-color: #ffffff;
}

.cart-item-img {
	margin-right: 15px;
}

.cart-item-info {
	flex: 0 1 auto;
	display: flex;
	flex-wrap: wrap;
	padding: 10px 10px 10px 0;
}

.cart-item-name-wrap {
	flex: 1 1 auto;
}

.cart-item-name {
	font-size: 1.75em;
	margin-bottom: 0;
}

.cart-item-unit {
	font-size: 1.25em;
	margin-bottom: 0;
}

.cart-item-cost {
	margin-bottom: 0;
	color: #c9353c;
	font-size: 1.75em;
	font-weight: 500;
}

.cart-item-form {
	display: flex;
	align-items: flex-end;
	width: 100%;
	margin-top: 10px;
	padding: 10px 0;
	border-top: 1px solid #cccccc;
}

.cart-quantity-field {
	display: flex;
	flex-direction: column;
	margin-right: 10px;
}

.cart-quantity-field input {
	width: 60px;
	text-align: right;
}

.button.module-button.update-qty-button {
	background-color: #c9353c;
	border: 1px solid #c2182d;
}

.button.module-button.update-qty-button:hover {
	background-color: #c2182d;
	cursor: pointer;
}

.cart-item-total {
	flex-grow: 1;
	margin-bottom: 5px;
	font-size: 1.25em;
	font-weight: 500;
}

.remove-cart-item {
	margin-bottom: 10px;
}

.cart-total-box {
	width: 30%;
	padding: 15px;
	text-align: center;
	border: 1px solid #cccccc;
	border-radius: 2px;
	background-color: #ffffff;
	box-sizing: border-box;
}

.cart-total-box h2 {
	padding-bottom: 10px;
	margin-bottom: 10px;
	color: #c9353c;
	font-weight: 500;
	text-transform: uppercase;
	font-size: 1.25em;
	border-bottom: 1px solid #cccccc;
}

.cart-total-table {
	width: 100%;
	max-width: 500px;
	margin-bottom: 20px;
}

.cart-total-table td {
	padding: 5px;
}

.cart-total-table tr td:first-child {
	text-transform: uppercase;
	text-align: left;
}

.cart-total-table tr td:last-child {
	text-align: right;
	font-weight: 500;
}

.button.module-button.checkout-button {
	margin-bottom: 20px;
	background-color: #c9353c;
	border: 1px solid #c2182d;
}

.button.module-button.checkout-button:hover {
	background-color: #c2182d;
	cursor: pointer;
}

.cart-disclaimer {
	font-size: 0.875;
	color: #999999;
}

/* ---------------------------- */
/* MOBILE BREAKPOINTS
/* ---------------------------- */

/* ---------------------------- */
/* TABLET AND BELOW */

@media (max-width: 991px) {
	/* CARDS */

	.inside-page-wrapper .card {
		flex: 0 1 31%;
	}

	.inside-page-wrapper .card.recipe {
		max-width: 31%;
	}

	.inside-page-wrapper .store-card-info {
		flex-wrap: wrap;
	}

	.inside-page-wrapper .store-phone {
		margin-bottom: 10px;
	}

	.inside-page-wrapper .store-phone,
	.inside-page-wrapper .store-hours {
		flex: 0 0 100%;
	}

	.inside-page-wrapper .store-hours p {
		justify-content: flex-start;
	}

	/* MODULE INTRO & OUTRO */

	.module-intro-content {
		flex: 0 1 100%;
	}

	.module-intro-wrap:before,
	.module-intro-wrap:after {
		display: none;
	}

	.module-outro-wrap:before,
	.module-outro-wrap:after {
		display: none;
	}

	/* MODULE FILTER BAR */

	/* Match the width of the form elements */
	.store-ad-functions {
		flex-direction: column;
		align-items: center;
	}

	.store-ad-functions a {
		margin-bottom: 15px;
	}

	.module-recipes .module-filter-wrap .container {
		flex-direction: column;
		align-items: stretch;
	}

	.module-filter-buttons .module-button {
		flex: 0 1 48.5%;
		min-width: 0 !important;
		margin-top: 15px !important;
	}

	.module-filter-buttons .button.module-button + .button.module-button {
		margin-left: 0 !important;
	}

	.recipe-search {
		width: 100%;
	}

	/* MEAT MARKET FUNCTIONS */

	.meat-market-functions {
		display: none;
	}

	.meat-market-functions-mobile {
		display: flex;
		align-items: center;
		margin-right: 20px;
	}

	/* MODULE FILTER BAR */

	.meat-market-cart-wrap {
		flex-direction: column;
	}

	.inside-page-wrapper .cart-list {
		width: 100%;
		margin-right: 0;
		margin-bottom: 10px;
	}

	.cart-total-box {
		width: 100%;
		max-width: 400px;
		margin: 0 auto;
		margin-bottom: 30px;
	}
}

@media screen and (max-width: 850px) {
	.module-healthblogs .inside-page-wrapper .card,
	.module-healthnews .inside-page-wrapper .card {
		flex: 0 1 48%;
		height: 320px;
	}
}

/* ---------------------------- */
/* HORIZ AND BELOW */

@media screen and (max-width: 767px) {
	/* CARDS */

	.inside-page-wrapper .card {
		flex: 0 1 48%;
		height: 320px;
	}

	.inside-page-wrapper .card.recipe {
		max-width: 48%;
	}

	.inside-page-wrapper .card.store {
		flex: 0 1 48%;
		height: auto;
	}

	.inside-page-wrapper .card.store .module-button {
		width: 100%;
	}

	/* MODULE SUBNAV */

	.module-subnav {
		flex-direction: column;
	}

	.module-subnav li {
		border-right: 0;
		text-align: center;
	}

	.module-subnav .nav-link {
		margin-bottom: 10px;
	}

	.module-subnav-wrap .container {
		flex-direction: column;
	}

	.module-subnav-buttons .module-button,
	.module-subnav-buttons .module-button-secondary {
		flex-grow: 0;
		width: 225px;
		margin-bottom: 15px;
	}

	/* MODULE FILTER BAR */
	.module-filter > div:not(.module-filter-buttons),
	.module-filter label {
		margin-bottom: 5px;
	}

	.module-filter-buttons .module-button,
	.module-filter-buttons .module-button-secondary {
		flex-grow: 0;
		width: 225px;
		margin-bottom: 15px;
	}

	.centsable-filter-buttons {
		width: 100%;
	}

	.filter-link {
		text-align: center;
		border-left: 0;
	}

	/* CAREERS */
	table.dataTable.careers-list thead {
		display: none;
	}

	table.dataTable.careers-list tbody tr {
		display: block;
	}

	table.dataTable.careers-list tr th:last-child,
	table.dataTable.careers-list tr td:last-child {
		border-right: 1px solid white;
	}

	table.dataTable.careers-list tbody td,
	table.dataTable.careers-list tbody td:nth-child(1) {
		position: relative;
		width: auto;
		display: block;
		padding-left: 100px;
		font-size: 1.125em;
		border-bottom: 0;
	}

	.careers-list tbody td:before {
		content: attr(data-label);
		position: absolute;
		left: 10px;
		top: 10px;
		width: 100px;
		font-weight: 400;
		font-size: 0.825em;
	}

	.form-group.date img.ui-datepicker-trigger {
		right: 8px !important;
	}

	div.cards.featured-cards {
		flex-direction: column;
		display: block;
	}

	/* JOB PAGE */

	.job-intro {
		flex-direction: column;
	}

	.job-intro div:not(:first-child) {
		margin-top: 20px;
		text-align: left;
	}

	/* MEAT COUNTER */

	.meat-counter-wrap {
		flex-direction: column;
	}

	.meat-counter-gallery {
		width: 100%;
		text-align: center;
		margin-bottom: 30px;
	}

	.meat-counter-info {
		width: 100%;
		margin: 0;
	}

	/* RECIPE PAGE */

	.module-filter-group {
		flex-direction: column;
		align-items: stretch;
	}

	.recipe-details-wrap {
		flex-direction: column;
	}

	.recipe-info {
		flex: 1 1 auto;
		width: 100%;
		margin-bottom: 30px;
		margin-left: 0;
		padding-left: 0;
		padding-right: 0;
	}

	.recipe-servings {
		display: block;
	}

	.recipe-amount-per-serving {
		display: block;
	}

	.recipe-total-time {
		display: block;
	}

	.recipe-specifics p.margined {
		margin-left: 0;
	}

	/* STORE AD */

	.store-ad-functions {
		align-items: flex-start;
	}

	.store-ad-functions a {
		align-items: flex-start;
		text-align: left;
	}

	.store-ad-info-wrap {
		flex-direction: column;
	}

	.store-ad-phone {
		flex: 1 1 auto;
		margin-bottom: 15px;
	}

	.store-ad-hours {
		flex: 1 1 auto;
		text-align: left;
	}

	.recipe-ingredients {
		padding-top: 0px;
		padding-bottom: 0;
		width: 100%;
	}

	/* CENTSABLE HEALTH */

	.inside-page-wrapper .centsable-article-wrap {
		flex-direction: column;
	}

	.inside-page-wrapper .centsable-article {
		width: 100%;
	}

	.inside-page-wrapper .centsable-author-wrap {
		width: 100%;
		margin-top: 20px;
	}

	.inside-page-wrapper .centsable-author {
		display: flex;
		align-items: flex-start;
		flex-direction: column;
	}

	/* MEAT COUNTER */

	.meat-market-product-wrap {
		flex-direction: column;
	}

	.product-info {
		width: 100%;
		order: 2;
	}

	.product-image {
		width: 100%;
		order: 1;
		margin-bottom: 30px;
		text-align: center;
	}

	.module-meatcounter .separate {
		display: none;
	}

	/* MEAT MARKET */

	.inside-page-wrapper .cart-item {
		flex-direction: column;
	}
}

/* ---------------------------- */
/* SPECIAL BREAKPOINTS */
@media screen and (max-width: 640px) {
	.button.secondary.module-button-secondary {
		margin-left: 0rem !important;
	}

	.module-filter-buttons .module-button,
	.module-filter-buttons .module-button-secondary {
		margin-top: 15px;
	}

	.module-health .content-wrapper .module-filter-buttons {
		align-items: center;
	}

	.module-health .content-wrapper .module-filter-buttons a.filter-link {
		margin-top: 5px;
		height: auto;
	}
}

@media screen and (max-width: 590px) {
	.module-healthblogs .inside-page-wrapper .card,
	.module-healthnews .inside-page-wrapper .card {
		flex: 0 1 98%;
		height: 320px;
	}
}

@media screen and (max-width: 550px) {
	.meat-market-functions-mobile {
		order: 1;
		justify-content: center;
		width: 100%;
		margin-top: 10px;
		margin-right: 0;
		padding: 10px 20px;
		border-top: 1px solid #cccccc;
	}

	.module-filter,
	.module-filter-buttons {
		flex-direction: column;
	}

	.module-recipes.controller-pages-recipes .module-filter,
	.module-recipes.controller-pages-recipes .module-filter-buttons {
		display: block;
	}

	.module-filter-group,
	.module-filter-buttons .module-button {
		flex-basis: 100%;
		width: 100%;
	}

	.module-filter-buttons .module-button {
		margin-top: 10px !important;
	}

	.module-filter-buttons .module-button + .module-button {
		margin-top: 15px !important;
	}
}

/* ---------------------------- */
/* PHONE AND BELOW */

@media (max-width: 650px) {
	.inside-page-wrapper .card {
		flex: 0 1 98%;
		min-height: 0;
	}

	.inside-page-wrapper .card.recipe {
		max-width: 98%;
	}
}

@media screen and (max-width: 555px) { /* phone only */
	/* STORES */

	.stores-search-options {
		flex-direction: column;
		align-items: center;
	}

	.or {
		margin-top: 15px;
		margin-bottom: 15px;
	}
}

@media screen and (max-width: 500px) { /* phone only */

	/* CARDS */

	.inside-page-wrapper .card {
		flex: 0 1 98%;
		height: auto;
		min-width: 0 !important;
	}

	.inside-page-wrapper .card.recipe {
		max-width: 98%;
	}

	.inside-page-wrapper .card.store {
		flex: 0 1 98%;
		height: auto;
	}

	.inside-page-wrapper .card-image-full,
	.inside-page-wrapper .card.video .card-image {
		height: 380px !important;
	}

	/* MODULE FILTER BAR */
	.module-filter-buttons .module-button,
	.module-filter-buttons .module-button-secondary {
		margin-right: 0px;
		margin-left: 0px;
		margin-top: 15px;
		margin-bottom: 0;
	}

	.button.secondary.module-button-secondary {
		margin-left: 0rem !important;
	}

	.module-filter-text .zip-code {
		width: 225px;
	}
	/* MODULE SUBNAV */

	.module-subnav-wrap .container {
		padding: 0;
		max-width: 100%;
	}

	.module-subnav-buttons {
		flex-direction: column;
		align-items: center;
	}

	/* CAREERS */

	.careers-list tbody td,
	.careers-list tbody td:nth-child(1) {
		padding-left: 90px;
	}

	.careers-list tbody td:before {
		width: 65px;
	}

	/* CENTSABLE HEALTH */

	.inside-page-wrapper .centsable-author {
		flex-direction: column;
	}

	/* MEAT COUNTER */

	.product-add {
		flex-wrap: wrap;
	}

	.button.module-button.add-to-cart-button {
		width: 100%;
		margin: 20px 0;
	}

	/* MEAT COUNTER */

	.inside-page-wrapper .cart-item-form {
		flex-wrap: wrap;
	}

	.inside-page-wrapper .cart-item-total {
		order: 1;
		width: 100%;
		margin-top: 15px;
	}
}

@media screen and (max-width: 500px) {
	.pagination-wrapper .pagination-previous-page,
	.pagination-wrapper .pagination-page-selection-bottom,
	.pagination-wrapper .pagination-next-page {
		float: none;
		width: 100%;
		text-align: center;
		padding: 0;
	}
}

/* ---------------------------- */
/* CUSTOM  */

@media screen and (max-width: 375px) { /* phone only */

	/* RECIPES */

	.my-shopping-list-functions {
		flex-direction: column;
	}

	.print-list {
		margin-bottom: 10px;
	}

	.clear-list {
		flex-direction: row-reverse;
		align-self: flex-start;
	}

	.clear-list:after {
		margin-right: 6px;
		margin-left: 0;
	}
}

#products_module.responsive .items .item-wrapper .item .item-image {
	padding: 0;
	border: 0;
}

#products_module.responsive .items.grid-view .item-wrapper .item {
	border-top: 10px solid #333333;
	background-color: #fff;
	padding: 0;
}

#products_module.responsive .items .item-wrapper .item .item-description {
	padding: 10px;
	min-height: 230px;
	text-align: center !important;
}

#products_module.responsive .items .item-wrapper .item .item-prices {
	padding-top: 15px;
}

#products_module.responsive .items .item-wrapper .item .item-price {
	font-size: 1.25em;
	color: #c9353c;
}

#products_module.responsive .items.grid-view .item-wrapper .item .item-image a {
	height: inherit;
	line-height: inherit;
	padding-left: 0;
	padding-right: 0;
}

#s-sign-in-form input {
	width: 100%;
	margin-left: 10px;
}

#s-sign-in-form .cart-buttons-row {
	margin-top: 20px;
}

#s-sign-in fieldset {
	border: 0;
}

/* ACCOUNTS */

#account_module.form .form_subheading {
	border-bottom: 1px solid hsla(0, 1%, 47%, 0.5);
	padding-bottom: 14px;
	margin: 20px 0 14px;
	font-family: 'Roboto Slab', sans-serif;
	font-size: 2rem;
	line-height: 2.7rem;
	font-weight: 400;
	color: #2e2e2e;
}

#account_module.form .required_message span {
	background: 0 none;
}

#account_module.form .required_message span:before {
	content: "*";
	color: #eb0000;
	padding-right: 5px;
}

#account_module.form .form_container {
	border-spacing: 2px;
}

#account_module.form .form_container .login_field {
	width: 100%;
	max-width: 250px;
	margin-bottom: 15px;
}

#account_module.form .more_options ul {
	width: 100%;
	text-align: center;
	padding-left: 0;
}

#account_module.form .label {
	color: #4b4b4b;
	font-size: 0.875rem;
	text-transform: uppercase;
	font-weight: 500;
	margin-bottom: 5px;
}

#account_module.form .ui-form-label label,
#account_module.form tr .field label {
	font-weight: 400;
	text-transform: uppercase;
}

#account_module.form .form_container input[type="text"] {
	margin-bottom: 10px;
}

#account_module.form .form_container input[name="PhoneMobile"] {
	margin-bottom: 0;
}

#account_module.form .form_container input[name="PhoneMobile"] + div {
	margin-bottom: 10px;
}

#account_module.form .form_item .field input[type="password"] {
	margin-bottom: 10px;
}

#account_module.form .form_item .field input[disabled] {
	color: #777;
	cursor: not-allowed;
}

#account_module.form .form_buttons {
	text-align: left;
	margin-top: 15px;
}

#account_module.form tr .field {
	background: none;
	padding-left: 0 !important;
	display: block;
	width: calc(100% - 11px);
}

#account_module.form .form_item .label,
#account_module.form .form_item.required .label {
	display: block;
	width: 100%;
	text-align: left;
	padding: 3px 5px 0 0;
	vertical-align: top;
}

#account_module.form:not(.subscription-preferences) tr.required .label:after {
	content: "*";
	color: #eb0000;
	padding-left: 5px;
}

#account_module.form .more_options ul {
	text-align: left;
	list-style-type: none;
	margin: 20px auto 0;
}

@media (max-width: 479px) {
	#account_module.form .field input[type="text"],
	#account_module.form .field input[type="password"],
	#account_module.form .field select {
		width: 100%;
	}
}

/* ACCOUNTS FORGOT PASSWORD */

#account_module.form > .instructions + .form > form > .form_container + br {
	display: none;
}

/* ACCOUNTS ORDER HISTORY */

@media (max-width: 991px) {
	#account_module.entity #account_orderhistory .order_section td {
		display: block;
		width: 100% !important;
		text-align: left !important;
	}

	#account_module.entity #account_orderhistory .order_section td strong {
		display: block;
	}
}


#order-confirmation h2 {
	color: #000000;
	font-size: 1.75em;
	font-weight: 300;
	text-transform: none;
	margin-bottom: 5px;
}

#order-confirmation .ui-form-shipping-address-parent .columns,
#order-confirmation .ui-data-shipping-address,
#order-confirmation .ui-data-shipping-method-and-delivery-options,
#order-confirmation .ui-data-payments,
#order-confirmation .ui-data-addresses {
	float: none;
	width: 100%;
	margin-bottom: 10px;
}

#order-confirmation label {
	text-transform: none;
	font-size: 1rem;
	font-weight: 400;
}

@media (max-width: 479px) {
	#order-confirmation .ui-order-review table tr:last-child > td,
	#order-confirmation .ui-order-review table tr:nth-last-child(2) > td {
		display: block;
		width: 100% !important;
		border-bottom: 0 none !important;
		padding-bottom: 0;
	}

	#order-confirmation .ui-order-review table tr:last-child td,
	#order-confirmation .ui-order-review table tr:nth-last-child(2) td {
		text-align: left !important;
	}
}

/* ACCOUNTS EDIT ADDRESSES */

@media (max-width: 991px) {
	#account_module.entity #account_addresses .address_section tr td:first-of-type br {
		display: none;
	}

	#account_module.entity #account_addresses .address_section td {
		display: block;
		width: 100% !important;
		text-align: left !important;
	}
}

#account_module.form .ui-form-fields {
	padding-left: 0;
}


#account_module.form .ui-form-label {
	clear: both;
	height: auto;
	left: auto;
	overflow: auto;
	padding-right: 0;
	position: relative;
	text-align: left;
	top: auto;
	width: 100%;
}


#account_module.form .ui-form-input {
	clear: both;
	overflow: hidden;
	position: relative;
	width: 100%;
}

#account_module.form .ui-form-input #Phone {
	max-width: 213px;
}

#account_module.form .ui-form-input #PhoneExtension {
	max-width: 70px;
}

#account_module.form .ui-form-label label {
	color: #4d4d4d;
	font-family: inherit;
}

#account_module.form .ui-form-label label em {
	color: #eb0000;
	font-style: italic;
}

@media (max-width: 479px) {
	#account_module.form .ui-form-select > select,
	#account_module.form .ui-form-textarea > textarea,
	#account_module.form .ui-form-text > input[type="password"],
	#account_module.form .ui-form-text > input[type="text"] {
		width: calc(100% - 26px) !important;
	}
}

/* ECOMMERCE */

#products_module .pagination-wrapper {
	margin-bottom: 15px;
}

#products_module #SortBy {
	padding: 8px 12px;
}

#products_module.responsive .items .item-wrapper .item .item-description .cms_title {
	text-transform: uppercase;
}

#products_module.responsive .items .item-wrapper .item .item-description .metadata1.cms_title h3 {
	font-size: 1.3em;
	line-height: 1.85rem;
}

#products_module.responsive .item-sale-price {
	color: #c9353c !important;
	font-family: inherit;
}

#products_module.responsive .items .item-wrapper .item .item-prices {
	padding-top: 7px;
}

/* ECOMMERCE GRID VIEW */
#products_module.responsive .items.grid-view {
	display: flex;
	display: -webkit-flex;
	flex-wrap: wrap;
	-webkit-flex-wrap: wrap;
}

#products_module.responsive .items.grid-view .item-wrapper .item {
	height: calc(100% - 20px);
}

#products_module.responsive .items .item-wrapper .item .item-image img {
	width: 100%;
}

/* ECOMMERCE LIST VIEW*/

#products_module.responsive.cms_list .items.list-view .item-wrapper {
	border-bottom: 1px solid #ccc;
	padding-bottom: 1em;
	margin-bottom: 1em;
}

#products_module.responsive.cms_list .items .item-wrapper .item .item-description {
	text-align: left !important;
}

#products_module.responsive.cms_list .items .item-wrapper .item .item-description {
	min-height: 0;
	padding-right: 0;
	padding-left: 0;
}

@media (max-width: 479px) {
	#products_module.responsive.cms_list .items.list-view .item-wrapper {
		padding-bottom: 1.5em;
		margin-bottom: 1.5em;
	}

	#products_module.responsive.cms_list .items.list-view .item-wrapper .item {
		margin-bottom: 0;
	}

	#products_module.responsive.cms_list .items.list-view .item-wrapper .item .item-image {
		float: none;
		width: 100%;
		text-align: left;
		padding-left: 10px;
		padding-right: 10px;
	}

	#products_module.responsive.cms_list .items.list-view .item-wrapper .item .item-description {
		float: none;
		width: 100%;
		margin-left: 0;
		padding-bottom: 0;
	}

	#products_module.responsive.cms_list .item-short-description.with-margin-bottom {
		margin-bottom: 0;
	}
}

/* ECOMMERCE CUSTOMER REGISTRATION */

#s-customer-register .ui-form-panel {
	padding-right: 0 !important;
	padding-left: 0 !important;
}

#s-customer-register fieldset .ui-form-legend {
	padding-right: 0;
	padding-left: 0;
}

#s-customer-register fieldset .ui-form-legend h3,
#s-customer-register fieldset .ui-form-legend .ui-form-legend-text {
	margin-top: 1.5rem;
	margin-bottom: 0.7rem;
	font-family: 'Roboto Slab', sans-serif;
	font-size: 2rem;
	line-height: 2.7rem;
	font-weight: 400;
	color: #2e2e2e;
}

#s-customer-register .ui-form-fields {
	padding: 0;
}

#s-customer-register .ui-form-buttons {
	padding-left: 2px;
}

#s-customer-register .ui-form-label {
	clear: both;
	height: auto;
	left: auto;
	overflow: auto;
	padding-right: 0;
	position: relative;
	text-align: left;
	top: auto;
	width: 100%;
}

#s-customer-register .ui-form-input {
	clear: both;
	overflow: auto;
	position: relative;
	width: 100%;
}

#s-customer-register label {
	color: #4d4d4d;
	text-transform: uppercase;
}

#s-customer-register label em {
	color: #eb0000;
	font-style: italic;
}

#s-customer-register fieldset #ffPhone {
	margin-right: 7px;
}

#s-customer-register fieldset #ffPhoneExtension {
	width: 100px;
}

/* ECOMMERCE DETAILS VIEW */

#products_module.responsive .add_to_cart_form .quantity_row .product-quantity-label {
	width: 30%;
}

@media screen and (max-width: 480px) {
	#products_module.responsive .add_to_cart_form .quantity_row .product-quantity-label {
		width: 50%;
	}
}

@media screen and (min-width: 481px) and (max-width: 720px) {
	#products_module.responsive .add_to_cart_form .quantity_row .product-quantity-label {
		width: 40%;
	}
}

#products_module.responsive .add_to_cart_form .quantity_row .product-denomination-label {
	width: 18%;
}

@media screen and (max-width: 480px) {
	#products_module.responsive .add_to_cart_form .quantity_row .product-denomination-label {
		width: 45%;
	}
}

@media screen and (min-width: 481px) and (max-width: 720px) {
	#products_module.responsive .add_to_cart_form .quantity_row .product-denomination-label {
		width: 28%;
	}
}

#products_module.responsive .add_to_cart_form .quantity_row span.form_money_currency {
	width: 12%;
}

@media screen and (max-width: 480px) {
	#products_module.responsive .add_to_cart_form .quantity_row span.form_money_currency {
		width: 5%;
	}
}

#products_module.responsive .add_to_cart_form .contentbox_item.attribute-type-singleline br {
	display: none;
}

#products_module.responsive .add_to_cart_form .contentbox_item label {
	display: block;
}

.product-attribute-file-upload label {
	font-weight: 500;
}

#products_module.responsive .add_to_cart_form .attribute-type-date .attribute-datepicker {
	max-width: 100%;
}

#products_module.responsive .add_to_cart_form .attribute-type-date img {
	margin-bottom: 0 !important;
}

#products_module.responsive .attributeSelectionWithImages .attributeSelectDescription {
	font-weight: 500;
	color: #4b4b4b;
	text-transform: uppercase;
	font-size: 0.875rem;
}

#products_module.responsive .availability_box .available {
	color: #4b4b4b;
	font-family: inherit;
}

.contentbox_item .special-instructions,
.contentbox_item .accepted-formats {
	font-size: 0.875rem;
}

.contentbox_item .accepted-formats {
	display: inline-block;
	word-break: break-all;
}

#products_module.responsive .tab-item-content .product-review .product-review-author {
	font-size: 0.875rem;
	font-weight: 500;
}

#products_module.responsive .tab-item-content .product-review {
	padding-left: 0;
}

.content-col #products_module.responsive.cms_entity .product-details-tabs .tab-item img.star,
.content-col #products_module.responsive .tab-item-content .product-review img.star {
	display: inline !important;
	margin-bottom: 0 !important;
}

#products_module.responsive.cms_entity .tab-item-content .product-question p {
	font-weight: 500;
}

#products_module.responsive.cms_entity .tab-item-content .product-question-answer {
	margin-left: 0;
}

#products_module.responsive.cms_entity .tab-item-content .product-question-have-a-question {
	border-top: 1px solid #ccc;
	font-weight: 500;
	padding-top: 20px;
}

#products_module.responsive.cms_entity .related-items {
	background-color: #efefef;
	margin-left: -20px;
	margin-right: -20px;
	margin-bottom: -50px;
	padding-top: 10px;
}

#products_module.responsive.cms_entity .related-items .related-items-title {
	font-size: 1.25em;
	margin-top: 20px;
	text-transform: uppercase;
	font-weight: 300;
	line-height: 1.25em;
}

#products_module.responsive.cms_entity .product-details-info-wrapper .product-details-info {
	padding: 15px;
}

/* ECOMMERCE ASK QUESTION & WRITE A REVIEW FORMS */
#products_module.responsive.cms_form .ui-form-fields,
#products_module.responsive.cms_form .ui-form-buttons {
	padding-left: 0;
}

#products_module.responsive.cms_form .required-fields-information em,
#products_module.responsive.cms_form .ui-form-fields .ui-form-label label em,
#products_module.responsive.cms_form .preferred-contact-method-label.ui-form-label em,
#products_module.responsive.cms_form .ui-form-fields .ui-form-label span em {
	color: #eb0000;
	font-weight: 500;
}

#products_module.responsive.cms_form .ui-form-fields .ui-form-label label,
#products_module.responsive.cms_form .preferred-contact-method-label.ui-form-label,
#products_module.responsive.cms_form .ui-form-fields .ui-form-label span {
	color: #4b4b4b;
	font-weight: 500;
	margin-bottom: 5px;
	vertical-align: text-bottom;
	display: block;
	text-transform: uppercase;
	font-size: 0.875em;
}

#products_module.responsive.cms_form .ui-form-fields .ui-form-label {
	height: auto;
	position: relative;
	top: 0;
	left: 0;
	line-height: 1.125rem;
	padding: 0;
	text-align: left;
	width: 100%;
}

#products_module.responsive.cms_form .ui-form-fields fieldset {
	border: 0 none;
	margin: 0;
	padding: 0;
}

#products_module.responsive.cms_form .ui-form-fields .ui-form-field .ui-checkbox-label {
	font-weight: 400;
	text-transform: none;
	color: #4b4b4b;
}

#products_module.responsive.cms_form .ui-form-buttons > button:first-of-type {
	margin-left: 0;
}

/* ECOMMERCE SECURITY INFORMATION PAGE */
#security-information-graphic {
	margin-bottom: 20px;
}

@media (max-width: 767px) {
	#security-information-graphic {
		float: none;
		margin-left: 0;
		text-align: left;
	}
}

/* ECOMMERCE CART */
.gift-options-container .ui-form-checkbox {
	width: auto;
}

.gift-options-container .ui-form-checkbox label {
	padding-left: 5px;
}

#s-cart .w-col.s-summary #s-shipping-disclaimer * {
	font-size: 0.78rem;
	line-height: 1.25rem;
}

#s-cart .w-col.s-summary #s-estimate-shipping button {
	height: 38px;
}

#s-cart #s-promotions .s-button,
#s-cart #s-promotions #s-button {
	padding: 10px 20px;
	margin-left: 0;
	border: 1px solid #999999 !important;
	border-radius: 2px;
	text-transform: uppercase;
}

#s-cart .w-col.s-summary #s-estimate-shipping #postalcode-button {
	margin-top: 0;
	border: 1px solid #999999 !important;
}

#s-cart .w-col.s-summary #s-buttons #s-continue-button {
	padding-left: 11px !important;
	padding-right: 11px !important;
	border: 1px solid #999999 !important;
}

#s-cart #s-promotions .s-button:hover,
#s-cart #s-promotions #s-button:hover,
#s-cart .w-col.s-summary #s-estimate-shipping #postalcode-button:hover,
#s-cart .w-col.s-summary #s-buttons #s-continue-button:hover {
	border: 1px solid #c2182d !important;
}

/* ECOMMERCE LOGIN/REGISTER OR PROCCEED AS GUEST */
#s-customer #s-sign-in .ui-form-label {
	padding-bottom: 0;
	text-transform: uppercase;
}

#s-customer #s-sign-in #s-returning-customer label,
#s-customer #s-sign-in #s-new-customer label {
	font-size: 1rem;
	font-weight: 400;
}

#s-customer #s-sign-in #s-sign-in-form input {
	margin-left: 0;
}

#s-customer #s-sign-in .ui-form-label {
	height: auto;
}

#s-customer #s-sign-in #s-sign-in-form .s-fields .ui-form-label label {
	color: #4b4b4b;
	font-size: 0.875em;
	font-weight: 500;
	line-height: 1.075rem;
}

#s-customer #s-sign-in #s-sign-in-form .s-fields .ui-form-label label em {
	color: #eb0000;
	font-style: italic;
}

#s-customer #s-sign-in #s-sign-in-form .cart-buttons-row {
	margin-top: 0;
}

#s-customer #s-sign-in #s-sign-in-form .s-button {
	margin-left: 0;
}

#s-customer #s-register .s-fields label {
	color: #4b4b4b;
	font-size: 0.875em;
	font-weight: 500;
	line-height: 1.075rem;
}

#s-customer #s-register .s-fields label em {
	color: #eb0000;
	font-style: italic;
}

#s-customer #s-register .s-button {
	margin-left: 0;
	max-width: 348px;
}

#s-customer #s-guest .bypassProcessing label {
	font-size: 1.2em;
	font-weight: 300;
	text-transform: none;
	margin-bottom: 15px;
	display: block;
}

#s-customer #s-guest #proceed_as_guest {
	border: 1px solid #999999 !important;
}

#s-customer #s-guest #proceed_as_guest:hover {
	border: 1px solid #c2182d !important;
}

/* ECOMEMRCE CHECKOUT */

#s-checkout #s-steps-bar-small ul li a,
#s-checkout #s-steps-bar ul li a {
	box-shadow: none;
}

#s-checkout #s-steps-bar-small ul li a:hover,
#s-checkout #s-steps-bar ul li a:hover {
	background-color: inherit;
	cursor: pointer;
}

#s-checkout .ui-form-label span,
#s-checkout .ui-form-label label {
	color: #4b4b4b;
	font-size: 0.875em;
	line-height: 1.075rem;
	font-weight: 500;
	text-transform: uppercase;
}

#s-checkout .ui-form-label label em {
	color: #eb0000;
	font-style: italic;
}

#s-checkout #s-shipping-method-heading,
#s-checkout h3.ui-form-field-billing-address {
	margin-top: 25px;
}

#s-checkout .ui-form-fields {
	padding: 0;
}

#s-checkout .ui-form-input {
	clear: both;
	overflow: hidden;
	position: relative;
	width: 100%;
}

#s-checkout .ui-form-label {
	clear: both;
	height: auto;
	left: auto;
	overflow: auto;
	padding-right: 0;
	position: relative;
	text-align: left;
	top: auto;
	width: 100%;
}

#s-checkout #s-summary .ui-form-label,
#s-order-confirmation #s-summary .ui-form-label {
	height: auto;
}

#s-checkout .ui-checkbox-label {
	text-transform: none;
	font-size: 0.8571rem;
	font-weight: 400;
}

#s-checkout .form-shipping-info .ui-form-buttons,
#s-checkout .form-payment-info .ui-form-buttons {
	padding: 0 !important;
}

#s-checkout .form-shipping-info .ui-form-buttons button,
#s-checkout .form-payment-info .ui-form-buttons button {
	margin-left: 0;
}

#s-checkout .ui-form-buttons.ui-form-field-place-order {
	padding-left: 0;
}

#s-checkout .ship-to-this-address-btn {
	border: 1px solid #999999 !important;
}

#s-checkout .ship-to-this-address-btn:hover {
	border: 1px solid #c2182d !important;
}

#s-checkout #ffShippingEmail .ui-form-note small {
	margin-top: 7px;
	line-height: 18px;
	display: block;
}

#s-checkout fieldset #ffApplyButton.ui-form-field.inline {
	margin-left: 0;
}

#ffPlaceOrderButton.ui-form-field {
	margin-right: 16px;
	margin-left: 16px;
}

@media (max-width: 991px) {
	#s-checkout fieldset {
		border: 0 none;
	}

	#ffPlaceOrderButton.ui-form-field {
		margin-right: 0;
		margin-left: 0;
	}
}

/* EVENTS */

.content .contentbox_item_image {
	width: 20px;
}

#events_module #fullcalendar-controls #categories {
	float: none;
}

#events_module.cms_list .event-current-day #event-calendar-link {
	margin-top: 8px;
}

#events_module.cms_list .cms_title {
	font-size: 1.125rem;
}

#events_module.cms_list .cms_list_item .cms_title h3 {
	margin-top: 0;
}

#events_module.cms_list div.events_module_calendar .fc-view-container .fc-event {
	border-bottom: 1px solid #000000;
	box-shadow: none;
}

#events_module .category-wrapper {
	display: inline-block;
}

#events_module .event-category-label {
	display: inline-block;
	margin-right: 7px;
	margin-bottom: 0;
}

#events_module.cms_form .cms_label {
	background-color: transparent;
	vertical-align: top;
}

#events_module.cms_form .cms_label label,
#events_module.cms_form .cms_label {
	font-weight: 500;
	color: #4b4b4b;
	text-transform: uppercase;
	font-size: 0.875em;
}

#events_module.cms_form .cms_required {
	background-color: transparent;
	background-position: center 5px;
}

#events_module.cms_form .cms_non_required {
	background-color: transparent;
	font-family: inherit;
}

#events_module.cms_form .cms_field {
	background-color: transparent;
	font-family: inherit;
}

#events_module.cms_form .cms_field textarea {
	resize: vertical;
}

#events_module.cms_form .cms_td_answers label {
	font-weight: 400;
	text-transform: none;
}

#events_module.cms_list .cms_list_item .cms_date {
	font-size: 1rem;
	margin-top: 10px;
}

#events_module.cms_list .back-link {
	display: none;
}

#events_module.cms_entity .cms_contact_name_label,
#events_module.cms_entity .cms_contact_phone_label,
#events_module.cms_entity .cms_location_label {
	font-weight: 500;
}

#events_module.cms_entity > .cms_content > .cms_divider:first-of-type {
	display: none;
}

#events_module.cms_entity .events-detail-date {
	margin-top: 0;
}

#events_module.cms_form .cms_label {
	width: auto;
	display: inline;
	text-align: left;
}

#events_module.cms_form .cms_field {
	width: 100%;
	display: block;
	padding-left: 0;
}

#events_module.cms_form .cms_required:before {
	content: "*";
	color: #eb0000;
}

#events_module.cms_form .cms_required {
	width: 15px;
	display: inline-block;
	background: 0 none;
}

#events_module.cms_form .cms_non_required {
	display: inline-block;
}

#events_module.cms_form .cms_field input[type="text"],
#events_module.cms_form .cms_field textarea {
	width: calc(100% - 26px) !important;
	max-width: 300px !important;
}

#events_module.cms_form .cms_info_req {
	float: none;
	margin-bottom: 10px;
	text-align: right;
}

#events_module.cms_form .cms_info_req:before {
	content: "*";
	color: #eb0000;
	padding-right: 5px;
}

#events_module.cms_form .cms_info_req img {
	display: none;
}

#events_module.cms_form form table:last-of-type td:nth-child(1),
#events_module.cms_form form table:last-of-type td:nth-child(2) {
	display: none;
}

#events_module.cms_form form table:last-of-type td:nth-child(3) {
	width: 100% !important;
}

@media (max-width: 767px) {
	#events_module.cms_list .event-current-day .cms_title,
	#events_module.cms_list .event-current-day #event-calendar-link {
		float: none;
		display: block;
	}
}

/* FAQs */

#faqs_module .cms_list_item ul,
#faqs_module .cms_list_subitem ul {
	color: currentColor;
	list-style-type: disc;
}

#faqs_module.cms_list .cms_list_item:first-of-type {
	padding-top: 0;
}

#faqs_module.cms_list .cms_list_item:first-of-type .cms_title h3,
#faqs_module.cms_list .cms_list_subitem:first-of-type .cms_title h3 {
	margin-top: 0;
}

#faqs_module.cms_list .cms_list_item .cms_title h3 {
	margin-bottom: 0;
}

.box.tools .content .contentbox_item form > p {
	margin-bottom: 0;
}

#faqs_module.cms_list .cms_list_item .description,
#faqs_module.cms_list .cms_list_subitem .description {
	list-style: none;
	padding-top: 0;
}

/* NEWSLETTERS */

#newsletters_module.form .required_message {
	margin-bottom: 20px;
}

#newsletters_module.form .required_message span {
	background: 0 none;
	padding-left: 0;
}

#newsletters_module.form .required_message span:before {
	content: "*";
	color: #eb0000;
	padding-right: 5px;
}

#newsletters_module.form .form_item td {
	padding-bottom: 15px;
}

#newsletters_module.form .form_item .label,
#newsletters_module.form .form_item.required .label {
	text-transform: uppercase;
	font-size: 0.875rem;
	color: #4b4b4b;
	font-weight: 500;
}

#newsletters_module.form .form_item.required .field {
	background: 0 none;
	display: block;
	width: 100%;
	padding-left: 0;
	max-width: 400px;
}

#newsletters_module.form .form_item.required .label {
	display: block;
	width: 100%;
	text-align: left;
	margin-bottom: 5px;
}

#newsletters_module.form .form_item.required .label::after {
	padding-left: 5px;
	content: "*";
	color: #eb0000;
}

#newsletters_module.form .form_buttons {
	text-align: left;
}

/* PHOTO ALBUMS */

@media (max-width: 991px) {
	#photoalbums_module td {
		display: block;
		width: 100% !important;
	}
}

@media (max-width: 991px) and (min-width: 768px) {
	#photoalbums_module.cms_list .cms_list_item .cms_image {
		padding-bottom: 15px;
	}
}

@media (max-width: 767px) {
	.content-col #photoalbums_module.cms_entity img {
		display: inline;
	}
}

/* POSTS */

.content-col > .search {
	margin-bottom: 20px;
}

.content-col > .search h2 {
	margin-bottom: 5px;
}

.content-col > .search .search-criteria {
	font-weight: 700;
}

.collection.posts-collection > .node {
	border-top: 1px solid #cccccc;
	margin: 1.5em 0 0;
	padding: 1.5em 0 0;
}

.collection.posts-collection > .node:first-child {
	border-top: 0 none;
	margin-top: 0;
	padding-top: 0;
}

.collection.posts-collection .field.posts-field h2 {
	margin-top: 0;
	margin-bottom: 10px;
}

.posts-node .posts-comments label,
#posts_module.form label {
	color: #4b4b4b;
	font-family: inherit;
}

.posts-node .post-comment {
	border-bottom: 1px solid #cccccc;
	padding: 20px 5px;
}

.posts-node .post-comment-author {
	font-size: 0.825rem;
}

.posts-node .posts-comments fieldset.ui-form-fieldset {
	border: 0 none;
	margin: 0;
	padding: 0;
}

.posts-node .posts-comments .ui-form-fieldset legend.ui-form-legend {
	margin-top: 20px;
	text-transform: none;
	color: #fff;
	font-size: 1rem;
}

#posts_module.form .ui-form-buttons {
	margin-top: 0;
	padding-left: 0;
	padding-top: 0;
	text-align: left;
}

#posts_module.form .form_item .ui-form-label,
#posts_module.form .form_item .required .ui-form-label {
	text-align: left;
}

.posts-blocks.posts-blocks-rss-feeds .posts-blocks-rss-feed .posts-blocks-rss-feed-link {
	text-decoration: none;
	border-bottom: 0 solid transparent;
}

.posts-blocks-popular-post,
.posts-blocks-author-name,
.posts-blocks-category,
.posts-blocks-posts {
	margin-bottom: 10px;
}

.posts-blocks.posts-blocks-tags a:hover {
	text-decoration: none;
	border-bottom: 0 solid transparent;
}

.posts-blocks.posts-blocks-searches .posts-blocks-search-form input.posts-blocks-search-field[type="text"] {
	border-radius: 0;
	height: 24px;
	width: calc(100% - 62px);
}

#posts_module.form .ui-form-panel {
	padding: 0 !important;
}

.posts-node .posts-comments .ui-form-fieldset,
#posts_module.form .ui-form-fieldset {
	padding: 0;
	margin-left: 0;
}

.posts-node .posts-comments .ui-form-fields,
#posts_module.form .ui-form-fields {
	padding-left: 0;
}

.ui-form-label {
	clear: both;
	height: auto;
	left: auto;
	overflow: auto;
	padding-right: 0;
	position: relative;
	text-align: left;
	top: auto;
	width: 100%;
}

.ui-form-input {
	clear: both;
	overflow: auto;
	position: relative;
	width: 100%;
}

#posts_module.form .ui-form-field {
	overflow: hidden;
}

#posts_module.form .form_item .required .ui-form-input input[type="text"],
.posts-node .posts-comments #post-add-comment .ui-form-fields .ui-form-input input,
.posts-node .posts-comments #post-add-comment .ui-form-fields .ui-form-textarea textarea {
	width: calc(100% - 26px);
	max-width: 265px;
}

.posts-node .posts-comments #post-add-comment .ui-form-buttons .cms_form_button.primary {
	margin-left: 0;
}


/* RESOURCES - MODULE DOCUMENTS */

.moduleDocuments-rss-feed {
	padding-top: 15px;
}

.content-wrapper a.moduleDocuments-rss-feed-link {
	box-shadow: none;
}

.content-wrapper a[href*="?tag="] {
	box-shadow: none;
}

#moduleDocuments_module .folder .icon {
	margin-bottom: 3px;
}

#moduleDocuments_module .cms_items .cms_description {
	background-position: left 0;
}

#moduleDocuments_module .cms_items .cms_description h4 {
	margin-top: 0;
}

/* SUBMISSION FORMS */

#submissionforms_module.cms_form .checkbox_wrapper .item .field input[type="checkbox"],
#submissionforms_module.cms_form .radio_wrapper .item .field input[type="radio"] {
	margin: 4px 0 0;
}

#submissionforms_module.cms_form .checkbox_wrapper .item label,
#submissionforms_module.cms_form .radio_wrapper .item label {
	text-transform: none;
	font-weight: 400;
}

#submissionforms_module.cms_form .checkbox_wrapper .item .field_label,
#submissionforms_module.cms_form .radio_wrapper .item .field_label {
	padding-left: 12px;
}

#submissionforms_module.cms_form .cms_label div,
#submissionforms_module.cms_form .cms_label label,
#submissionforms_module.cms_form .cms_label .fake_label {
	font-size: 0.875em;
	font-weight: 500;
	color: #4b4b4b;
	text-transform: uppercase;
}

#submissionforms_module.cms_form .cms_steps_wrapper {
	margin-top: 10px;
}

#submissionforms_module.cms_form .cms_steps_wrapper .cms_step,
#submissionforms_module.cms_form .cms_steps_wrapper .cms_step_first,
#submissionforms_module.cms_form .cms_steps_wrapper .cms_step_last {
	height: auto;
}

#submissionforms_module.cms_form .cms_steps_wrapper .cms_step span,
#submissionforms_module.cms_form .cms_steps_wrapper .cms_step_first span,
#submissionforms_module.cms_form .cms_steps_wrapper .cms_step_last span {
	padding: 1px 27px;
}

#submissionforms_module.cms_form .cms_steps_wrapper span a {
	border-bottom: 0 none;
}

#submissionforms_module.cms_form form {
	margin-top: 15px;
}

#submissionforms_module .cms_hint {
	display: block;
}

#submissionforms_module.cms_form .cms_field .ui-datepicker-trigger {
	vertical-align: text-top;
}

#submissionforms_module.cms_form .sigWrapper.current,
#submissionforms_module.cms_form .sigPad input {
	display: block !important;
}

.pad {
	border-right: 1px solid #cccccc;
	border-bottom: 1px solid #cccccc;
}

#submissionforms_module.cms_form .sigPad .sigNav a {
	box-shadow: none;
}

.sigNav a,
.sigNav a:link,
.sigNav a:visited {
	color: #6c6c6c !important;
	font-weight: 400 !important;
}

#submissionforms_module.cms_form .cms_field textarea[cols="60"] {
	width: calc(100% - 26px);
}

#submissionforms_module.cms_form .cms_field input[size="60"] {
	width: calc(100% - 26px);
}

#submissionforms_module.cms_form .cms_field input.hasDatepicker[size="60"] {
	width: calc(100% - 46px);
}

#submissionforms_module.cms_form .cms_label {
	display: block;
	text-align: left;
	width: 100%;
}


#submissionforms_module.cms_form .cms_field {
	display: block;
	padding-left: 0;
	width: 100%;
}

.content-col #submissionforms_module.cms_form .cms_field .ui-datepicker-trigger {
	display: inline;
}

#submissionforms_module.cms_form .cms_buttons {
	text-align: left !important;
	padding-left: 5px !important;
}

#submissionforms_module tr[data-required="true"] .cms_label::after {
	content: none;
}

.module-forms.confirmation td {
	display: block;
	width: 100%;
}

#submissionforms_module.cms_form .required_information em {
	color: #db0000;
	font-style: italic;
}

#submissionforms_module tr[data-required="true"] .cms_label div::after,
#submissionforms_module tr[data-required="true"] .cms_label label::after {
	content: "*";
	color: #db0000;
	padding-left: 5px;
}

#submissionforms_module.cms_form .cms_required {
	display: none;
}

#submissionforms_module.cms_form .cms_buttons {
	margin-left: 0;
	padding-left: 0 !important;
}

.module-forms.confirmation .step-title {
	margin-top: 1.5rem;
	margin-bottom: 0.7rem;
	font-family: 'Roboto Slab', sans-serif;
	font-size: 2rem;
	line-height: 2.7rem;
	font-weight: 400;
}

@media (max-width: 767px) {
	#submissionforms_module.cms_form input[type="text"]:not(.hasDatepicker):not(.date-field),
	#submissionforms_module.cms_form input[type="file"],
	#submissionforms_module.cms_form textarea,
	#submissionforms_module.cms_form select {
		width: calc(100% - 26px);
	}
}

@media (max-width: 500px) {
	#submissionforms_module.cms_form .checkbox_wrapper .item .field_label,
	#submissionforms_module.cms_form .radio_wrapper .item .field_label {
		padding-left: 16px;
	}
}

/* TESTIMONIALS OK */

/* ACCOUNTS */

#account_module.form form[action*="/log-in"] .form_container {
	margin: 0 auto;
	text-align: center;
	width: auto;
}

#account_module.form form[action*="/log-in"] .form_item .label,
#account_module.form form[action*="/log-in"] .form_item.required .label {
	text-align: center;
}

#account_module.form form[action*="/log-in"] .form_buttons {
	margin: 0 auto;
	text-align: center;
	width: auto;
}

#account_module.form form[action*="/log-in"] + .more_options ul {
	text-align: center;
}

/* SEARCH */

.search-node + .search-node {
	margin-top: 20px;
	padding-top: 20px;
}

.search-node .search-field {
	max-width: 100%;
	width: 100%;
}

/* OTHER */

.fly-out-menu-container .fly-out-menu-button .selection.js-selection a {
	box-shadow: none;
}

.fly-out-menu-container .fly-out-menu-button .selection.js-selection a:hover {
	background-color: transparent;
	cursor: pointer;
}

@media screen and (max-width: 720px) {

	#products_module.responsive.cms_entity .product-details-info-wrapper .product-details-info {
		padding: 15px;
	}

	#products_module.responsive .add_to_cart_form .cart-estimate-button {
		margin: 0 auto;
	}
}

@media (max-width: 39.9375em) {

	.tablesaw-cell-content {
		font-size: 0.8em;
	}
}

.module-ecommerce-cart .module-intro-content {
	background: #ffffff;
	padding: 0;
	margin-bottom: 0;
}

@media screen and (max-width: 991px) {

	.module-ecommerce-cart .module-intro-content {
		margin: 0 10px 0 10px;
	}
}

.module-ecommerce-cart .module-intro-content h2 {
	margin-bottom: 0;
	padding-top: 20px;
}

.module-ecommerce-cart .module-intro-content p {
	padding: 0 20px 20px 20px;
}

.inside-page-wrapper .card.full-size-image {
	position: relative;
}

.inside-page-wrapper .card.full-size-image a {
	background-position: center center;
	background-size: cover;
	display: block;
	height: 100%;
	left: 0;
	position: absolute;
	text-indent: -999999px;
	top: 0;
	width: 100%;
}


#shipping-addresses .s-address-row-right .button,
#shipping-addresses .s-address-row-right .primary,
#shipping-addresses .s-address-row-right .secondary,
#shipping-addresses .s-address-row-right .tertiary,
#shipping-addresses .s-address-row-right button[type='submit'] {
	padding-top: 0 !important;
	padding-bottom: 0 !important;
}

#shipping-addresses .s-address-row-right button[type='submit'] {
	margin-top: 3px;
}

#shipping-addresses .ui-form-fields,
#shipping-addresses .ui-form-buttons {
	padding: 0 !important;
}

/* Expand/Collapse */
.collapsed {
	display: none;
}

.show-more-less {
	padding-top: 5px;
	padding-bottom: 5px;
	text-align: right;
}

/* Featured Cards */
.cards.featured-cards {
	flex-wrap: nowrap;
}

.cards.featured-cards .card {
	flex: 1 1 23%;
}


span.form-label-right-margin {
	margin-right: 5px;
	color: #4b4b4b;
	vertical-align: text-bottom;
	font-size: 0.875em;
	font-weight: 500;
	text-transform: uppercase;
	line-height: 1.35rem;
}

/* embedded */
.embedded .inside-page-wrapper {
	padding-top: 0;
}

.embedded .main-content-wrapper {
	margin-top: 0;
}

.embedded .breadcrumbs-wrapper {
	display: none;
}

.embedded .inside-page-wrapper .card {
	flex: 0 1 48%;
}

.embedded .inside-page-wrapper .save:before,
.embedded .inside-page-wrapper .prep-time:before,
.embedded .inside-page-wrapper .delete:before {
	margin-right: 5px;
}

.embedded .inside-page-wrapper .save span:first-child,
.embedded .inside-page-wrapper .prep-time span:first-child,
.embedded .inside-page-wrapper .delete span:first-child {
	font-size: 0.9em;
	max-width: 66%;
	text-align: left;
}

.embedded .inside-page-wrapper .pagination-wrapper {
	border-bottom: none;
	flex-direction: row;
	padding-bottom: 0;
	white-space: nowrap;
}

/* Begin Diversity and Inclusion styles */
#diversity-container {
	display: flex;
	flex-direction: column;
}

#partners-and-testimonials {
	display: flex;
	justify-content: space-between;
	margin-top: 28px;
	margin-bottom: 28px;
}

#diversity-testimonials .testimonials-title {
	margin-top: 0px;
	line-height: 2.1rem;
	text-align: center;
}

#diversity-testimonials #testimonials_module_contentbox #testimonials-content,
#diversity-testimonials #testimonials_module_contentbox .testimonials-content {
	width: 85%;
}

#diversity-partners {
	width: 48%;
}

#diversity-testimonials {
	width: 48%;
}

#diversity-slideshow {
	display: flex;
	justify-content: center;
}

#diversity-slideshow .arrow-icon {
	font-size: 2rem;
}

#diversity-slideshow .slideshow-arrow {
	opacity: 1;
	color: #fff;
	text-shadow: 0 0 8px #cf0a2c;
}

#diversity-slideshow .slideshow-arrow:hover {
	color: #d895a1;
}

#diversity-slideshow .slideshow-arrow.left-arrow {
	left: 20px;
}

#diversity-slideshow .slideshow-arrow.right-arrow {
	right: 20px;
}

.diversity-content {
	padding: 2.5rem 2rem;
	border: 1px solid #ccc;
	border-radius: 5px;
}

.diversity-content p {
	margin: 0;
}

.bg-pale-pink {
	background-color: #fff;
}

.bg-pinkish-red {
	background-color: #d8263c;
}

.bg-pinkish-red,
.bg-pinkish-red p {
	color: white;
}

#lead-with-love-page #partners_module {
	display: grid;
	grid-template-columns: 1fr;
	grid-gap: 1rem 1rem;
	align-items: center;
}

#lead-with-love-page #partners_module.homepage_contentbox.left-align-images {
	grid-template-columns: 1fr;
}

#lead-with-love-page #partners_module.homepage_contentbox.left-align-images .cms_list_item {
	display: flex;
	align-items: center;
	justify-content: center;
	width: 100%;
}

#lead-with-love-page #partners_module.homepage_contentbox.left-align-images .cms_list_item .partner_image_container {
	margin-right: 10px;
	width: 40%;
	display: flex;
	align-items: center;
	justify-content: center;
}

#lead-with-love-page #partners_module.homepage_contentbox.left-align-images .cms_list_item .cms_title {
	width: 60%;
}

#lead-with-love-page .cms_title {
	text-align: center;
	margin-top: 5px;
}

#lead-with-love-page .cms_list_item {
	justify-self: center;
}

#partners_footer {
	float: right;
	margin-top: 20px;
}

#diversity-testimonials .author-name {
	margin-top: 1rem;
	justify-content: flex-start;
	font-size: 1rem;
	line-height: 1.3rem;
}

#diversity-testimonials .company {
	font-size: 0.9rem;
	line-height: 1rem;
}

@media screen and (max-width: 991px) {
	#diversity-main {
		margin-top: 1rem !important;
	}

	#partners-and-testimonials {
		flex-direction: column;
	}

	#diversity-partners {
		width: 100%;
		margin-bottom: 28px;
	}

	#diversity-testimonials {
		width: 100%;
	}

	#lead-with-love-page #partners_module {
		grid-template-columns: 1fr 1fr;
	}
}

@media screen and (max-width: 767px) {
	#lead-with-love-page #partners_module {
		grid-template-columns: 1fr;
	}
}

@media screen and (max-width: 479px) {
	#diversity-main,
	#diversity-testimonials {
		text-align: center;
	}

	#diversity-testimonials .author-name {
		margin-top: 1rem;
		justify-content: center;
	}

	#lead-with-love-page #partners_module {
		display: flex;
		flex-direction: column;
		align-items: center;
	}
}

/* End Diversity and Inclusion styles */

/* CSS Grid Code */
.grid {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	margin-right: -1%;
	margin-bottom: 20px;
	-webkit-flex-wrap: wrap;
	-ms-flex-wrap: wrap;
	flex-wrap: wrap;
	flex: 0 0 100%;
}

.grid-box {
	margin-right: 1%;
	margin-bottom: 15px;
	padding: 20px;
	-webkit-box-flex: 0;
	-webkit-flex: 0 auto;
	-ms-flex: 0 auto;
	flex: 0 auto;
	box-sizing: border-box;
}

.grid-box.half {
	width: 49%;
}

.grid-box.third {
	width: 32.33%;
}

.grid-box.quarter {
	width: 24%;
}

.grid-box.fifth {
	width: 19%;
}

.grid-box.twothirds {
	width: 65.66%;
}

.grid-box.center {
	text-align: center;
}

.grid-box.center p {
	margin: 0 auto;
	max-width: 400px;
}

.grid-box img {
	max-width: 100%;
	height: auto;
}

@media (max-width: 991px) {
	.grid-box.quarter {
		width: 49%;
	}

	.grid-box.fifth {
		width: 49%;
	}
}

@media (max-width: 767px) {
	.grid-box.half {
		width: 100%;
	}

	.grid {
		margin-left: -20px;
		margin-right: calc(-20px + 1%);
	}

	.grid-box.twothirds {
		width: 100%;
	}

	.grid-box.third {
		width: 100%;
	}

	.grid-box-1 {
		order: 2;
	}

	.grid-box-2 {
		order: 1;
	}
}

@media (max-width: 630px) {
	.grid-box.third {
		order: -1;
	}
}

@media (max-width: 479px) {
	.grid-box {
		margin-right: 0%;
	}

	.grid-box.half {
		width: 100%;
	}

	.grid-box.third {
		width: 100%;
	}

	.grid-box.quarter {
		width: 100%;
	}

	.grid-box.fifth {
		width: 100%;
	}
}


/* Lead with Love New */
#lead-with-love-page-new .inside-page-banner-section.banner-image-1 {
	background-image: url('/assets/images/lead-with-love-banner.webp');
}

#lead-with-love-page-new .inside-content-column.no-sidebars-content-column {
	margin-left: 0px;
	margin-right: 0px;
}

#lead-with-love-page-new .main-content-wrapper-copy {
	float: left;
	width: 100%;
	margin-top: 2rem;
	padding-top: 0;
	padding-bottom: 1rem;
}

#lead-with-love-page-new .intro-paragraph {
	color: #2e2e2e;
	margin-bottom: 1.5rem;
	font-size: 1.25rem;
	line-height: 1.95rem;
}

#lead-with-love-page-new .job-block.option2 {
	border: 1px solid rgba(121, 118, 118, 0.5);
	border-top-style: none;
	flex-direction: column;
	min-width: auto;
	margin-top: 2rem;
	margin-right: 0;
	display: flex;
	justify-content: space-between;
	align-items: center;
	width: 100%;
	height: 100%;
	min-height: auto;
}

#lead-with-love-page-new .red-top-border {
	background-color: #c9353c;
	align-items: flex-start;
	width: 100%;
	height: 10px;
	margin-bottom: 2rem;
	display: flex;
}

#lead-with-love-page-new .job-text-block {
	border-bottom: 1px #000;
	flex-flow: column;
	justify-content: flex-start;
	align-items: stretch;
	height: 100%;
	padding-left: 1rem;
	padding-right: 1rem;
	display: block;
}

#lead-with-love-page-new .job-button-block {
	border-top: 1px solid rgba(121, 118, 118, 0.5);
	flex-direction: column;
	justify-content: center;
	align-items: center;
	width: 100%;
	margin-top: 1rem;
	display: flex;
}

#lead-with-love-page-new .heading.card-title {
	text-align: center;
	font-size: 1.55rem;
	font-weight: 500;
	line-height: 2rem;
}

#lead-with-love-page-new .text-block {
	text-align: center;
	font-weight: 500;
}

#lead-with-love-page-new .cbtext p {
	font-family: inherit;
	line-height: inherit;
	font-weight: inherit;
	color: inherit;
	font-size: inherit;
}

#lead-with-love-page-new .button {
	border: 1px solid #d8263c;
	background-color: #d8263c;
	color: #fff;
	text-align: center;
	border-radius: 2px;
	min-width: 100px;
	margin-top: 1rem;
	margin-bottom: 1.5rem;
	margin-right: 0;
	padding: 0.5625rem 1rem;
	font-weight: 600;
	line-height: 1rem;
	text-decoration: none;
	transition: all 0.2s;
	display: inline-block;
	position: static;
}

#lead-with-love-page-new .button:hover {
	border-color: #ad1e30;
	background-color: #ad1e30;
}

#lead-with-love-page-new .cbtext.red {
	color: #b22222;
}

#lead-with-love-page-new .cbtext a,
#lead-with-love-page-new .cbtext a:hover {
	color: #b22222;
	text-decoration: underline;
	box-shadow: none;
	border-bottom: 0px;
	background-color: transparent;
}

#lead-with-love-page-new .cbtext p:last-child {
	margin-bottom: 0px;
}

#lead-with-love-page-new .spacer40 {
	width: 100%;
	height: 40px;
	margin-top: 20px;
}

#lead-with-love-page-new .job-block.option2 {
	justify-content: space-between;
	align-items: center;
	width: 100%;
	height: 100%;
	min-height: auto;
}

#lead-with-love-page-new .job-block.option2.banner {
	background-position: 50%;
	background-repeat: no-repeat;
	background-size: cover;
	height: auto;
}

#lead-with-love-page-new .job-content-wrapper {
	grid-column-gap: 12px;
	grid-row-gap: 16px;
	flex-flow: column wrap;
	grid-template-rows: auto auto;
	grid-template-columns: 1fr 1fr 1fr;
	grid-auto-columns: 1fr;
	justify-content: space-between;
	align-items: center;
	width: auto;
	display: grid;
}

#lead-with-love-page-new .job-text-block-2 {
	border-bottom: 1px #000;
	width: auto;
	padding-left: 0%;
	padding-right: 0%;
}

#lead-with-love-page-new .tran-bg {
	background-color: rgba(255, 255, 255, 0.7);
	justify-content: center;
	width: auto;
	margin-top: 40px;
	margin-bottom: 40px;
	display: flex;
}

#lead-with-love-page-new .text-block-2 {
	border: 2px solid #b22222;
	color: #b22222;
	background-color: rgba(255, 255, 255, 0.7);
	border-radius: 4px;
	width: auto;
	margin: 20px;
	padding: 60px 40px;
	font-family: Roboto Slab, sans-serif;
	font-size: 1.5rem;
	font-weight: 400;
}

#lead-with-love-page-new .cbtext h2 {
	text-transform: uppercase;
	margin-top: 0;
	margin-bottom: 15px;
	font-family: Montserrat, sans-serif;
	font-size: 1.55rem;
	font-weight: 500;
	line-height: 2rem;
}

#lead-with-love-page-new .intro-paragraph.cbtext h2 {
	margin-top: 0;
	margin-bottom: 0.7rem;
	font-family: Roboto Slab, sans-serif;
	font-size: 2rem;
	font-weight: 400;
	line-height: 2.7rem;
	text-transform: none;
}

#lead-with-love-page-new .red-top-border-2 {
	background-color: #c9353c;
	width: 100%;
	height: 10px;
	margin-bottom: 1rem;
}

#lead-with-love-page-new .rotator {
	box-shadow: none;
}

#lead-with-love-page-new .slide-lead {
	width: 277px;
	height: 185px;
	background-size: cover;
	background-position: 50% 50%;
	margin-right: 5px;
	margin-left: 5px;
}

#lead-with-love-page-new .slick-dots {
	margin-top: 12px;
}

#lead-with-love-page-new .slick-dots li {
	background-color: #d6d6d6;
}

#lead-with-love-page-new .slick-dots li.slick-active {
	background-color: #869791;
}

#lead-with-love-page-new .uui-page-padding-6 {
	padding-left: 2rem;
	padding-right: 2rem;
}

#lead-with-love-page-new .uui-container-large-6 {
	width: 100%;
	max-width: 80rem;
	margin-left: auto;
	margin-right: auto;
}

#lead-with-love-page-new .uui-padding-vertical-xhuge-7 {
	padding-top: 3rem;
	padding-bottom: 4rem;
}

#lead-with-love-page-new .uui-testimonial05_logo-wrapper {
	justify-content: center;
	margin-bottom: 2rem;
	display: flex;
}

#lead-with-love-page-new .f-h1-heading-3 {
	color: #303440;
	letter-spacing: 1px;
	margin-top: 0;
	margin-bottom: 0;
	font-size: 32px;
	font-weight: 400;
	line-height: 66px;
}

#lead-with-love-page-new .testimonials-content .description-box {
	margin-bottom: 20px;
	color: #242424;
	width: 80%;
	font-family: Montserrat, sans-serif;
	font-size: 16px;
	font-weight: 400;
	line-height: 26px;
	margin-left: auto;
	margin-right: auto;
}

#lead-with-love-page-new .testimonials-content .author-name {
	color: #475467;
	letter-spacing: normal;
	margin-top: 20px;
	margin-bottom: 10px;
	font-size: 16px;
	line-height: 1.5;
}

#lead-with-love-page-new .testimonials-content .company {
	color: #475467;
	letter-spacing: normal;
	margin-top: 0;
	font-size: 14px;
	line-height: 1.5;
}

#lead-with-love-page-new #testimonials_module_contentbox .left-arrow,
#lead-with-love-page-new #testimonials_module_contentbox .right-arrow {
	color: #667085;
	background-color: #fff;
	border: 1px solid #eaecf0;
	border-radius: 100%;
	justify-content: center;
	align-items: center;
	min-width: 3.5rem;
	height: 3.5rem;
	transition: all 0.3s;
	display: flex;
}

#lead-with-love-page-new #testimonials_module_contentbox .left-arrow:hover,
#lead-with-love-page-new #testimonials_module_contentbox .right-arrow:hover {
	background-color: #f9fafb;
}

#lead-with-love-page-new #testimonials_module_contentbox .left-arrow .arrow {
	background-image: url("/assets/images/testimonials-arrow.svg");
	background-repeat: no-repeat;
	border: 0;
	background-size: 20px;
	width: 20px;
	transform: rotate(180deg);
	height: 20px;
	opacity: 0.6;
}

#lead-with-love-page-new #testimonials_module_contentbox .right-arrow .arrow {
	background-image: url("/assets/images/testimonials-arrow.svg");
	background-repeat: no-repeat;
	border: 0;
	background-size: 20px;
	width: 20px;
	height: 20px;
	transform: rotate(0deg);
	opacity: 0.6;
}

@media screen and (max-width: 991px) {
	#lead-with-love-page-new .main-content-wrapper-copy {
		width: 100%;
		margin-top: 0;
		padding-top: 0.5rem;
		padding-bottom: 0.75rem;
	}

	#lead-with-love-page-new .intro-paragraph {
		margin-top: 0;
		font-size: 1rem;
		line-height: 1.5rem;
	}

	#lead-with-love-page-new .job-content-wrapper {
		flex-flow: column wrap;
		grid-template-columns: 1fr 1fr;
		align-content: stretch;
	}

	#lead-with-love-page-new .job-block.option2 {
		min-width: 150px;
	}


	#lead-with-love-page-new .slide-lead {
		height: 18vw;
	}

	#lead-with-love-page-new .uui-padding-vertical-xhuge-7 {
		padding-top: 6rem;
		padding-bottom: 6rem;
	}
}

@media screen and (max-width: 767px) {
	#lead-with-love-page-new .main-content-wrapper-copy {
		padding-top: 0.5rem;
		padding-bottom: 0.75rem;
	}

	#lead-with-love-page-new .intro-paragraph {
		font-size: 1rem;
		line-height: 1.4rem;
	}

	#lead-with-love-page-new .slide-lead {
		height: 26vw;
	}

	#lead-with-love-page-new .inside-page-banner-section {
		height: 347px;
	}

	#lead-with-love-page-new .uui-page-padding-6 {
		padding-left: 1rem;
		padding-right: 1rem;
	}

	#lead-with-love-page-new .uui-padding-vertical-xhuge-7 {
		padding-top: 4rem;
		padding-bottom: 4rem;
	}

	#lead-with-love-page-new .f-h1-heading-3 {
		margin-top: 0;
		font-size: 48px;
	}

	#lead-with-love-page-new .uui-testimonial05_component {
		padding-bottom: 3rem;
	}

	#lead-with-love-page-new #testimonials_module_contentbox .left-arrow,
	#lead-with-love-page-new #testimonials_module_contentbox .right-arrow {
		bottom: -4.5rem;
		position: absolute;
	}

	#lead-with-love-page-new #testimonials_module_contentbox .left-arrow {
		left: 32%;
	}

	#lead-with-love-page-new #testimonials_module_contentbox .right-arrow {
		right: 32%;
	}

	#lead-with-love-page-new #testimonials_module_contentbox .testimonials-content {
		width: 100%;
	}

	#lead-with-love-page-new .intro-paragraph.cbtext h2 {
		font-size: 1.75rem;
		line-height: 2.45rem;
	}
}

@media screen and (max-width: 479px) {
	#lead-with-love-page-new .main-content-wrapper-copy {
		padding-bottom: 0;
	}

	#lead-with-love-page-new .intro-paragraph {
		font-size: 1rem;
		line-height: 1.2rem;
	}

	#lead-with-love-page-new .job-content-wrapper {
		grid-template-columns: 1fr;
	}

	#lead-with-love-page-new .job-text-block {
		height: auto;
	}

	#lead-with-love-page-new .slide-lead {
		height: 62vw;
	}

	#lead-with-love-page-new .f-h1-heading-3 {
		font-size: 30px;
		line-height: 35px;
	}

	#lead-with-love-page-new #testimonials_module_contentbox .left-arrow {
		left: 22%;
	}

	#lead-with-love-page-new #testimonials_module_contentbox .right-arrow {
		right: 22%;
	}

	#lead-with-love-page-new .intro-paragraph.cbtext h2 {
		font-size: 1.55rem;
		line-height: 2.15rem;
	}

	#lead-with-love-page-new .inside-page-banner-section.banner-image-1 {
		background-image: url('/assets/images/lead-with-love-banner-mobile.webp');
	}
}
